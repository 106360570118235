import React, { useState, useEffect } from "react";
import { Paper, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import AllContact from "./AllContact";
import { getCompanyContact } from "../../Actions/companiesAction";
import SearchBar from "../../Components/Common/SearchBar";
import AddContact from "./AddContact";

const styles = () => ({
  paper: {
    backgroundColor: "#fcfdfe"
  },
  mainContact: {
    padding: "20px 40px"
  },
  aboutCompany: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 0px 30px"
  }
});

function ContactTabs(props) {
  const { classes } = props;
  const [isSearch, setisSearch] = useState(false);
  const [company_contact, setCompanyContact] = useState();
  const [loading, setloading] = useState();
  const [search, setsearch] = useState("");

  const onSearchContact = search => {
    setisSearch({ isSearch: true });
    setsearch(search);
    props.getCompanyContact("", props.id, search, isSearch);
  };

  useEffect(() => {
    props.getCompanyContact(props.id);
  }, []);

  useEffect(() => {
    setCompanyContact(props.company_contact);
  }, [props.company_contact]);

  useEffect(() => {
    setloading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    if (props.addCompanyContact.success) props.getCompanyContact(props.id);
  }, [props.addCompanyContact.success]);
  return (
    <Paper className={classes.root} style={{ backgroundColor: "#FCFDFE" }}>
      <div className={classes.mainContact}>
        <div className={classes.aboutCompany}>
          <SearchBar type="Search Contacts" onSearch={onSearchContact} />
          <AddContact id={props.id} />
        </div>
        <AllContact
          id={props.id}
          company={props.company}
          company_contact={company_contact}
          loading={loading}
          search={search}
          isSearch={isSearch}
        />
      </div>
    </Paper>
  );
}

const mapStateToProps = state => {
  return {
    company_contact: state.company.company_contact,
    loading: state.company.loading,
    addCompanyContact: state.company.addCompanyContact
  };
};

export default connect(
  mapStateToProps,
  { getCompanyContact }
)(withStyles(styles)(ContactTabs));
