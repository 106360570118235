import {
  GET_CITIES,
  GET_CITIES_ERRORS,
  GET_CITIES_LOADING
} from "../Actions/types";

const initialState = {
  citylist: [],
  loading: false
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CITIES:
      return {
        ...state,
        loading: false,
        citylist: action.payload.data
      };
    case GET_CITIES_ERRORS:
      return {
        ...state,
        loading: false,
        city_error: { errors: false }
      };
    case GET_CITIES_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
