import React from "react";
import { Typography, Avatar } from "@material-ui/core";
import { capitalize } from "../../Utils/StringFunctions";
import withStyles from "@material-ui/core/styles/withStyles";
import Partners from "../../Assets/Partners.png";
const ASSET_URL = process.env.REACT_APP_ASSET_URL;

const styles = () => ({
  profileImage: {
    display: "flex",
    flexDirection: "row",
    height: 50,
    justifyContent: "center",
    alignItems: "center"
  },
  eventName: {
    color: "black"
  },
  partnerName: {
    fontWeight: "semi-bold",
    fontSize: 14,
    textAlign: "center",
    color: "#4d7cfe",
    margin: "20px 0px 0px"
  },
  mapPin: { paddingRight: "2px" },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  }
});

function DashboardAssociates(props) {
  const { classes, data, history } = props;
  const hadleClick = () => {
    history.push(`/associates/${props.data._id}`);
  };
  return (
    <main>
      <div className={classes.profileImage}>
        <div className={classes.image}>
          {data.photo ? (
            <Avatar
              src={`${ASSET_URL}${data.photo}`}
              className={classes.bigAvatar}
              onClick={hadleClick}
            />
          ) : (
            <img
              className={classes.Partners}
              src={Partners}
              alt=""
              width="91px"
            />
          )}
        </div>
      </div>
      <Typography component="h2" className={classes.partnerName}>
        {capitalize(data.user ? data.user.first_name : "")}{" "}
        {capitalize(data.user ? data.user.last_name : "")}
      </Typography>
    </main>
  );
}
export default withStyles(styles)(DashboardAssociates);
