import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Landing from "./Screens/Landing";
import Login from "./Screens/Login";
import Forgotpassword from "./Screens/Login/Forgotpassword";
import Resetpassword from "./Screens/Login/Resetpassword";
import Register from "./Screens/Register";
import CompanyForm from "./Screens/CompanyForm";
// import EventForm from "./Screens/EventForm";
import ApplicationForm from "./Screens/ApplicationForm";
import Dashboard from "./Screens/Dashboard";
import ProfileForm from "./Screens/ProfileForm";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./Utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./Actions/authAction";
import { clearCurrentProfile } from "./Actions/profileAction";
// import AdminRoute from "./Components/Common/AdminRoute";
import ChallengesPage from "./Screens/ChallengesPage/ChallengesPage";
import CompaniesPage from "./Screens/CompaniesPage";
import AdminEvents from "./Screens/EventPage/AdminEvents";
import EventPage from "./Screens/EventPage";
import AdminApplications from "./Screens/Applications/AdminApplications";
import ViewApplication from "./Screens/Applications/ViewApplication";
import AdminAssociates from "./Screens/Associates/AdminAssociates";
import AdminApplicants from "././Screens/Applicants/AdminApplicants";
import AdminPartners from "./Screens/Partners/AdminPartners";
import PartnerForm from "./Screens/Partners/PartnerForm";
import AssociateForm from "./Screens/Associates/AssociateForm";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import CompanyPage from "./Screens/CompanyPage/CompanyPage";
import ViewaPartner from "./Screens/Partners/ViewaPartner";
import ViewaAssociates from "./Screens/Associates/ViewaAssociates";
import ComponentDemo from "./Screens/ComponentDemo";
import interceptor from "./Utils/interceptor";
import NewEventForm from "./Screens/NewEventForm/NewEventForm";
import ViewaEvent from "./Screens/EventPage/ViewaEvent";
import theme from "./Theme";
import ToastProvider from "./ToastManager";
import PrivateRoute from "./Components/Common/PrivateRoute";
import ViewaApplicants from "./Screens/Applicants/ViewaApplicants";

interceptor();
let Theme = createMuiTheme(theme);

const styles = () => ({});

// Check for token and set current user
if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    store.dispatch(clearCurrentProfile());
    window.location.href = "/login";
  }
}
class App extends Component {
  state = {
    mobileOpen: false
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };
  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={Theme}>
          <Provider store={store}>
            <Router>
              <ToastProvider>
                <Switch>
                  <Route exact path="/" component={Landing} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/register" component={Register} />
                  <Route
                    exact
                    path="/Forgotpassword"
                    component={Forgotpassword}
                  />
                  <Route
                    exact
                    path="/Resetpassword"
                    component={Resetpassword}
                  />
                  <Route path="/challenges" component={ChallengesPage} />
                  <PrivateRoute
                    path="/event/:id"
                    component={ViewaEvent}
                    title="View Event"
                  />
                  <Route exact path="/event-page" component={EventPage} />
                  <PrivateRoute
                    exact
                    path="/dashboard"
                    component={Dashboard}
                    title="Dashboard"
                  />
                  <PrivateRoute
                    exact
                    path="/events"
                    component={AdminEvents}
                    title="Events"
                  />
                  <PrivateRoute exact path="/demo" component={ComponentDemo} />
                  {/* <PrivateRoute exact path="/events/add" component={EventForm} /> */}
                  <PrivateRoute
                    exact
                    path="/events/add"
                    component={NewEventForm}
                    title="Add Event"
                  />
                  <PrivateRoute
                    exact
                    path="/events/edit/:id"
                    component={NewEventForm}
                    title="Edit Event"
                  />
                  <PrivateRoute
                    exact
                    path="/company"
                    component={CompaniesPage}
                    title="Companies"
                  />
                  <PrivateRoute
                    exact
                    path="/applicants"
                    component={AdminApplicants}
                    title="Applicants"
                  />
                  <PrivateRoute
                    exact
                    path="/partners"
                    component={AdminPartners}
                    title="Partners"
                  />
                  <PrivateRoute
                    exact
                    path="/partners/add"
                    component={PartnerForm}
                    title="Add Partner"
                  />
                  <PrivateRoute
                    exact
                    path="/partner/edit/:id"
                    component={PartnerForm}
                    title="Edit Partner"
                  />
                  <PrivateRoute
                    path="/partners/:id"
                    component={ViewaPartner}
                    title="View Partner"
                  />
                  <PrivateRoute
                    exact
                    path="/companies/:id"
                    component={CompanyPage}
                    title="View Company"
                  />
                  <PrivateRoute
                    exact
                    path="/company/add"
                    component={CompanyForm}
                    title="Add Company"
                  />
                  <PrivateRoute
                    path="/company/:id"
                    component={CompanyForm}
                    title="Edit Company"
                  />
                  {/* <PrivateRoute
                    path="/events/add-event"
                    component={EventForm}
                    title="Add Event"
                  />
                  <PrivateRoute
                    path="/events/:id"
                    component={EventForm}
                    title="Edit Event"
                  /> */}
                  <PrivateRoute
                    path="/applications"
                    component={AdminApplications}
                    title="Applications"
                  />
                  <PrivateRoute
                    path="/application/:id"
                    component={ViewApplication}
                    title=" View Application"
                  />
                  <Route
                    path="/add-application/:id"
                    component={ApplicationForm}
                    title="Edit Application"
                  />
                  <PrivateRoute
                    exact
                    path="/associates"
                    component={AdminAssociates}
                    title="Associates"
                  />
                  <PrivateRoute
                    exact
                    path="/associates/add"
                    component={AssociateForm}
                    title="Add Associate"
                  />
                  <PrivateRoute
                    exact
                    path="/associate/edit/:id"
                    component={AssociateForm}
                    title="Edit Associate"
                  />
                  <PrivateRoute
                    path="/associates/:id"
                    component={ViewaAssociates}
                    title="View Associate"
                  />
                  <PrivateRoute
                    path="/associate/:id"
                    component={AssociateForm}
                    title="Edit Associate"
                  />
                  <PrivateRoute
                    path="/applicants/:id"
                    component={ViewaApplicants}
                    title="View a Applicants"
                  />
                  <Route exact path="/add-profile" component={ProfileForm} />
                </Switch>
              </ToastProvider>
            </Router>
          </Provider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles, { withTheme: true })(App);
