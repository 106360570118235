import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { CssBaseline, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { getAssociate } from "../../Actions/associateActions";
import { connect } from "react-redux";
import Header from "./Header";
import AssociateTabs from "./AssociateTabs";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = () => ({
  layout: {
    backgroundColor: "#fff"
  }
});
class SingleAssociate extends React.Component {
  state = {
    value: 0,
    selectedIndex: 0
  };

  constructor(props) {
    super(props);
    this.state = { partners: [] };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getAssociate(id);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.associate !== prevState.associate) {
      return { associate: nextProps.associate };
    } else return [];
  }

  render() {
    const { classes } = this.props;
    const { associate } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Header data={associate} />
        </main>
        <AssociateTabs data={associate} />
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    associate: state.associates.associate
  };
};

export default connect(
  mapStateToProps,
  { getAssociate }
)(withStyles(styles)(SingleAssociate));
