import React from "react";
import { DatePicker } from "material-ui-pickers";
import { withStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const styles = () => ({
  input: {
    fontSize: "inherit",
    paddingLeft: "7px",
    border: "1px solid #ede8ef",
    borderRadius: "5px",
    position: "relative",
    top: "8px"
  }
});
const theme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        margin: "14px 0px 10px 0px"
      }
    },
    MuiInputLabel: {
      root: {
        "&$error": {
          color: "rgba(0,0,0,0.54)"
        }
      }
    },
    MuiInputBase: {
      input: {
        fontSize: "14px",
        // borderLeft: props.fieldRequired ? "2px solid #F3A7A7" : "none",
        marginTop: "5px",
        height: "20px"
      }
    },
    MuiIconButton: {
      root: {
        padding: "5px",
        marginRight: "5px"
      }
    },
    MuiSvgIcon: {
      root: {
        width: "22px"
      }
    }
  }
});
class DatePickerField extends React.Component {
  handleChange = value => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange(this.props.name, value);
  };

  render() {
    const { classes, ...other } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <DatePicker
          {...other}
          name={this.props.name}
          keyboard
          label={this.props.label}
          format="DD/MM/YYYY"
          disableOpenOnEnter
          onChange={this.handleChange}
          value={this.props.value}
          animateYearScrolling={false}
          InputProps={{ disableUnderline: true, className: classes.input }}
          fullWidth
        />
      </ThemeProvider>
    );
  }
}
export default withStyles(styles)(DatePickerField);
