import React, { useEffect, useState } from "react";
import StackCard from "../../Components/Common/Stack";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { getEvents } from "../../Actions/eventActions";
import { getApplicants } from "../../Actions/applicantActions";
import { getApplications } from "../../Actions/applicationActions";
import { getAssociates } from "../../Actions/associateActions";
import { getPartners } from "../../Actions/partnerActions";
import DashboardEvents from "./DashboardEvents";
import DashboardPartner from "./DashboardPartners";
import Header from "../../Components/Common/Table/TableHeader";
import { Table, TableHead } from "@material-ui/core";
import SkeletonTable from "../../Components/Common/SkeletonTable";
import DashboardApplications from "./DashboardApplications";
import DashboardAssociates from "./DashboardAssociates";
import SkeletonCard from "../../Components/Common/Cards/SkeletonCard";

const styles = () => ({
  main: {
    background: "#fff",
    padding: "24px"
  },
  partnerContainer: {
    boxShadow: "0 5px 10px rgba(0,0,0,0.04), 0 5px 5px rgba(0,0,0,0.05)",
    border: "1px solid #efefef",
    borderRadius: "1px",
    minHeight: "341px"
  },
  partner: {
    marginTop: "0.7%"
  },
  ImageContainer: {
    height: "269px"
  },
  text: {
    color: "grey"
  }
});
function Dashboard(props) {
  const { classes } = props;
  const [events, setevents] = useState("");
  const [applications, setApplications] = useState("");
  const [applicants, setapplicants] = useState("");
  const [associates, setAssociates] = useState("");
  const [partners, setPartner] = useState("");
  const [eventsloading, setEventsLoading] = useState(props.eventsloading);
  const [applicationsLoading, setApplcationsLoading] = useState(
    props.applicationsLoading
  );
  const [partnersLoading, setPartnersLoading] = useState(props.partnersLoading);
  const [associatesLoading, setAssociatesLoading] = useState(
    props.associatesLoading
  );

  //Associates Loading

  useEffect(() => {
    setAssociatesLoading(props.associatesLoading);
  }, [props.associatesLoading]);

  //PartnersLoading

  useEffect(() => {
    setPartnersLoading(props.partnersLoading);
  }, [props.partnersLoading]);

  //EventLoading

  useEffect(() => {
    setEventsLoading(props.eventsloading);
  }, [props.eventsloading]);

  //ApplicationLoading

  useEffect(() => {
    setApplcationsLoading(props.applicationsLoading);
  }, [props.applicationsLoading]);

  //GET ALL API

  useEffect(() => {
    props.getEvents();
    props.getApplicants();
    props.getApplications();
    props.getPartners();
    props.getAssociates();
  }, []);

  //SET EVENTS

  useEffect(() => {
    setevents(props.events);
  }, [props.events]);

  //SET APPLICATIONS

  useEffect(() => {
    setApplications(props.applications);
  }, [props.applications]);

  //SET applicants

  useEffect(() => {
    setapplicants(props.applicants);
  }, [props.applicants]);

  //SET PARTNERS

  useEffect(() => {
    setPartner(props.partners);
  }, [props.partners]);

  //SET ASSOCIATES

  useEffect(() => {
    setAssociates(props.associates);
  }, [props.associates]);

  const totalEvents = events ? events.length : "";
  const totalApplication = applications ? applications.length : "";
  const totalapplicants = applicants ? applicants.length : "";
  const totalAssociates = associates ? associates.length : "";
  const totalPartners = partners ? partners.length : "";

  return (
    <main className={classes.main}>
      <Grid container spacing={0}>
        <Grid item xs={4} sm={12} lg={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs
              sm
              onClick={() => {
                props.history.push("/events");
              }}
            >
              <StackCard
                stackType="white"
                stackText="Events"
                stackVal={totalEvents}
              />
            </Grid>
            <Grid
              item
              xs
              sm
              onClick={() => {
                props.history.push("/applications");
              }}
            >
              <StackCard
                stackType="white"
                stackText="Applications"
                stackVal={totalApplication}
              />
            </Grid>
            <Grid
              item
              xs
              sm
              onClick={() => {
                props.history.push("/applicants");
              }}
            >
              <StackCard
                stackType="white"
                stackText="Applicants"
                stackVal={totalapplicants}
              />
            </Grid>
            <Grid
              item
              xs
              sm
              onClick={() => {
                props.history.push("/associates");
              }}
            >
              <StackCard
                stackType="white"
                stackText="Associates"
                stackVal={totalAssociates}
              />
            </Grid>
            <Grid
              item
              xs
              sm
              onClick={() => {
                props.history.push("/partners");
              }}
            >
              <StackCard
                stackType="white"
                stackText="Partners"
                stackVal={totalPartners}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row" xs={12}>
        <Grid item xs={12} lg={7}>
          {eventsloading ? (
            Array.from(new Array(8)).map(id => {
              return <SkeletonTable key={id} dashboard />;
            })
          ) : (
            <DashboardEvents data={events} history={props.history} />
          )}
        </Grid>
        <Grid item xs={12} lg={5} className={classes.partner}>
          <Grid container spacing={2} className={classes.partnerContainer}>
            {!partnersLoading && (
              <Table className={classes.table}>
                <TableHead>
                  <Header>Partner </Header>
                </TableHead>
              </Table>
            )}
            {partnersLoading
              ? Array.from(new Array(6)).map(id => {
                  return (
                    <Grid item key={id} xs={4}>
                      <SkeletonCard dashboard />
                    </Grid>
                  );
                })
              : partners &&
                partners.length > 0 &&
                partners.slice(0, 6).map((item, id) => {
                  return (
                    <Grid item key={id} xs={12} lg={4} md={4} sm={6}>
                      <DashboardPartner data={item} history={props.history} />
                    </Grid>
                  );
                })}
            {!partnersLoading && partners && partners.length <= 0 && (
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.ImageContainer}
              >
                <Grid item className={classes.text}>
                  No Data Found
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={7}>
          {applicationsLoading ? (
            Array.from(new Array(7)).map(id => {
              return <SkeletonTable key={id} dashboard />;
            })
          ) : (
            <DashboardApplications
              data={applications ? applications : ""}
              history={props.history}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={5} className={classes.partner}>
          <Grid container spacing={2} className={classes.partnerContainer}>
            {!associatesLoading && (
              <Table className={classes.table}>
                <TableHead>
                  <Header>Associate </Header>
                </TableHead>
              </Table>
            )}
            {associatesLoading
              ? Array.from(new Array(6)).map(id => {
                  return (
                    <Grid item key={id} xs={4}>
                      <SkeletonCard dashboard />
                    </Grid>
                  );
                })
              : associates &&
                associates.length > 0 &&
                associates.slice(0, 6).map((item, id) => {
                  return (
                    <Grid item key={id} xs={12} lg={4} md={4} sm={6}>
                      <DashboardAssociates
                        data={item}
                        history={props.history}
                      />
                    </Grid>
                  );
                })}
            {!associatesLoading && associates && associates.length <= 0 && (
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.ImageContainer}
              >
                <Grid item className={classes.text}>
                  No Data Found
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
}
const mapStateToProps = state => {
  return {
    events: state.events.events,
    applications: state.applications.applications || {},
    applicants: state.applicants.applicants,
    associates: state.associates.associates,
    partners: state.partners.partners,
    eventsloading: state.events.loading,
    applicationsLoading: state.applications.loading,
    partnersLoading: state.partners.loading,
    associatesLoading: state.associates.loading
  };
};

export default connect(mapStateToProps, {
  getEvents,
  getApplications,
  getApplicants,
  getAssociates,
  getPartners
})(withStyles(styles)(Dashboard));
