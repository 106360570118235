import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Paper, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { registerApplication } from "../../Actions/applicationActions";
import { connect } from "react-redux";
import { getChallenge, applyToChallenge } from "../../Actions/ChallengesAction";
import { Formik, FieldArray } from "formik";
import { IconButton } from "@material-ui/core";
import { Plus, X } from "react-feather";
import { Fab } from "@material-ui/core";
import { Form } from "react-formik-ui";
import {
  SelectField,
  UploadField,
  InputField
} from "../../Components/Common/Form";
import Button from "../../Components/Common/Button";
import Authentication from "../Landing/Authentication";
import * as Yup from "yup";

const styles = theme => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent"
  },

  textField: {
    marginTop: 0
  },
  textFieldHelper: {
    marginTop: 0
  },
  group: {
    flexDirection: "row"
  },
  iconButton: {
    position: "absolute",
    right: 0
  },
  lightBG: {
    position: "relative"
  },
  toolBar: {
    width: "auto",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 1200,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  "@global": {
    body: {
      backgroundColor: "#fff"
    }
  },

  toolbarTitle: {
    flex: 1
  },
  layout: {
    width: "auto",
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 1200,
      marginLeft: "auto",
      marginRight: "auto"
    },
    paddingLeft: 0,
    paddingRight: 0
  }
});
const ApplicationSchema = Yup.object().shape({
  logo: Yup.mixed().required("Required"),
  theme: Yup.string().required("Required"),
  team_name: Yup.string().required("Required")
});
class ApplicationForm extends React.Component {
  state = { themelist: "" };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push({
        pathname: "/login",
        state: { appID: id }
      });
    }
    this.props.getChallenge(this.props.match.params.id);
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.challenge !== prevState.challenge) {
      return { challenges: nextProps.challenge };
    } else return null;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.challenge !== this.props.challenge) {
      this.setState({
        challenge: this.props.challenge
      });
    }
  }
  getAlltheme = challenge => {
    return challenge
      ? challenge.theme.map(theme => {
          return {
            value: theme ? theme._id : "",
            label: theme ? theme.theme_name : ""
          };
        })
      : [];
  };
  getInitialvalues = () => {
    return {
      team_name: "",
      logo: "",
      idea: "",
      team: [
        {
          first_name: "",
          last_name: "",
          email: ""
        }
      ],
      theme: "",
      event_id: this.props.match.params.id,
      createdBy: this.props.auth.user.id
    };
  };

  render() {
    const { classes } = this.props;
    const { challenge } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <Authentication />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Add Application
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginBottom: 20 }}>
              General Information
            </Typography>
            <Formik
              initialValues={this.getInitialvalues()}
              validationSchema={ApplicationSchema}
              onSubmit={values => {
                const themename = values.theme ? values.theme.label : "";
                values.theme = themename;
                this.props.applyToChallenge(values, this.props.history);
              }}
            >
              {({
                values,
                setFieldValue,
                setFieldTouched,
                errors,
                touched
              }) => (
                <Form
                  className={classes.form}
                  onSubmit={this.onSubmit}
                  encType="multipart/form-data"
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <InputField name="team_name" label="Team Name" />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <SelectField
                        value={values.theme}
                        name="theme"
                        id="theme"
                        label="Select Theme"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        multi={false}
                        error={errors.theme}
                        touched={touched.theme}
                        options={this.getAlltheme(challenge)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <UploadField
                        label="Upload Team Photo"
                        name="logo"
                        id="logo"
                        onChange={setFieldValue}
                        value={values.logo}
                        folder="profile/picture"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField name="idea" label="Idea" />
                    </Grid>
                  </Grid>
                  <FieldArray
                    name="team"
                    render={({ remove, push }) => (
                      <div>
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{ margin: "30px 0px 0px" }}
                        >
                          Team Members
                        </Typography>
                        {values.team.length > 0 &&
                          values.team.map((member, idx) => (
                            <Grid
                              container
                              spacing={24}
                              key={idx}
                              className={classes.lightBG}
                            >
                              <IconButton
                                color="primary"
                                className={classes.iconButton}
                                onClick={() => remove(idx)}
                                aria-label="remove"
                              >
                                <X />
                              </IconButton>
                              <Grid item xs={12} sm={4}>
                                <InputField
                                  name={`team.${idx}.first_name`}
                                  label="First Name"
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <InputField
                                  name={`team.${idx}.last_name`}
                                  label="Last Name"
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <InputField
                                  name={`team.${idx}.email`}
                                  label="Email"
                                />
                              </Grid>
                            </Grid>
                          ))}
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12} align={"center"}>
                            <Fab
                              onClick={() => {
                                push({
                                  first_name: "",
                                  last_name: "",
                                  email: ""
                                });
                              }}
                              color="primary"
                              aria-label="add"
                              className={classes.fab}
                            >
                              <Plus />
                            </Fab>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  />
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    spacing={2}
                    direction="row-reverse"
                  >
                    <Grid item>
                      <Button type="submit" label="Submit" />
                    </Grid>
                    <Grid item>
                      <Button
                        type="cancel"
                        label="Cancel"
                        onClick={() => {
                          this.props.history.goBack();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    challenge: state.events.challenge,
    auth: state.auth
  };
};

export default connect(
  mapStateToProps,
  { registerApplication, getChallenge, applyToChallenge }
)(withStyles(styles)(ApplicationForm));
