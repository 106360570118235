import React from "react";
import { Toolbar, Typography, Button, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { RenderMenu } from "./../../Components/Layout/userMenu";
import { User } from "react-feather";
import { getCurrentProfile } from "../../Actions/profileAction";
import { logoutUser } from "./../../Actions/authAction";
import { clearCurrentProfile } from "./../../Actions/profileAction";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";

const styles = {
  toolbar: {
    " & span": {
      color: "white"
    }
  },
  navbar: {
    backgroundColor: "black"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  grow: {
    flexGrow: 1,
    fontFamily: "Lato",
    fontWeight: "600",
    height: "2.5rem",
    lineHeight: "2.5rem",
    fontSize: "1rem",
    color: "#fff"
  }
};

class UserNavBar extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null
  };
  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };
  logout = () => {
    this.props.logoutUser();
    this.props.clearCurrentProfile();

    this.props.history.push("/");
  };
  render() {
    let { classes, auth } = this.props;
    let isAuthenticated = auth.isAuthenticated;
    const isMenuOpen = Boolean(this.state.anchorEl);
    return (
      <div className={classes.root}>
        <AppBar
          position="static"
          style={{
            padding: "10px",
            backgroundColor: "transparent",
            backgroundRepeat: "norepeat"
          }}
        >
          <Toolbar>
            <Link to="/" className="logo" rel="home">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M7.335 1.023l2.462.434a1 1 0 0 1 .811 1.159L8.004 17.388a2 2 0 0 1-2.317 1.622l-3.94-.694a1 1 0 0 1-.81-1.159L3.28 3.862a3.5 3.5 0 0 1 4.054-2.839zm7.039 3.272l7.878 1.39a1 1 0 0 1 .812 1.158l-1.997 11.325a5.5 5.5 0 0 1-6.372 4.461l-4.431-.78a1 1 0 0 1-.812-1.16l2.605-14.771a2 2 0 0 1 2.317-1.623z"
                  fill="white"
                />
              </svg>
              <span style={{ color: "white" }}>HaaS</span>
            </Link>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              <Link to="/" style={{ marginRight: "16px", color: "#fff" }}>
                Home
              </Link>
              <Link
                to="/challenges"
                className="active"
                style={{ color: "#fff" }}
              >
                Challenges
              </Link>
            </Typography>
            {!isAuthenticated && (
              <ul className="">
                <li style={{ listStyle: "none" }}>
                  <Link to="/login">Log In</Link>
                </li>
              </ul>
            )}
            {isAuthenticated && (
              <Button
                style={{
                  fontFamily: "Lato",
                  fontWeight: "600",
                  height: "2.5rem",
                  lineHeight: "2.5rem",
                  fontSize: "13px"
                }}
                color="inherit"
                onClick={e => {
                  this.setState({ anchorEl: e.currentTarget });
                }}
              >
                <User />
                <p style={{ margin: "0px 4px" }}>Profile</p>
              </Button>
            )}
            <RenderMenu
              anchorEl={this.state.anchorEl}
              isMenuOpen={isMenuOpen}
              handleMenuClose={this.handleMenuClose}
              logout={this.logout}
            />
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    events: state.events.events,
    auth: state.auth,
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  { getCurrentProfile, logoutUser, clearCurrentProfile }
)(withStyles(styles)(UserNavBar));
