const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
export { capitalize };

const spacecapitalize = s => {
  if (typeof s !== "string") return "";
  let string = s.replace(/_/g, " ");
  let splitStr = string.toLowerCase().split(" ");
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
  // return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
};
export { spacecapitalize };
