import React from "react";
import { withRouter, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { registerUser } from "../../Actions/authAction";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
import PropTypes from "prop-types";
import LoginImage from "../../Assets/login.jpg";
import { createMuiTheme } from "@material-ui/core/styles";
import { Typography, Paper, Grid } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

const styles = theme => {
  return {
    paper: {
      display: "flex",
      flexDirection: "column",
      height: "-webkit-fill-available",
      padding: "3% 22% 0% 22%",
      backgroundColor: "#fff"
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(1)
    },
    login: {
      backgroundImage: `url(${LoginImage})`,
      width: "100%",
      height: "100vh",
      backgroundSize: "cover",
      backgroundPosition: "50%"
    },
    formfooter: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      marginTop: "4%"
    },
    multilineColor: {
      height: "50px",
      padding: "0px 10px",
      marginBottom: "-7px"
    },
    submitbutton: {
      backgroundColor: "#211b4e !important",
      "&:hover": {
        backgroundColor: "#151039 !important"
      },
      textTransform: "initial"
    },
    paragraph: {
      fontSize: "1.25rem",
      fontWeight: 400,
      letterSpacing: "0.3px",
      fontFamily: "PT Serif, Georgia, serif",
      margin: "0px"
    },
    muted: {
      opacity: "0.4"
    },
    spacetop: {
      marginTop: "1rem"
    }
  };
};

const SignupSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "please enter more than 2 character")
    .max(15, "")
    .required("Required"),
  last_name: Yup.string()
    .min(2, "please enter more than 2 character")
    .max(15, "")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  password: Yup.string().required("Required"),
  contact_number: Yup.number()
    .required("Field Required")
    .positive("A phone number can't start with a minus")
    .min(1111111111, "Please enter 10 digit number")
    .max(9999999999, "Please enter 10 digit number")
});

const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        "&$error": {
          // increase the specificity for the pseudo class
          color: "rgba(0,0,0,0.54)"
        }
      }
    },
    MuiInputBase: {
      input: {
        paddingLeft: "10px !important",
        fontSize: "14px"
      }
    }
  }
});
class RegisterPage extends React.Component {
  state = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password2: "",
    errors: {}
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} sm={6} md={6} component={Paper}>
          <div className={classes.paper}>
            <h5>Welcome !</h5>
            <p className={classes.paragraph}>
              Enter your details to create an account.
            </p>
            <div className={classes.loginContent}>
              <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  email: "",
                  password: "",
                  contact_number: ""
                  // role: "candidate"
                }}
                validationSchema={SignupSchema}
                onSubmit={(values, { setSubmitting }) => {
                  this.props.registerUser(values, this.props.history);
                }}
              >
                {({ errors }) => (
                  <Form className={classes.form} noValidate autoComplete="off">
                    <ThemeProvider theme={theme}>
                      <FormikTextField
                        name="first_name"
                        label=" First Name"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        InputProps={{
                          classes: {
                            input: classes.multilineColor
                          }
                        }}
                        placeholder="Enter your First Name"
                      />
                      <FormikTextField
                        name="last_name"
                        label="Last Name"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        InputProps={{
                          classes: {
                            input: classes.multilineColor
                          }
                        }}
                        placeholder="Enter your Last Name"
                      />
                      <FormikTextField
                        name="email"
                        label="Email Address"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        InputProps={{
                          classes: {
                            input: classes.multilineColor
                          }
                        }}
                        placeholder="johndoe@mail.com"
                      />
                      <FormikTextField
                        name="contact_number"
                        label="Contact Number"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        InputProps={{
                          classes: {
                            input: classes.multilineColor
                          }
                        }}
                        placeholder="91XXXXXXXX"
                        type="tel"
                        max="10"
                      />
                      <FormikTextField
                        name="password"
                        label="Password"
                        type="password"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        InputProps={{
                          classes: {
                            input: classes.multilineColor
                          }
                        }}
                        placeholder="Enter password"
                      />

                      <Typography as="h5" style={{ color: "red" }}>
                        {errors.generic}
                      </Typography>
                      <div className={classes.formfooter}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className={classes.submitbutton}
                        >
                          Submit
                        </Button>
                      </div>

                      <div className={classes.spacetop}>
                        <span className={classes.muted}>
                          By creating an account, you agree to our
                        </span>
                        <a href="#">Terms</a>
                        <span className={classes.muted}>.</span>
                      </div>
                      <div className="center max-width-s">
                        <span className={classes.muted}>
                          Already have an account?{" "}
                        </span>
                        <Link to="/Login">Login</Link>
                      </div>
                    </ThemeProvider>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Grid>
        <Grid
          container
          sm={6}
          md={6}
          className={classes.image}
          direction="column"
        >
          <div className={classes.login} />
        </Grid>
      </Grid>
    );
  }
}

RegisterPage.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    errors: state.errors
  };
};

export default connect(
  mapStateToProps,
  { registerUser }
)(withStyles(styles)(withRouter(RegisterPage)));
