import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Grid, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import logo2 from "../../Assets/logo2.png";
import { getPartner } from "../../Actions/partnerActions";
import { connect } from "react-redux";
import Facebook from "../../Assets/icons/Facebook.svg";
import Twitter from "../../Assets/icons/Twitter.svg";
import Linkedin from "../../Assets/icons/Linkedin.svg";
import Instagram from "../../Assets/icons/Instagram.svg";
import PartnerTabs from "./partnerTabs";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = () => ({
  headersection: {
    backgroundColor: "#fff",
    padding: "24px 5px 40px 10px"
  },
  headtitle: {
    color: "#0da0e6",
    textTransform: "capitalize"
  },
  subheading: {
    textTransform: "capitalize"
  },
  partnercontent: {
    padding: "20px 0px 11px 1px"
  },
  partnertitle: {
    fontSize: "12px",
    // textAlign: "center",
    padding: "10px 0px 5px 0px",
    color: "#ababab"
  },
  eventname: {
    fontSize: "14px",
    textAlign: "center",
    color: "#626262"
  },
  spocName: {
    fontSize: "14px",
    textAlign: "center",
    color: "#626262",
    textTransform: "capitalize"
  },
  partnerbasicdetail: {
    margin: "50px 0px 1px 26px",
    textAlign: "center"
  },
  tab: {
    display: "flex",
    alignItems: "center",
    margin: "30px 0px 25px 1px",
    backgroundColor: "#f8f6f6",
    color: "blue"
  },
  root: {
    flexGrow: 1,
    backgroundColor: "rgb(239, 243, 246)"
  },
  social: {
    width: "7%",
    margin: "30px 10px"
  },
  downloadButton: {
    textTransform: "capitalize"
  },
  partnerLogo: {
    maxWidth: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "103px"
  },
  logo2: {
    width: "70%",
    padding: "31px 0px 0px 0px"
  }
});
class ViewaPartner extends React.Component {
  state = {
    value: 0,
    selectedIndex: 2
  };

  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.getPartner(id);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.partner !== prevState.partner) {
      return { partner: nextProps.partner };
    } else return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.partner !== this.props.partner) {
      this.setState({
        partner: this.props.partner
      });
    }
  }

  onChange = e => {
    this.setState({ search: e.target.value }, () => {
      this.props.getAssociates(e.target.value);
    });
  };

  render() {
    const { classes } = this.props;
    const { partner } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />

        <main>
          <div className={classes.applicationpage}>
            <div className={classes.headersection}>
              <Grid container item xs={12} lg={12} spacing={2}>
                <Grid item xs={3} lg={3} className={classes.partnerLogo}>
                  <img
                    className={classes.logo2}
                    src={partner.logo ? `${ASSET_URL}${partner.logo}` : logo2}
                    alt=""
                    width="100%"
                  />
                </Grid>

                <Grid item xs={5} lg={5}>
                  <div className={classes.partnercontent}>
                    <Typography variant="h6" className={classes.headtitle}>
                      {/* Headstarts */}
                      {partner.partner_name}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.subheading}
                    >
                      {partner.category} Partner
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={4} lg={4} style={{ textAlign: "right" }}>
                  <img src={Linkedin} alt="" className={classes.social} />
                  <img
                    src={Facebook}
                    alt=""
                    className={classes.social}
                    style={{ borderRadius: "50%" }}
                  />
                  <img src={Twitter} alt="" className={classes.social} />
                  <img src={Instagram} alt="" className={classes.social} />
                </Grid>
              </Grid>
              <div className={classes.partnerbasicdetail}>
                <Grid container item xs={12} lg={12} spacing={2}>
                  <Grid item xs={2} lg={2}>
                    <Typography variant="h1" className={classes.partnertitle}>
                      SPOC Name
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.spocName}
                    >
                      {partner.user && partner.user.first_name}{" "}
                      {partner.user && partner.user.last_name}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} lg={2}>
                    <Typography variant="h1" className={classes.partnertitle}>
                      Contact Number
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.eventname}
                    >
                      {/* +91 0000000000 */}
                      {partner.user && partner.user.contact_number}
                    </Typography>
                  </Grid>

                  <Grid item xs={3} lg={3}>
                    <Typography variant="h1" className={classes.partnertitle}>
                      Email ID
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.eventname}
                    >
                      {/* Dharuvi@gmail.com */}
                      {partner.user && partner.user.email}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} lg={2}>
                    <Typography variant="h1" className={classes.partnertitle}>
                      Location
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.eventname}
                    >
                      {partner.location && partner.location.cityName
                        ? partner.location.cityName
                        : "-"}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} lg={2}>
                    <Typography variant="h1" className={classes.partnertitle}>
                      Documents
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.downloadButton}
                    >
                      Download
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
            {/*end headersection */}
            <PartnerTabs
              company={this.state.company}
              id={this.state.id}
              data={this.state.partner}
            />
          </div>
        </main>
      </React.Fragment>
    );
  }
}

ViewaPartner.propTypes = {};

const mapStateToProps = state => {
  return {
    partner: state.partners.partner
  };
};

export default connect(
  mapStateToProps,
  { getPartner }
)(withStyles(styles)(ViewaPartner));
