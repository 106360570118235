import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const styles = () => {
  return {
    box: {
      padding: "2%"
    }
  };
};
function SkeletonTable(props) {
  const { dashboard } = props;
  return dashboard ? (
    <Grid container spacing={2} justify="left">
      <Grid item xs={4}>
        <Skeleton width="100%" />
      </Grid>
      <Grid item xs={4}>
        <Skeleton width="100%" />
      </Grid>
      <Grid item xs={4}>
        <Skeleton width="100%" />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={3} justify="center">
      <Grid item xs={2}>
        <Skeleton width="100%" />
      </Grid>
      <Grid item xs={2}>
        <Skeleton width="100%" />
      </Grid>
      <Grid item xs={2}>
        <Skeleton width="100%" />
      </Grid>
      <Grid item xs={2}>
        <Skeleton width="100%" />
      </Grid>
      <Grid item xs={2}>
        <Skeleton width="100%" />
      </Grid>
      <Grid item xs={2}>
        <Skeleton width="100%" />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(SkeletonTable);
