import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Table, TableHead, TableRow, TableBody } from "@material-ui/core";
import SkeletonTable from "../../Components/Common/SkeletonTable";
import Cell from "../../Components/Common/Table/TableCell";
import Header from "../../Components/Common/Table/TableHeader";
import Pagination from "../../Components/Common/Table/TablePagination";
import NoRecordInViewPage from "../../Components/Common/NoRecordInViewPage";
import NoRecordaFoundTable from "../../Components/Common/NoRecordaFoundTable";

const styles = () => ({
  mainOverview: {
    padding: "20px 40px"
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE"
    }
  },
  emailid: {
    textTransform: "lowercase"
  }
});

class AllContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10
    };
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };
  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <Header>Sr</Header>
          <Header>Name</Header>
          <Header>Designation</Header>
          <Header>Email ID</Header>
          <Header>Contact No</Header>
        </TableRow>
      </TableHead>
    );
  };
  renderBody = () => {
    const { classes, isSearch, search } = this.props;
    const { page, rowsPerPage } = this.state;
    return (
      <TableBody>
        {this.props.company_contact &&
          this.props.company_contact.length <= 0 &&
          isSearch && (
            <NoRecordaFoundTable
              colSpan="7"
              text={`No records found for your text "${search}"`}
            />
          )}
        {this.props.company_contact &&
          this.props.company_contact.length <= 0 &&
          !isSearch && (
            <NoRecordInViewPage
              colSpan="5"
              text={`No Contacts found for this company`}
            />
          )}
        {this.props.company_contact &&
          this.props.company_contact
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, id) => {
              return (
                <React.Fragment key>
                  <TableRow className={classes.root1}>
                    <Cell>{page * rowsPerPage + id + 1}</Cell>
                    <Cell>
                      {item.user && item.user.first_name} &nbsp;
                      {item.user && item.user.last_name}
                    </Cell>
                    <Cell>{item.designation}</Cell>
                    <Cell lower>{item.user && item.user.email}</Cell>

                    <Cell>{item.user && item.user.contact_number}</Cell>
                  </TableRow>
                </React.Fragment>
              );
            })}
      </TableBody>
    );
  };
  render() {
    const { classes } = this.props;
    const itemLength = this.props.company_contact
      ? this.props.company_contact.length
      : 0;
    const { loading } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          {loading ? (
            Array.from(new Array(10)).map(id => {
              return <SkeletonTable key={id} />;
            })
          ) : (
            <div className={classes.root}>
              <div className={classes.header}>
                <Table className={classes.table}>
                  {this.renderHead()}
                  {this.renderBody()}
                </Table>
              </div>
              <Pagination
                count={itemLength}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          )}
        </main>
      </React.Fragment>
    );
  }
}

AllContact.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AllContact);
