import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import ApplicationPage from "./HeaderApplication";
import { Tabs, Tab, Typography } from "@material-ui/core";
import Team from "./Team";
import History from "./History";
import About from "./About";
import { connect } from "react-redux";
import { getApplication } from "../../Actions/applicationActions";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: "0px 14px",
        marginTop: " 14px",
        backgroundColor: "#fff"
      }}
    >
      {props.children}
    </Typography>
  );
}
const styles = () => ({
  layout: {
    backgroundColor: "#fff"
  },
  detailsection: {
    backgroundColor: "#eff3f6",
    padding: "30px 0px 0px"
  }
});
const AntTabs = withStyles({
  indicator: {
    color: "#435bf2",
    backgroundColor: " #4682B4",
    top: "38px",
    display: "none"
  }
})(Tabs);

const AntTab = withStyles(() => ({
  selected: {
    color: " #4D7CFE !important",
    backgroundColor: "#E2EAFF",
    borderRadius: "5px"
  },
  root: {
    minWidth: "60px",
    maxWidth: "100%",
    textTransform: "capitalize",
    minHeight: "0px !important"
  }
}))(props => <Tab disableRipple {...props} />);
class ViewApplication extends Component {
  constructor(props) {
    super(props);
    this.state = { application: [], value: 0 };
  }
  handleChange = (createdApplication, value) => {
    this.setState({ value });
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getApplication(id);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.application !== prevState.application) {
      return { application: nextProps.application };
    } else return [];
  }
  render() {
    const { classes, history } = this.props;
    const { value } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <ApplicationPage data={this.state.application} />
          <div className={classes.detailsection}>
            <AntTabs
              value={value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <AntTab label="About" />
              <AntTab label="Team" />
              <AntTab label="History" />
            </AntTabs>
            {value === 0 && (
              <TabContainer>
                <About data={this.state.application} />
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <Team data={this.state.application} history={history} />
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer>
                <History data={this.state.application} />
              </TabContainer>
            )}
          </div>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    application: state.applications.application
  };
};

export default connect(
  mapStateToProps,
  { getApplication }
)(withStyles(styles)(ViewApplication));
