export const PartnerType = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" }
];
export const MemberSelection = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" }
];
export const eventType = [
  {
    value: "hackathon",
    label: "Hackathon"
  },
  {
    value: "ideathon",
    label: "Ideathon"
  },
  {
    value: "hiring_competition",
    label: "Hiring Challenge"
  },
  {
    value: "competition",
    label: "Competition"
  },
  {
    value: "pitch_a_thon",
    label: "Pitch-E-Thon"
  }
];
export const EventMode = [
  { value: "online", label: "Online" },
  { value: "offline", label: "Offline" },
  { value: "both", label: "Both" }
];
export const skillsOptions = [
  { value: "sales", label: "Sales" },
  { value: "b2b_sales", label: "B2B Sales" },
  { value: "marketing", label: "Marketing" },
  { value: "funding", label: "Funding" },
  { value: "opertions", label: "Opertions" },
  { value: "product_development", label: "Product Development" },
  { value: "finance", label: "Finance" },
  { value: "legal", label: "Legal" }
];
export const StrengthOptions = [
  { value: "0-10", label: "0-10" },
  { value: "11-55", label: "11-55" },
  { value: "51-200", label: "51-200" },
  { value: "201-500", label: "201-500" },
  { value: "500+", label: "500+" }
];
export const categoryOptions = [
  { value: "technology", label: "Technology" },
  { value: "ecosystem", label: "Ecosystem" },
  { value: "outreach", label: "Outreach" },
  { value: "knowledge", label: "Knowledge" },
  { value: "venue", label: "Venue" },
  { value: "ticketing", label: "Ticketing" },
  { value: "media", label: "Media" },
  { value: "investment", label: "Investment" },
  { value: "payment", label: "Payment" }
];
