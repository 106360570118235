import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import ProgressChart from "../Common/Charts/ProgressChart";

const styles = () => {
  return {
    appBar: {
      position: "relative",
      backgroundColor: "transparent"
    },
    stack: {
      marginBottom: "20px",
      borderRadius: `6px`,
      border: "1px solid #e6eaee",
      backgroundColor: "white",
      boxShadow: "0 5px 10px rgba(0,0,0,0.04), 0 5px 5px rgba(0,0,0,0.05)"
    },
    // stackHeader: {
    //   textAlign: "left",
    //   borderTopLeftRadius: `6px`,
    //   borderTopRightRadius: `6px`,
    //   paddingTop: "15px",
    //   paddingBottom: "15px",
    //   paddingLeft: "20px",
    //   color: "white",
    //   fontSize: "14px",
    //   backgroundColor: "#4D7CFE",
    //   fontWeight: 600
    // },
    stackHeader: {
      textAlign: "left",
      borderRadius: `20px 20px 0 0 `,
      paddingTop: "15px",
      paddingBottom: "5px",
      paddingLeft: "20px",
      color: "#888",
      fontSize: "14px"
    },
    // stackValue: {
    //   textAlign: "left",
    //   paddingLeft: "20px",
    //   paddingBottom: "10px",
    //   paddingTop: "10px",
    //   color: "#110F24",
    //   fontSize: "24px",
    //   fontWeight: "bold"
    // },
    stackValue: {
      textAlign: "left",
      paddingLeft: "20px",
      paddingBottom: "20px",
      color: "#110F24",
      fontSize: "24px",
      fontWeight: "bold"
    },
    default: {
      backgroundColor: "whitesmoke"
    }
  };
};
class StackCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, stackText, stackVal, type } = this.props;
    let stackType = this.props.stackType || "default";
    return (
      <Grid
        container
        direction="column"
        justify="center"
        className={classes.stack}
      >
        <Grid
          item
          xs={12}
          className={classNames(classes[stackType], classes.stackHeader)}
        >
          {stackText}
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            className={classes.stackValue}
            justify="center"
            alignItems="center"
          >
            {type && (
              <Grid item xs={6}>
                {type === "chart" && (
                  <ProgressChart percent={this.props.percent} />
                )}
                {type === "barChart" && (
                  <ProgressChart percent={this.props.percent} />
                )}
              </Grid>
            )}
            <Grid item xs={type ? 6 : 12}>
              {Number(stackVal).toLocaleString("en")}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

StackCard.propTypes = {};

export default withStyles(styles)(StackCard);
