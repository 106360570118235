import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
const styles = theme => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -20,
    marginLeft: -20,
    color: "lightgrey"
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  },
  MuiCircularProgress: {
    svg: {
      color: "grey"
    }
  }
});
const theme = () => {
  const getSpecifics = (type, property) => {
    if (type === "submit") {
      switch (property) {
        case "bgcolor":
          return "#4D7CFE";
        case "hoverColor":
          return "#3565E9";
        case "textColor":
          return "white";
        default:
          break;
      }
    }
    if (type === "cancel") {
      switch (property) {
        case "bgcolor":
          return "#F1F1F1";
        case "hoverColor":
          return "#E2E2E2";
        case "textColor":
          return "#4D7CFE";

        default:
          break;
      }
    }
  };
  return createMuiTheme({
    overrides: {
      MuiButton: {
        root: {
          width: "146.93px",
          height: "46px",
          backgroundColor: props => getSpecifics(props.type, "bgcolor"),
          color: props => getSpecifics(props.type, "textColor"),
          "&:hover": {
            backgroundColor: props => getSpecifics(props.type, "hoverColor")
          }
        }
      }
    }
  });
};

function CustomButton(props) {
  const { classes } = props;
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.wrapper}>
        <Button {...props} disabled={props.loader}>
          {props.label}
        </Button>
        {props.loader ? (
          <CircularProgress className={classes.buttonProgress} />
        ) : (
          ""
        )}
      </div>
    </ThemeProvider>
  );
}
export default connect()(withStyles(styles)(CustomButton));
