import React from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { capitalize } from "../../Utils/StringFunctions";

const styles = () => {
  return {
    boldStyles: {
      // fontWeight: "bold"
    }
  };
};

const NameTruncate = props => {
  const { text, classes, amount } = props;
  return (
    <span>
      <Tooltip title={capitalize(text)} className={classes.customTooltip}>
        <span className={classes.boldStyles}>
          {` ${text.substring(0, amount)}${amount < text.length ? "..." : " "}`}
        </span>
      </Tooltip>
    </span>
  );
};

export default withStyles(styles)(NameTruncate);
