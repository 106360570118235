import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
const styles = () => {
  return {
    image: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "center"
    },
    maintext: {
      color: "lightgrey",
      fontSize: "1rem"
    },
    headerSpacing: {
      marginTop: "15%"
    }
  };
};

const NoRecordInViewPage = props => {
  const { text, classes, colSpan } = props;
  return (
    <tr className={classes.root1} style={{ textAlign: "center" }}>
      <td colSpan={colSpan} style={{ padding: "10px 0px" }}>
        <Typography variant="h6" className={classes.maintext}>
          {text}
        </Typography>
      </td>
    </tr>
  );
};

export default withStyles(styles)(NoRecordInViewPage);
