import React, { Component } from "react";
import { Table, TableRow, TableHead, TableBody } from "@material-ui/core";
import Header from "../../Components/Common/Table/TableHeader";
import Cell from "../../Components/Common/Table/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import checkPermission from "../../Utils/CheckPermissions";
import { spacecapitalize } from "../../Utils/StringFunctions";

const moment = require("moment");

const styles = () => ({
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE"
    }
  }
});

class EventTable extends Component {
  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <Header>Sr </Header>
          <Header>Date</Header>
          <Header>Event</Header>
          {checkPermission("COMPANY_VIEW") && <Header>Company </Header>}
          <Header>Application</Header>
          <Header>Status</Header>
        </TableRow>
      </TableHead>
    );
  };
  renderBody = () => {
    const { data, classes } = this.props;
    return (
      <TableBody>
        {data && data.event
          ? data.event.map((item, id) => {
              return (
                <React.Fragment key>
                  <TableRow className={classes.root1}>
                    <Cell>{id + 1}</Cell>
                    <Cell>
                      {moment(item.event ? item.event.start_date : "").format(
                        "DD/MM/YYYY"
                      )}
                    </Cell>
                    <Cell>{item.event ? item.event.event_name : ""}</Cell>
                    {checkPermission("COMPANY_VIEW") && (
                      <Cell>
                        {item.event && item.event.company
                          ? item.event.company.company_name
                          : ""}
                      </Cell>
                    )}
                    <Cell>
                      {item && item.application
                        ? item.application.team_name
                        : ""}
                    </Cell>
                    <Cell>
                      {item.status ? spacecapitalize(item.status) : ""}
                    </Cell>
                  </TableRow>
                </React.Fragment>
              );
            })
          : ""}
      </TableBody>
    );
  };
  render() {
    return (
      <div>
        <Table>
          {this.renderHead()}
          {this.renderBody()}
        </Table>
      </div>
    );
  }
}
export default connect()(withStyles(styles)(EventTable));
