import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import ApplicationCard from "../../Components/Common/Cards/ApplicationCard";
import { getApplications } from "../../Actions/applicationActions";
import { connect } from "react-redux";
import axios from "axios";
import { ExportToCsv } from "export-to-csv";
import SearchBar from "../../Components/Common/SearchBar";
import SkeletonCard from "../../Components/Common/Cards/SkeletonCard";
import NoRecordsFound from "../../Components/Common/NoRecordsFound";
import NoDataFound from "../../Components/Common/NoDataFound";

const styles = () => ({
  subHeader: {
    display: "flex",
    alignItems: "center",
    margin: "30px 0px"
  },
  main: {
    background: "#fff",
    padding: "24px"
  }
});
class AdminApplications extends React.Component {
  constructor(props) {
    super(props);
    this.state = { applications: [], search: "", isSearch: false };
  }

  handleChange = () => {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Application Data",
      filename: "Application Data",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.props.applications.data);
  };

  componentDidMount() {
    this.props.getApplications();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.applications !== prevState.applications) {
      return { applications: nextProps.applications };
    } else return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applications !== this.props.applications) {
      this.setState({
        applications: this.props.applications
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
    if (prevProps.applicationstatus !== this.props.applicationstatus) {
      this.setState({
        applicationstatus: this.props.applicationstatus
      });
      if (this.props.applicationstatus.success) this.props.getApplications();
    }
  }
  changeStatus(id, value) {
    axios
      .put(`/api/application/${id}`, { status: value })
      .then(() => {})
      .catch(err => console.log(err, "error is"));
  }
  searchApplications = (search, isSearch) => {
    this.setState({ isSearch: true, search });
    this.props.getApplications(search, isSearch);
  };

  render() {
    const { classes } = this.props;
    const { loading, isSearch, search, applications } = this.state;

    if (!loading && applications && applications.length <= 0 && !isSearch) {
      return (
        <div style={{ textAlign: "center" }}>
          <NoDataFound />
        </div>
      );
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.main}>
          <div style={{ marginBottom: "10px" }}>
            <div className={classes.subHeader}>
              <SearchBar
                type="Search Applications"
                onSearch={this.searchApplications}
              />
              {/* TODO IN VERSION-2 */}
              {/* <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.exportButton}
                onClick={this.handleChange}
                style={{ marginRight: "10px" }}
              >
                Export
              </Button> */}
            </div>
          </div>

          {applications && applications.length <= 0 && isSearch && (
            <NoRecordsFound
              text={`No records found for your text "${search}"`}
            />
          )}
          <main className={classes.layout}>
            <Grid container spacing={2}>
              {loading
                ? Array.from(new Array(6)).map(id => {
                    return (
                      <Grid item key={id} xs={12} lg={4} sm={6}>
                        <SkeletonCard />
                      </Grid>
                    );
                  })
                : applications &&
                  applications.length > 0 &&
                  applications.map((element, id) => {
                    return (
                      <Grid item xs={3} sm={4} key={id}>
                        <ApplicationCard
                          data={element}
                          changeStatus={this.changeStatus}
                        />
                      </Grid>
                    );
                  })}
            </Grid>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

AdminApplications.propTypes = {};

const mapStateToProps = state => {
  return {
    applications: state.applications.applications || {},
    loading: state.applications.loading,
    applicationstatus: state.applications.applicationstatus
  };
};

export default connect(
  mapStateToProps,
  { getApplications }
)(withStyles(styles)(AdminApplications));
