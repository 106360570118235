import { ADD_NOTIFICATION } from "../Actions/types";

const initialState = {
  notification: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      //   return Object.assign({}, state, {});
      return {
        ...state,
        notification: { ...action.payload, show: true }
      };
    default:
      return state;
  }
};
