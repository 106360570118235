import React from "react";
import { Card, CardContent, Typography, Paper, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Green from "@material-ui/core/colors/green";
import { MapPin } from "react-feather";
import Partners from "../../../Assets/Partners.png";
import { capitalize } from "../../../Utils/StringFunctions";
import SocialHandle from "../../Common/SocialHandle";
import Settings from "../../Common/Settings";
import CloseIcon from "@material-ui/icons/Close";
import { getRole } from "../../../Utils/CheckPermissions";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;

const styles = theme => {
  const borderRadius = theme.shape.borderRadius * 4;

  return {
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      boxShadow: "0 5px 10px rgba(0,0,0,0.04), 0 5px 5px rgba(0,0,0,0.05)",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      backgroundColor: "#FCFDFE",
      position: "relative",
      border: "1px solid #efefef"
    },
    cardContent: {
      flexGrow: 1,
      textAlign: "center",
      "&:last-child": {
        paddingBottom: "16px"
      }
    },
    containerPaper: {
      border: `1px solid #efefef`,
      marginBottom: "11px",
      backgroundColor: "#fff"
    },
    profileImage: {
      display: "flex",
      flexDirection: "row",
      height: 50,
      justifyContent: "center",
      alignItems: "center",
      margin: "38px 0px 15px 0px"
    },
    status: {
      color: "white",
      backgroundColor: Green[700],
      padding: "5px 8px",
      fontSize: 14
    },
    eventInfo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    },
    spocName: {
      fontWeight: "normal",
      fontSize: 14,
      textAlign: "left",
      color: "#626262"
    },
    spocContact: {
      fontWeight: "normal",
      fontSize: 12,
      textAlign: "right",
      color: "#626262"
    },
    spocEmail: {
      fontWeight: "normal",
      fontSize: 12,
      textAlign: "right",
      color: "#ababab"
    },
    cardFooter: {
      justifyContent: "space-around",
      "& a": {
        textDecoration: "none"
      },
      "& button": {
        color: "#fff",
        borderRadius: `${borderRadius}px`,
        backgroundColor: "#959595",
        "&:hover": {
          color: "#fff",
          borderRadius: `${borderRadius}px`,
          backgroundColor: "#959595"
        }
      }
    },
    // customBadge: {
    //   position: "absolute",
    //   backgroundColor: "#ffbb00",
    //   color: "white",
    //   padding: "0px 15px",
    //   right: 11,
    //   top: 18,
    //   "-webkitClipPath":
    //     "polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 7% 50%, 0% 0%)",
    //   clipPath: "polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 7% 50%, 0% 0%)",
    //   fontSize: "13px",
    //   fontWeight: "bold",
    //   height: "20px"
    // },
    social: {
      height: "20px",
      width: "20px"
    },
    partnerName: {
      fontWeight: "semi-bold",
      fontSize: 14,
      textAlign: "center",
      color: "#4d7cfe",
      margin: "20px 0px 0px",
      textTransform: "capitalize"
    },
    location: {
      fontWeight: "normal",
      fontSize: 12,
      textAlign: "left",
      color: "#626262"
    },
    locationIcon: {
      color: "#626262",
      margin: "10px 4px"
    },
    verticalIcon: {
      display: "flex",
      justifyContent: "flex-end",
      position: "absolute",
      top: "10px",
      right: "10px"
    },
    Partners: {
      maxHeight: "90px"
    }
  };
};

function PartnerCard(props) {
  const { classes, data, history } = props;
  const role = getRole();
  const handleEditButton = () => {
    history.push({
      pathname: `/partner/edit/${data._id}`,
      params: { isGlobalHidden: role === "admin" ? false : true }
    });
  };
  const handleCardClick = () => {
    props.viewMore && history.push(`/partners/${data._id}`);
  };
  return (
    <Card className={classes.card}>
      <Paper className={classes.containerPaper}>
        <div className={classes.verticalIcon}>
          {props.collapseIcon ? (
            <Settings editMethod={handleEditButton} />
          ) : (
            <CloseIcon />
          )}
        </div>
        <CardContent className={classes.cardContent} onClick={handleCardClick}>
          <div className={classes.profileImage}>
            <div className={classes.image}>
              {data.logo ? (
                <img
                  className={classes.Partners}
                  src={`${ASSET_URL}${data.logo}`}
                  alt=""
                  width="91px"
                />
              ) : (
                <img
                  className={classes.Partners}
                  src={Partners}
                  alt=""
                  width="91px"
                />
              )}
            </div>
          </div>
          <Typography component="h2" className={classes.partnerName}>
            {capitalize(data.partner_name)}
          </Typography>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            direction="row"
            justify="center"
          >
            <Grid item>
              <MapPin size={16} className={classes.locationIcon} />
            </Grid>
            <Grid item>
              <Typography
                gutterBottom
                component="p"
                variant="subtitle2"
                className={classes.location}
              >
                {data.location ? data.location.cityName : ""} |{" "}
                {data.category ? capitalize(data.category) : ""} Partner
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                className={classes.iconContainer}
                justify="center"
                spacing={2}
              >
                <Grid item>
                  <Paper className={classes.paper}>
                    <SocialHandle
                      type="linkedin"
                      width="20px"
                      height="20px"
                      url={data.linkedin ? data.linkedin : ""}
                    />
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={classes.paper}>
                    <SocialHandle
                      type="facebook"
                      width="20px"
                      height="20px"
                      url={data.facebook ? data.facebook : ""}
                    />
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={classes.paper}>
                    <SocialHandle
                      type="twitter"
                      width="20px"
                      height="20px"
                      url={data.twitter ? data.twitter : ""}
                    />
                  </Paper>
                </Grid>

                <Grid item>
                  <Paper className={classes.paper}>
                    <SocialHandle
                      type="instagram"
                      width="20px"
                      height="20px"
                      url={data.instagram ? data.instagram : ""}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
      <Paper className={classes.containerPaper}>
        <CardContent className={classes.cardContent}>
          <div className={classes.eventInfo}>
            <div className={classes.eventapplicants}>
              <Typography
                variant="subtitle2"
                component="p"
                className={classes.spocName}
              >
                {data.user ? capitalize(data.user.first_name) : ""}{" "}
                {data.user ? capitalize(data.user.last_name) : ""}
              </Typography>
            </div>
            <div className={classes.eventDate}>
              <Typography
                variant="subtitle2"
                component="p"
                className={classes.spocContact}
              >
                {data.user ? data.user.contact_number : ""}
              </Typography>

              <Typography
                variant="subtitle1"
                component="p"
                className={classes.spocEmail}
              >
                {data.user ? capitalize(data.user.email) : ""}
              </Typography>
            </div>
          </div>
        </CardContent>
      </Paper>

      {/* {props.viewMore && (
        <CardActions className={classes.cardFooter}>
          <Link to={`/partners/${data._id}`}>
            <Button variant="contained" className={classes.button} size="small">
              View More
            </Button>
          </Link>
        </CardActions>
      )} */}
    </Card>
  );
}
export default withStyles(styles)(PartnerCard);
