import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "./Header";
import { connect } from "react-redux";
import { getApplicant } from "../../Actions/applicantActions";
import EventTable from "./EventTable";
const styles = () => ({
  layout: {
    backgroundColor: "#fff"
  },
  eventTable: {
    background: "white",
    marginTop: "3%"
  }
});

class ViewaApplicants extends Component {
  constructor(props) {
    super(props);
    this.state = { application: [], value: 0 };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getApplicant(id);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.applicant !== prevState.applicant) {
      return { applicant: nextProps.applicant };
    } else return [];
  }
  render() {
    const { classes } = this.props;
    const { applicant } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Header data={applicant} />
        </main>
        <div className={classes.eventTable}>
          <EventTable data={applicant} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    applicant: state.applicants.applicant
  };
};

export default connect(mapStateToProps, { getApplicant })(
  withStyles(styles)(ViewaApplicants)
);
