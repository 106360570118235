import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Banner from "../../../Assets/application_banner.png";
import StatusSelector from "../../Common/StatusSelector";
import { capitalize } from "../../../Utils/StringFunctions";
import Tooltip from "../../Common/NameToolTip";
import checkPermission from "../../../Utils/CheckPermissions";
const ASSET_URL = process.env.REACT_APP_ASSET_URL;

const styles = () => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    border: "1px solid #efefef",
    borderRadius: "5px",
    background: "#FCFDFE",
    position: "relative",
    boxShadow: "0 5px 10px rgba(0,0,0,0.04), 0 5px 5px rgba(0,0,0,0.05)"
  },
  footerHeader: {
    textAlign: "center",
    fontSize: "12px",
    color: "#ababab"
  },
  footerHeaderValue: {
    textAlign: "center",
    fontWeight: "normal",
    color: "#626262",
    marginTop: "10px"
  },
  cardHeaderCont: {
    borderRadius: "5px",
    background: "#fff",
    border: "1px solid #efefef",
    marginBottom: 10,
    padding: "0px 0px 10px !important"
  },
  cardMiddleCont: {
    borderRadius: "5px",
    background: "#fff",
    border: "1px solid #efefef",
    marginBottom: 10,
    padding: "10px !important"
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-around"
  },
  banner: {
    width: "100%",
    height: "150px"
  },
  image: {
    height: "auto"
  },
  applicationName: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#4d7cfe",
    textTransform: "capitalize"
  },
  applicationType: {
    fontWeight: "normal",
    fontSize: " 12px",
    color: "#626262",
    textTransform: "capitalize",
    marginBottom: "18px"
  },
  adminName: {
    fontWeight: "normal",
    fontSize: "14px",
    textAlign: "left",
    color: "#626262",
    textTransform: "capitalize"
  },
  eventInfo: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "6px"
  },
  adminContact: {
    fontWeight: "normal",
    fontSize: "12px",
    textAlign: "right",
    color: " #626262"
  },
  adminEmail: {
    fontWeight: "normal",
    fontSize: "12px",
    textAlign: "right",
    color: "#ababab"
  },
  eventName: {
    fontWeight: "normal",
    fontSize: "14px",
    color: " #626262",
    marginBottom: "0px",
    textTransform: "capitalize"
  },
  companyName: {
    fontWeight: "normal",
    fontSize: "12px",
    color: "#ababab",
    marginBottom: "12px",
    textTransform: "capitalize"
  },
  viewmoreButton: {
    borderRadius: "50px",
    "&:hover": {
      color: "#211B4E",
      borderRadius: "50px"
    }
  },
  morecontLink: {
    textDecoration: "none"
  },
  cardTopCont: {
    textAlign: "left",
    padding: "4px 15px 0px"
  }
});

function ApplicationCard(props) {
  const { classes, data } = props;
  const eventNameChar = data.event ? data.event.event_name : "";
  const teamNameChar = data.team_name ? data.team_name : "";
  return (
    <Card className={classes.card}>
      <Paper className={classes.containerPaper}>
        <CardContent className={classes.cardHeaderCont}>
          <div className={classes.profileImage}>
            <div className={classes.image}>
              <img
                alt="logo"
                src={data.logo ? `${ASSET_URL}${data.logo}` : Banner}
                className={classes.banner}
              />
            </div>
          </div>
          <div className={classes.cardTopCont}>
            <Typography
              component="h2"
              variant="h4"
              className={classes.applicationName}
            >
              {teamNameChar.length > 40 ? (
                <Tooltip
                  amount="40"
                  text={data.team_name ? data.team_name : ""}
                />
              ) : data.team_name ? (
                data.team_name
              ) : (
                ""
              )}
            </Typography>
            <Typography
              gutterBottom
              component="p"
              variant="subtitle2"
              className={classes.applicationType}
            >
              {data.theme}
            </Typography>

            <Typography
              gutterBottom
              component="p"
              variant="subtitle2"
              className={classes.eventName}
            >
              {eventNameChar.length > 45 ? (
                <Tooltip
                  amount="45"
                  text={capitalize(data.event && data.event.event_name)}
                />
              ) : (
                capitalize(data.event && data.event.event_name)
              )}
            </Typography>
            {checkPermission("COMPANY_VIEW") && (
              <Typography
                gutterBottom
                component="p"
                variant="subtitle2"
                className={classes.companyName}
              >
                {data.event && data.event.company
                  ? capitalize(data.event.company.company_name)
                  : "-"}
              </Typography>
            )}
          </div>
          <Typography variant="body1" component="p">
            {/* <Chip
              label={data.event && data.event.type_of_event}
              className={classes.chip}
              variant="outlined"
              style={{ marginBottom: "40px" }}
            /> */}
          </Typography>

          <Grid
            container
            spacing={24}
            className={classes.footerContainer}
            justify="center"
            alignItems="center"
          >
            <Grid item xs>
              <div className={classes.footerHeaderValue}>
                {data.active_members.length}
              </div>
              <div className={classes.footerHeader}>Members</div>
            </Grid>
            <Grid item xs>
              <div className={classes.footerHeaderValue}>Students</div>
              <div className={classes.footerHeader}>Category</div>
            </Grid>

            <Grid item xs>
              <div className={classes.footerHeaderValue}>
                {moment(data.createdAt).format("DD/MM/YYYY")}
              </div>
              <div className={classes.footerHeader}>Date</div>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
      {/* {data.admin && ( */}
      <Paper className={classes.containerPaper}>
        <CardContent className={classes.cardMiddleCont}>
          <div className={classes.eventInfo}>
            <div className={classes.eventParticipant}>
              <Typography
                variant="subtitle2"
                component="p"
                className={classes.adminName}
              >
                {/* LEADER NAME */}
                {data.createdBy ? data.createdBy.first_name : ""}{" "}
                {data.createdBy ? data.createdBy.last_name : ""}
              </Typography>
            </div>
            <div className={classes.eventDate}>
              <Typography
                variant="subtitle2"
                component="p"
                className={classes.adminContact}
              >
                {/* LEADER CONTACT NUMBER*/}
                +91 {data.createdBy ? data.createdBy.contact_number : ""}
              </Typography>

              <Typography
                variant="subtitle1"
                component="p"
                className={classes.adminEmail}
              >
                {/* LEADER EMAIL ID */}
                {data.createdBy ? data.createdBy.email : ""}
              </Typography>
            </div>
          </div>
        </CardContent>
      </Paper>
      {/* )} */}
      <CardActions className={classes.cardFooter}>
        <Link to={`/application/${data._id}`} className={classes.morecontLink}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.viewmoreButton}
          >
            View More
          </Button>
        </Link>
        <StatusSelector
          selectedItem={data.status}
          id={data._id}
          changeStatus={props.changeStatus}
        />
      </CardActions>
    </Card>
  );
}
export default withStyles(styles)(ApplicationCard);
