import {
  ONBOARDED,
  REJECTED,
  ONHOLD,
  SHORTLISTED,
  REVOKED,
  PARTICIPATED,
  REGISTERED,
  view
} from "./StatusTypes";

const getApplicationTypes = applicationType => {
  const OnBoarded = [
    view.ONBOARDED,
    view.REJECTED,
    view.ONHOLD,
    view.SHORTLISTED,
    view.REVOKED,
    view.REGISTERED
  ];
  const Rejected = [view.REJECTED, view.SHORTLISTED, view.ONHOLD, view.REVOKED];

  const OnHold = [view.REJECTED, view.SHORTLISTED, view.REVOKED, view.ONHOLD];

  const ShortListed = [
    view.PARTICIPATED,
    view.REJECTED,
    view.REVOKED,
    view.ONHOLD,
    view.SHORTLISTED
  ];

  const Revoked = [view.REVOKED, view.SHORTLISTED, view.ONHOLD];

  const Participated = [view.WON, view.DISQUALIFIED, view.PARTICIPATED];
  const Registered = [view.REGISTERED, view.ONBOARDED];
  switch (applicationType) {
    case ONBOARDED:
      return OnBoarded;
    case REJECTED:
      return Rejected;
    case ONHOLD:
      return OnHold;
    case SHORTLISTED:
      return ShortListed;
    case REVOKED:
      return Revoked;
    case PARTICIPATED:
      return Participated;
    case REGISTERED:
      return Registered;
    default:
      break;
  }
};
export default getApplicationTypes;
