import React, { useEffect } from "react";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import xssFilter from "showdown-xss-filter";
import withStyles from "@material-ui/core/styles/withStyles";
import { ErrorMessage } from "formik";

const styles = () => ({
  error: {
    color: "#f53b57",
    fontSize: "0.75rem",
    fontWeight: "400",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ],
    textAlign: "left",
    lineHeight: "1em",
    marginTop: "7px",
    padding: "0px 0px 0px 5px"
  }
});
const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  extensions: [xssFilter]
});

function Editor(props) {
  const { classes, name } = props;
  const [value, setValue] = React.useState("");
  const [selectedTab, setSelectedTab] = React.useState("write");
  useEffect(() => {
    setValue(props.data);
  }, [props.data]);
  const handleEvent = value => {
    setValue(value);
    props.getval(value);
  };

  return (
    <div className="container">
      <ReactMde
        value={value}
        onChange={handleEvent}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={markdown =>
          Promise.resolve(converter.makeHtml(markdown))
        }
      />
      <div className={classes.error}>
        <ErrorMessage name={name} component="div" />
      </div>
    </div>
  );
}
export default withStyles(styles)(Editor);
