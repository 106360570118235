import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid } from "@material-ui/core";
import { getTeamMates } from "../../Actions/applicationActions";
import { connect } from "react-redux";
import SkeletonCard from "../../Components/Common/Cards/SkeletonCard";
import ApplicantCard from "../../Components/Common/Cards/ApplicantCard";

const styles = () => ({
  mainTeam: {
    padding: "20px 40px"
  },
  title: {
    padding: "24px 24px 11px",
    color: "lightgrey"
  }
});

class Team extends Component {
  state = {
    dense: false,
    secondary: false
  };
  componentDidMount() {
    this.props.getTeamMates(this.props.data._id);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.team &&
      nextProps.team.data !== prevState.team &&
      prevState.team.data
    ) {
      return { team: nextProps.team && nextProps.team.data };
    } else return [];
  }
  componentDidUpdate(prevProps) {
    if (prevProps.team !== this.props.team) {
      this.setState({
        team: this.props.team
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { team, loading } = this.state;
    if (team && team.length <= 0) {
      return (
        <div style={{ textAlign: "center" }}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item align="center" className={classes.noCoworker}>
              <span>No team members found for this event.</span>
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <Grid container spacing={2}>
            {loading
              ? Array.from(new Array(6)).map(id => {
                  return (
                    <Grid item key={id} xs={12} lg={4} sm={6}>
                      <SkeletonCard />
                    </Grid>
                  );
                })
              : team &&
                team.map((element, id) => {
                  return (
                    <Grid item xs={3} sm={4} key={id}>
                      <ApplicantCard
                        data={element}
                        history={this.props.history}
                      />
                    </Grid>
                  );
                })}
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    team: state.applications.team,
    loading: state.applications.loading
  };
};

export default connect(
  mapStateToProps,
  { getTeamMates }
)(withStyles(styles)(Team));
