import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { getEvents } from "../../Actions/eventActions";
import { Table, TableHead, TableRow, TableBody } from "@material-ui/core";
import { connect } from "react-redux";
import SearchBar from "../../Components/Common/SearchBar";
import AddFormButton from "../../Components/Common/AddFormButton";
import SkeletonTable from "../../Components/Common/SkeletonTable";
import Cell from "../../Components/Common/Table/TableCell";
import Header from "../../Components/Common/Table/TableHeader";
import Pagination from "../../Components/Common/Table/TablePagination";
import { Link } from "react-router-dom";
import EventStatus from "../../Components/Common/EventStatus";
import NoRecordaFoundTable from "../../Components/Common/NoRecordaFoundTable";
import NoDataFound from "../../Components/Common/NoDataFound";
// import EditButton from "../../Components/Common/EditButton";
// import { MapPin } from "react-feather";
import checkPermission from "../../Utils/CheckPermissions";
const moment = require("moment");

const styles = () => ({
  main: {
    background: "#fff",
    padding: "24px"
  },
  subHeader: {
    display: "flex",
    alignItems: "center",
    margin: "30px 0px"
  },
  viewLink: {
    textDecoration: "none",
    color: "#626262"
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE"
    }
  },
  innerData: {
    color: "#626262",
    fontSize: "11px",
    padding: "0px 0px 0px 5px"
  },
  locationSection: {
    fontSize: "12px"
  },
  locationIcon: {
    position: "relative",
    top: "1px",
    marginRight: "3px"
  }
});

class AdminEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      search: "",
      isSearch: false
    };
  }
  // methodFromParent(cell) {
  //   alert("Parent Component Method from " + cell + "!");
  // }
  // updateEventLocal(rowId, status) {
  //   let data = this.state.events[rowId];
  //   this.props.updateEvent(data.id, { status: status });
  //   this.props.getEvents();
  // }
  componentDidMount() {
    this.props.getEvents();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.events !== prevState.events) {
      return { events: nextProps.events };
    } else return null;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
    if (prevProps.statusUpdate !== this.props.statusUpdate) {
      this.setState({
        statusUpdate: this.props.statusUpdate
      });
      if (this.props.statusUpdate.success) this.props.getEvents();
    }
  }

  onBtExport() {
    var params = {
      name: true
    };
    this.gridApi.exportDataAsCsv(params);
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };
  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <Header>Sr.</Header>
          <Header>Date</Header>
          <Header>Event</Header>
          {checkPermission("COMPANY_VIEW") && <Header>Company </Header>}
          <Header>Location </Header>
          <Header>Applications</Header>
          <Header>Applicants</Header>
          <Header>Partners</Header>
          <Header>Associates</Header>
          <Header>Status</Header>
          {/* <Header></Header> */}
        </TableRow>
      </TableHead>
    );
  };
  renderBody = () => {
    const { classes } = this.props;
    const { page, rowsPerPage, isSearch, search, events } = this.state;
    return (
      <TableBody>
        {events && events.length <= 0 && isSearch && (
          <NoRecordaFoundTable
            height
            colSpan="9"
            text={`No records found for your text "${search}"`}
          />
        )}

        {events &&
          events
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, id) => {
              return (
                <React.Fragment key={id}>
                  <TableRow className={classes.root1}>
                    <Cell>{page * rowsPerPage + id + 1}</Cell>
                    <Cell>{moment(item.start_date).format("DD/MM/YYYY")}</Cell>
                    <Cell>
                      <Link
                        key={id}
                        to={`event/${item._id}`}
                        className={classes.viewLink}
                      >
                        {item.event_name}
                      </Link>
                      {/* <br />
                      <Typography
                        component="p"
                        variant="subtitle2"
                        className={classes.locationSection}
                      >
                        <MapPin size={14} className={classes.locationIcon} />
                        {item.location && item.location.cityName}
                      </Typography> */}
                    </Cell>
                    {checkPermission("COMPANY_VIEW") && (
                      <Cell>
                        {item.company && item.company.company_name
                          ? item.company.company_name
                          : "-"}
                      </Cell>
                    )}
                    <Cell>{item.location && item.location.cityName}</Cell>
                    <Cell>{item.application}</Cell>
                    <Cell>{item.applicants}</Cell>
                    <Cell>{item.partner}</Cell>
                    <Cell>{item.associate}</Cell>
                    <Cell>
                      <EventStatus
                        selectedItem={
                          item.event_status ? item.event_status : "published"
                        }
                        id={item ? item._id : ""}
                        changeStatus={this.props.changeStatus}
                      />
                    </Cell>
                  </TableRow>
                </React.Fragment>
              );
            })}
      </TableBody>
    );
  };
  searchEvents = (search, isSearch) => {
    this.setState({ isSearch: true, search });
    this.props.getEvents("", search, isSearch);
  };

  render() {
    const { classes } = this.props;
    const { loading, isSearch, events } = this.state;
    const itemLength = this.state.events ? this.state.events.length : 0;
    if (!loading && events && events.length <= 0 && !isSearch) {
      return (
        <div style={{ textAlign: "center" }}>
          <NoDataFound />
          <AddFormButton type="Event" url="/events/add" />
        </div>
      );
    }
    return (
      <div className={classes.main}>
        <div style={{ marginBottom: "10px" }}>
          {/* <ScreenHeader type=" Events" /> */}

          <div className={classes.subHeader}>
            <SearchBar type="Search Events" onSearch={this.searchEvents} />
            {/* TODO IN VERSION-2 */}
            {/* <Button
              variant="outlined"
              color="primary"
              size="small"
              className={classes.button}
              onClick={this.onBtExport.bind(this)}
              style={{ marginRight: "10px" }}
            >
              Export
            </Button> */}
            <AddFormButton type="Event" url="/events/add" />
          </div>
        </div>

        {loading ? (
          Array.from(new Array(10)).map(id => {
            return <SkeletonTable key={id} />;
          })
        ) : (
          <div className={classes.root}>
            <div className={classes.header}>
              <Table className={classes.table}>
                {this.renderHead()}
                {this.renderBody()}
              </Table>
            </div>
            <Pagination
              count={itemLength}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </div>
        )}
      </div>
    );
  }
}

AdminEvents.propTypes = {};

const mapStateToProps = state => {
  return {
    events: state.events.events,
    loading: state.events.loading,
    statusUpdate: state.events.updateEventStatus
  };
};

export default connect(
  mapStateToProps,
  { getEvents }
)(withStyles(styles)(AdminEvents));
