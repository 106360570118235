import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  Grid,
  Button
} from "@material-ui/core";
import { connect } from "react-redux";
import { getEvents, addEventToPartner } from "../../Actions/eventActions";
import SkeletonTable from "../../Components/Common/SkeletonTable";
import Cell from "../../Components/Common/Table/TableCell";
import Header from "../../Components/Common/Table/TableHeader";
import Pagination from "../../Components/Common/Table/TablePagination";
import NoRecordInViewPage from "../../Components/Common/NoRecordInViewPage";
import EventDropdown from "../../Components/Common/EventDropdown";
import { Formik, Form } from "formik";
import StatusColor from "../../Components/Common/EventStatusColor";
import checkPermission from "../../Utils/CheckPermissions";

const moment = require("moment");

const styles = () => ({
  mainOverview: {
    padding: "20px 40px"
  },
  viewLink: {
    textDecoration: "none",
    color: "#626262"
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE"
    }
  },
  addEventSection: {
    display: "flex",
    justifyContent: "flex-end"
  },
  addButton: {
    marginTop: "26px"
  },
  root: {
    marginTop: "3%"
  }
});

class EventsCommpany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10
    };
  }

  componentDidMount() {
    let id = this.props.data && this.props.data._id;
    this.props.getEvents("", "", "", id);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.events && nextProps.events !== prevState.events) {
      const partnerIDs =
        nextProps.events &&
        nextProps.events.map(el => {
          return el && el._id;
        });
      return { events: nextProps.events, partnerIDs };
    } else return null;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
    if (prevProps.eventPartner !== this.props.eventPartner) {
      this.setState({
        eventPartner: this.props.eventPartner
      });
      if (this.props.eventPartner.success)
        this.props.getEvents("", "", "", this.props.data._id);
    }
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };
  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <Header>Sr</Header>
          <Header>Date</Header>
          <Header>Event</Header>
          {checkPermission("COMPANY_VIEW") && <Header>Company</Header>}
          <Header>Location</Header>
          <Header>Status</Header>
        </TableRow>
      </TableHead>
    );
  };
  renderBody = () => {
    const { classes } = this.props;
    const { page, rowsPerPage, events } = this.state;
    return (
      <TableBody>
        {events &&
          events
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, id) => {
              const color = StatusColor(item.event_status);
              return (
                <React.Fragment key>
                  <TableRow className={classes.root1}>
                    <Cell>{page * rowsPerPage + id + 1}</Cell>
                    <Cell>{moment(item.start_date).format("DD/MM/YYYY")}</Cell>
                    <Cell>{item.event_name}</Cell>
                    {checkPermission("COMPANY_VIEW") && (
                      <Cell>{item.company && item.company.company_name}</Cell>
                    )}

                    <Cell>{item.location && item.location.cityName}</Cell>
                    <Cell>
                      <span style={{ color: color }}>{item.event_status}</span>
                    </Cell>
                  </TableRow>
                </React.Fragment>
              );
            })}
      </TableBody>
    );
  };

  render() {
    const { classes, data } = this.props;
    const itemLength = this.state.events ? this.state.events.length : 0;
    const { loading, partnerIDs, events } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.mainOverview}>
          <Formik
            initialValues={{
              event_id: "",
              partner_id: data._id
            }}
            onSubmit={(values, { resetForm }) => {
              const val = values.event_id ? values.event_id.value : "";
              values.event_id = val;
              this.props.addEventToPartner(values);
              resetForm(this.initialValues);
            }}
          >
            {({ setFieldValue, setFieldTouched, values, errors, touched }) => (
              <Form className={classes.container} noValidate autoComplete="off">
                <Grid
                  container
                  xs={12}
                  lg={12}
                  spacing={2}
                  className={classes.addEventSection}
                >
                  <Grid item xs={3} lg={3}>
                    <EventDropdown
                      values={values.event_id}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      touched={touched}
                      name="event_id"
                      eventdata={partnerIDs}
                    />
                  </Grid>
                  <Grid item xs={1} lg={1}>
                    <Button
                      variant="contained"
                      size="small"
                      type="submit"
                      className={classes.addButton}
                      disabled={!values.event_id}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          {!loading && events && events.length <= 0 && (
            <Grid container xs={12} justify="center">
              <NoRecordInViewPage
                colSpan="5"
                text={`No Events found for this company`}
              />
            </Grid>
          )}
          {loading
            ? Array.from(new Array(10)).map(id => {
                return <SkeletonTable key={id} />;
              })
            : events &&
              events.length > 0 && (
                <div className={classes.root}>
                  <div className={classes.header}>
                    <Table className={classes.table}>
                      {this.renderHead()}
                      {this.renderBody()}
                    </Table>
                  </div>
                  <Pagination
                    count={itemLength}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </div>
              )}
        </main>
      </React.Fragment>
    );
  }
}

EventsCommpany.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    events: state.events.events,
    loading: state.events.loading,
    eventPartner: state.events.eventPartner
  };
};

export default connect(
  mapStateToProps,
  { getEvents, addEventToPartner }
)(withStyles(styles)(EventsCommpany));
