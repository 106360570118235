import axios from "axios";
import { GET_CITIES, GET_CITIES_LOADING, GET_CITIES_ERRORS } from "./types";
// import { transformErrorsFromApi } from "./TransformError";

export const getCities = () => dispatch => {
  dispatch(getCitiesLoading());
  axios
    .get("/api/city")
    .then(res => {
      dispatch({
        type: GET_CITIES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CITIES_ERRORS,
        payload: err.response && err.response.data
      })
    );
};

//  Loading
export const getCitiesLoading = () => {
  return {
    type: GET_CITIES_LOADING
  };
};
