import React from "react";
import { Card, CardActions, CardContent, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import { MapPin } from "react-feather";
import User1 from "../../../Assets/applicant.png";
import SocialHandle from "../SocialHandle";

const styles = theme => {
  return {
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      boxShadow:
        "0 0 32px 0 rgba(12,12,13,.1), 0 2px 16px 0 rgba(12,12,13,.05)",
      backgroundColor: "#FCFDFE"
    },
    cardMedia: {
      paddingTop: "56.25%" // 16:9
    },
    profileImage: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    },
    avatar: {
      width: 70,
      height: 70,
      position: "relative",
      borderRadius: "100px",
      padding: "5px"
    },
    status: {
      color: "white",
      backgroundColor: Green[700],
      padding: "5px 8px",
      fontSize: 14
    },
    image: {
      height: "auto"
    },
    teamAvtar: {
      display: "flex",
      flexDirection: "row"
    },
    footerContainer: {
      marginTop: "10px",
      color: theme.palette.font.head1,
      fontSize: "12px"
    },
    footerHeader: {
      textAlign: "center",
      fontSize: "12px",
      color: "#ababab"
    },
    iconContainer: {
      marginTop: "10px"
    },
    userName: {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "14px",
      color: "#4d7cfe",
      textTransform: "capitalize"
    },
    userLocation: {
      textAlign: "center",
      fontWeight: "normal",
      fontSize: "12px",
      color: " #626262",
      textTransform: "capitalize"
    },
    titleContent: {
      marginTop: "10px",
      textAlign: "center",
      textTransform: "capitalize"
    },
    icons: {
      justifyContent: "center"
    },
    ownerDetail: {
      fontWeight: "normal",
      fontSize: "12px",
      textAlign: "center",
      color: "#626262",
      padding: "8px 0px"
    },
    footerHeaderValue: {
      textAlign: "center",
      fontSize: "17px",
      fontFamily: "Rubik",
      fontWeight: "normal",
      color: "#626262"
    },
    cardFooter: {
      textAlign: "center",
      display: "flex",
      justifyContent: "center"
    },
    cardContent: {
      border: "1px solid #efefef",
      backgroundColor: "#fff",
      marginBottom: "2%"
    }
  };
};

function ApplicantCard(props) {
  const { classes, data, history } = props;
  const handleCardClick = () => {
    history.push(`/applicants/${data.user ? data.user._id : ""}`);
  };
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent} onClick={handleCardClick}>
        <Paper className={classes.containerPaper}>
          <div className={classes.profileImage}>
            <div className={classes.image}>
              <img alt="User" src={User1} className={classes.avatar} />
            </div>
          </div>
          <Typography component="h2" variant="h5" className={classes.userName}>
            {data.user && data.user.first_name} &nbsp;
            {data.user && data.user.last_name}
          </Typography>
          <Typography component="p" variant="p" className={classes.ownerDetail}>
            {data.user && data.user.contact_number} |{" "}
            {data.user && data.user.email}
          </Typography>

          <Grid container spacing={2} justify="center">
            <Grid item>
              <SocialHandle type="linkedin" height="20px" width="20px" url="" />
            </Grid>
            <Grid item>
              <SocialHandle type="facebook" height="20px" width="20px" url="" />
            </Grid>
            <Grid item>
              <SocialHandle type="twitter" height="20px" width="20px" url="" />
            </Grid>
            <Grid item>
              <SocialHandle
                type="instagram"
                height="20px"
                width="20px"
                url=""
              />
            </Grid>
          </Grid>
        </Paper>
      </CardContent>
      {/* TODO DYNAMIC DATA  */}
      <CardContent className={classes.cardContent}>
        <Paper className={classes.containerPaper}>
          <Grid
            container
            spacing={24}
            className={classes.footerContainer}
            justify="center"
            alignItems="center"
          >
            <Grid item xs>
              <div className={classes.footerHeaderValue}>
                {/* {data.members_count + 1} */}10
              </div>
              <div className={classes.footerHeader}>Hackathon</div>
            </Grid>

            <Grid item xs>
              <div className={classes.footerHeaderValue}>
                {/* {data.status.charAt(0).toUpperCase() + data.status.slice(1)} */}
                2
              </div>
              <div className={classes.footerHeader}>Winning Team</div>
            </Grid>

            <Grid item xs>
              <div className={classes.footerHeaderValue}>
                <MapPin size={12} />
              </div>
              <div className={classes.footerHeader}>Ahmedabad</div>
            </Grid>
          </Grid>
        </Paper>
      </CardContent>
      <CardActions className={classes.cardFooter}></CardActions>
    </Card>
  );
}
export default withStyles(styles)(ApplicantCard);
