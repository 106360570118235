import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CardView from "../../Components/Common/Cards/Card";
import { getChallenges } from "../../Actions/ChallengesAction";
import { connect } from "react-redux";
import { getCurrentProfile } from "../../Actions/profileAction";
import { logoutUser } from "./../../Actions/authAction";
import { clearCurrentProfile } from "./../../Actions/profileAction";
import Authentication from "../Landing/Authentication";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => {
  return {
    "@global": {
      body: {
        backgroundColor: "#fff"
      }
    },
    toolbarTitle: {
      flex: 1
    },
    layout: {
      width: "auto",
      [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
        width: 1200,
        marginLeft: "auto",
        marginRight: "auto"
      },
      paddingLeft: 0,
      paddingRight: 0
    },

    cardPricing: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      marginBottom: theme.spacing.unit * 2
    },
    cardActions: {
      justifyContent: "center",
      [theme.breakpoints.up("sm")]: {
        paddingBottom: theme.spacing.unit * 2
      }
    },

    toolBar: {
      width: "auto",
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
        width: 1200,
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    heroUnit: {
      minHeight: "600px"
    }
  };
};

class ChallengesPages extends React.Component {
  state = {
    value: 0
  };

  constructor(props) {
    super(props);
    this.state = {
      events: {}
      // applied: data && data.applied
    };
  }
  componentDidMount() {
    this.props.getChallenges();
    // this.props.getCurrentProfile();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.challenges !== prevState.challenges) {
      return { challenges: nextProps.challenges };
    } else return null;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.challenges !== this.props.challenges) {
      this.setState({
        challenges: this.props.challenges
      });
    }
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };
  render() {
    const { classes } = this.props;
    const { challenges } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <Authentication />
        <main>
          <div className={classes.heroUnit}>
            <div className={classes.heroContent}>
              <div className={classes.challenges}>
                <div className={classes.logedinuser}>
                  <div className={classes.banner}>
                    <Typography
                      component="h1"
                      variant="h4"
                      align="center"
                      color="textPrimary"
                      gutterBottom
                      style={{ marginTop: "50px", marginBottom: "100px" }}
                    >
                      Challenges
                    </Typography>
                  </div>

                  <div className={classes.mainchallenges}>
                    <Grid container justify={"center"}>
                      <Grid container />

                      <div
                        className={classNames(classes.layout, classes.cardGrid)}
                      >
                        <Grid container spacing={2}>
                          <Grid container spacing={2}>
                            {challenges &&
                              challenges.map((element, id) => {
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    key={id}
                                  >
                                    <CardView data={element} />
                                  </Grid>
                                );
                              })}
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
ChallengesPages.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    challenges: state.events.challenges,
    auth: state.auth,
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  { getChallenges, getCurrentProfile, logoutUser, clearCurrentProfile }
)(withStyles(styles)(ChallengesPages));
