import React from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { FileText } from "react-feather";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";
import moment from "moment";

const styles = {
  name: {
    display: "flex",
    flexDirection: "row",
    fontWeight: "501",
    margin: "1px 0 10px 0px",
    fontSize: "13px"
  },
  addeddate: {
    margin: "0px 5px",
    fontWeight: "normal"
  },
  nodedescription: {
    padding: "3px 18px 0px 17px",
    border: "1px solid gainsboro",
    backgroundColor: "#fff",
    margin: "4px 45px 14px 45px",
    fontSize: "12px",
    borderRadius: "5px"
  },
  addicon: {
    backgroundColor: "#f6a80b",
    color: "#fff",
    borderRadius: "50%",
    padding: "0px 4px",
    margin: "0px 10px"
  },
  changeicon: {
    backgroundColor: "#008aff",
    color: "#fff",
    borderRadius: "50%",
    padding: "0px 4px",
    margin: "0px 10px"
  },
  receiveicon: {
    backgroundColor: "grey",
    color: "grey",
    borderRadius: "50%",
    padding: "0px 4px",
    margin: "3px 15px",
    fontSize: "15px"
  }
};

function NoteCard(props) {
  const { classes, note } = props;
  return (
    <React.Fragment>
      <div className={classes.notesection}>
        {note.comment ? (
          <p className={classes.name}>
            <FileText className={classes.addicon} />
            <span style={{ color: "grey" }}>
              {spacecapitalize(note.title)} by{" "}
            </span>
            &nbsp;
            {capitalize(note.createdBy ? note.createdBy.first_name : "")}{" "}
            {capitalize(note.createdBy ? note.createdBy.last_name : "")}
            <span className={classes.addeddate}>
              {moment(note.createdAt ? note.createdAt : "").format("lll")}
            </span>
          </p>
        ) : (
          <p className={classes.name}>
            <FileText className={classes.addicon} />
            {note.createdBy.first_name} {note.createdBy.last_name}
            <span className={classes.addeddate}>
              {moment(note.createdAt).format("lll")}
            </span>
          </p>
        )}
      </div>

      <div className={classes.nodedescription}>
        <Typography variant="subtitle2" gutterBottom>
          {note.comment ? note.comment : note.title}
        </Typography>
      </div>
    </React.Fragment>
  );
}

export default connect()(withStyles(styles)(NoteCard));
