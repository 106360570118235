const Theme = {
  typography: {
    useNextVariants: true,
    fontFamily: ['"Lato"', "sans-serif"].join(",")
  },
  palette: {
    primary: {
      main: "#211B4E"
    },
    secondary: {
      main: "#2ecc71"
    },
    error: {
      main: "#E52B20"
    },
    table: {
      header: {
        main: "#D2FFFF"
      }
    },
    text: {
      primary: "#110F24",
      disabled: "#CCCED0"
    },
    applications: {
      applied: "#F6F6F9",
      shortlisted: "#8854d0",
      rejected: "#f53b57",
      backout: "#485460",
      attended: "#ffa801",
      winners: "#05c46b",
      white: "#ffffff"
    },
    border: {
      color: "#F8FAFB"
    },
    font: {
      head1: "#110F24",
      head2: "#110F24"
    },
    background: {
      light: "#F5FAFD"
    }
  },
  shadows: Array(25).fill("0"),
  shape: {
    borderRadius: 4
  }
};

export default Theme;
