import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { updateApplicationStatus } from "../../Actions/applicationActions";
const styles = () => ({
  inputField: {
    resize: "none"
  },
  submitButton: {
    backgroundColor: "#4D7CFE",
    "&:hover": {
      backgroundColor: "#3565E9"
    },
    "&:disabled": {
      backgroundColor: "lightgrey"
    },
    color: "white"
  },
  cancelButton: {
    backgroundColor: "#F1F1F1",
    color: "#4D7CFE",
    "&:hover": {
      backgroundColor: "#E2E2E2"
    }
  }
});

const ApplicationStatusPopup = props => {
  const { classes, selected, id } = props;
  const [comments, setComments] = useState("");
  const handleSubmit = () => {
    props.updateApplicationStatus(id, {
      status: selected,
      comment: comments
    });
    props.handleClose();
  };
  const onChangeDescription = e => {
    setComments(e.target.value);
  };
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        className={classes.modal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Change Status</DialogTitle>
        <DialogContent>
          <textarea
            rows="5"
            cols="50"
            type="text"
            placeholder="Enter your comments"
            value={comments}
            onChange={onChangeDescription}
            name="description"
            className={classes.inputField}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleClose}
            color="primary"
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={!comments}
            className={classes.submitButton}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    errors: state.errors
  };
};
export default connect(
  mapStateToProps,
  { updateApplicationStatus }
)(withStyles(styles)(ApplicationStatusPopup));
