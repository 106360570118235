import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid, Button } from "@material-ui/core";
import RightDrawer from "../../Components/Common/RightDrawer";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import InputField from "../../Components/Common/Form/InputField";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import CustomButton from "../../Components/Common/Button";
import { addCompanyContact } from "../../Actions/companiesAction";
const styles = () => ({
  AddContact: {
    color: "white",
    padding: "7px 16px",
    borderRadius: "5px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important"
    }
  },
  submitButton: {
    marginRight: "20px"
  },
  descriptionLabel: {
    fontSize: "13px",
    marginBottom: "-8px"
  },
  cancelButton: {
    width: "146.93px",
    height: "46px",
    backgroundColor: "#F1F1F1",
    color: "#4D7CFE",
    "&:hover": {
      backgroundColor: "#E2E2E2"
    }
  }
});

const ContactSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "please enter more than 2 character")
    .max(15, "")
    .required("Field Required"),
  last_name: Yup.string()
    .min(2, "please enter more than 2 character")
    .max(15, "")
    .required("Field Required"),
  email: Yup.string()
    .email("Invalid email Id")
    .required("Field Required"),
  designation: Yup.string().required("Field Required"),
  contact_number: Yup.number()
    .required("Field Required")
    .positive("A phone number can't start with a minus")
    .min(1111111111, "Please enter 10 digit number")
    .max(9999999999, "Please enter 10 digit number")
});

class AddContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dense: false,
      secondary: false,
      openModal: false,
      search: "",
      isSearch: false
    };
  }

  toggleDrawer = event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ openModal: !this.state.openModal });
  };
  handleClose = () => {
    this.setState({ openModal: false });
  };
  addContact = values => {
    this.props.addCompanyContact(values);
    this.setState({ openModal: false });
  };

  render() {
    const { classes } = this.props;
    const { openModal } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.mainOverview}>
          <div className={classes.aboutCompany}>
            <Button onClick={this.toggleDrawer} className={classes.AddContact}>
              Add Contact
            </Button>
            <RightDrawer openModal={openModal} toggleDrawer={this.toggleDrawer}>
              <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  email: "",
                  contact_number: "",
                  designation: ""
                }}
                enableReinitialize={true}
                validationSchema={ContactSchema}
                onSubmit={values => {
                  values.company_id = this.props.id;
                  this.addContact(values);
                }}
              >
                {() => (
                  <Form>
                    <Grid container spacing={2}>
                      <ScreenHeader type="Add Contact" />
                      <Grid item xs={6} sm={6}>
                        <InputField
                          name="first_name"
                          label="First Name"
                          placeholder="Enter First Name"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <InputField
                          name="last_name"
                          label="Last Name"
                          placeholder="Enter Last Name"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <InputField
                          name="email"
                          label="Email ID"
                          placeholder="johndoe@mail.com"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <InputField
                          name="contact_number"
                          label="Contact No"
                          placeholder="Enter Contact No"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <InputField
                          name="designation"
                          label="Designation"
                          placeholder="Enter Designation"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      sm={12}
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                      className={classes.formFooter}
                    >
                      <Button
                        onClick={this.handleClose}
                        className={classes.cancelButton}
                      >
                        Cancel
                      </Button>
                      <CustomButton
                        type="submit"
                        label="Submit"
                        className={classes.submitButton}
                      ></CustomButton>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </RightDrawer>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
AddContact.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    loading: state.company.loading
  };
};

export default connect(
  mapStateToProps,
  { addCompanyContact }
)(withStyles(styles)(AddContact));
