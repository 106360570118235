import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import nodata from "../../Assets/nodata.png";

const styles = () => {
  return {
    image: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "center"
    },
    maintext: {
      color: "lightgrey"
    },
    headerSpacing: {
      marginTop: "10%",
      marginBottom: "3%"
    }
  };
};

const NoDataFound = props => {
  const { classes } = props;
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.headerSpacing}
    >
      <Grid item justify="center">
        <Grid itme sm={12} lg={12}>
          <img src={nodata} alt="" width="76%" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(NoDataFound);
