import axios from "axios";
import {
  GET_ALL_CHALLENGES,
  CHALLENGES_LOADING,
  CHALLENGES_ERRORS,
  GET_SINGLE_CHALLENGE,
  APPLY_TO_CHALLENGE,
  GET_MY_CHALLENGES
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
export const getChallenges = () => dispatch => {
  dispatch(setChallengesLoading());

  axios
    .get(`/api/listevent`)
    .then(res => {
      dispatch({
        type: GET_ALL_CHALLENGES,
        payload: res.data
      });
    })
    .catch(() =>
      dispatch({
        type: CHALLENGES_ERRORS,
        payload: {}
      })
    );
};
export const getChallenge = id => dispatch => {
  dispatch(setChallengesLoading());
  axios
    .get(`/api/geteventdetails/${id}`)
    .then(res => {
      dispatch({
        type: GET_SINGLE_CHALLENGE,
        payload: res.data
      });
    })
    .catch(() =>
      dispatch({
        type: CHALLENGES_ERRORS,
        payload: {}
      })
    );
};
export const applyToChallenge = (userData, history) => dispatch => {
  axios
    .post("/api/application/apply", userData)
    .then(result => {
      dispatch({
        type: APPLY_TO_CHALLENGE,
        payload: result.data
      });
      dispatch(showSuccess("", "You successfully applied to a challenge"));
      history.push({
        pathname: "/challenges"
      });
      history.push("/challenges");
    })
    .catch(() => {
      dispatch(showError("There was some error while applying to a challenge"));
    });
};

// AUTHORIZE FOR CANDIDATE ONLY
export const getMychallenges = () => dispatch => {
  dispatch(setChallengesLoading());

  axios
    .get(`/api/application/mychallenges`)
    .then(res => {
      dispatch({
        type: GET_MY_CHALLENGES,
        payload: res.data
      });
    })
    .catch(() =>
      dispatch({
        type: CHALLENGES_ERRORS,
        payload: {}
      })
    );
};

// Profile Loading
export const setChallengesLoading = () => {
  return {
    type: CHALLENGES_LOADING
  };
};
