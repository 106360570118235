import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  DialogContent
} from "@material-ui/core";
import logo from "../../Assets/offerbanner1.png";
import Button from "@material-ui/core/Button";
import { getApplication } from "../../Actions/applicationActions";
import { connect } from "react-redux";
import moment from "moment";
import { addNote, getNotes } from "../../Actions/noteActions";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";
const ASSET_URL = process.env.REACT_APP_ASSET_URL;

const styles = () => ({
  headersection: {
    backgroundColor: "#fff",
    padding: "24px 5px 40px 10px"
  },
  eventtitle: {
    marginBottom: 14,
    color: "#ABABAB",
    fontWeight: "normal",
    fontSize: 12,
    textTransform: "capitalize"
  },
  logo: {
    width: "92%",
    height: "102px",
    paddingLeft: "16%"
  },
  headtitle: {
    color: "#0da0e6",
    textTransform: "capitalize"
  },
  eventIdea: {
    textTransform: "capitalize"
  },
  eventcontent: {
    padding: "20px 0px 11px 1px"
  },
  eventname: {
    fontSize: "14px",
    textTransform: "capitalize",
    color: "#000"
  },
  eventbasicdetail: {
    margin: "50px 0px 1px 0px",
    textAlign: "center"
  },
  addNoteButton: {
    borderRadius: "50px"
  }
});
const NoteSchema = Yup.object().shape({
  title: Yup.string().required("field is required")
});
class HeaderApplication extends React.Component {
  state = {
    value: 0,
    selectedIndex: 2,
    modalOpen: false
  };

  handleChange = (createdApplication, value) => {
    this.setState({ value });
  };

  handleClickOpen = () => {
    this.setState({
      modalOpen: true
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false
    });
    this.props.getNotes(this.props.data._id);
  };

  render() {
    const { classes, data } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <Dialog
            open={this.state.modalOpen}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Formik
              initialValues={{
                title: "",
                applicationid: this.props.data._id
              }}
              validationSchema={NoteSchema}
              onSubmit={values => {
                this.props.addNote(values);
                this.props.getNotes(this.props.data._id);
                this.handleClose();
              }}
            >
              <Form>
                <DialogTitle id="alert-dialog-title">
                  {"Add a Note"}
                </DialogTitle>
                <DialogContent>
                  <Field
                    name="title"
                    label="Title"
                    margin="normal"
                    component="textarea"
                    style={{ width: "300px" }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button color="primary" type="Submit" variant="contained">
                    Add Note
                  </Button>
                  <Button
                    onClick={this.handleClose}
                    type="cancel"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            </Formik>
          </Dialog>
          <div className={classes.applicationpage}>
            <div className={classes.headersection}>
              <Grid container spacing={16}>
                <Grid item xs={3} lg={3}>
                  <img
                    className={classes.logo}
                    src={data.logo ? `${ASSET_URL}${data.logo}` : logo}
                    alt=""
                  />
                </Grid>

                <Grid item xs={5} lg={5}>
                  <div className={classes.eventcontent}>
                    <Typography variant="h6" className={classes.headtitle}>
                      {data.team_name}
                    </Typography>
                    <Typography className={classes.eventIdea}>
                      {data.theme}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={2} lg={2} />
                <Grid item xs={2} lg={2} style={{ padding: "20px" }}>
                  <Button
                    variant="outlined"
                    size="small"
                    fullWidth
                    onClick={this.handleClickOpen}
                    className={classes.addNoteButton}
                  >
                    {spacecapitalize(data.status)}
                  </Button>
                </Grid>
              </Grid>
              <div className={classes.eventbasicdetail}>
                <Grid container spacing={16}>
                  <Grid item xs={3} lg={3}>
                    <Typography variant="h1" className={classes.eventtitle}>
                      Event Name
                    </Typography>
                    <Typography className={classes.eventname}>
                      {data.event ? data.event.event_name : ""}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} lg={2}>
                    <Typography variant="h1" className={classes.eventtitle}>
                      Company Name
                    </Typography>
                    <Typography className={classes.eventname}>
                      {capitalize(
                        data.event && data.event.company
                          ? data.event.company.company_name
                          : ""
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} lg={2}>
                    <Typography variant="h1" className={classes.eventtitle}>
                      Date
                    </Typography>
                    <Typography className={classes.eventname}>
                      {moment(data.createdAt).format("DD/MM/YYYY")}
                    </Typography>
                  </Grid>

                  <Grid item xs={1} lg={1}>
                    <Typography variant="h1" className={classes.eventtitle}>
                      Team Size
                    </Typography>
                    <Typography className={classes.eventname}>
                      {/* {data.members_count
                        ? JSON.parse(data.members_count) + 1
                        : ""} */}
                      {data.active_members && data.active_members.length}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} lg={4}>
                    <Typography variant="h1" className={classes.eventtitle}>
                      SPOC Details
                    </Typography>
                    <Typography className={classes.eventname}>
                      {data.createdBy
                        ? capitalize(data.createdBy.first_name)
                        : ""}{" "}
                      {data.createdBy
                        ? capitalize(data.createdBy.last_name)
                        : ""}{" "}
                      | {data.createdBy ? data.createdBy.contact_number : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
            {/*end headersection */}
          </div>
        </main>
      </React.Fragment>
    );
  }
}

HeaderApplication.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    application: state.applications.application,
    auth: state.auth
  };
};

export default connect(
  mapStateToProps,
  { getApplication, addNote, getNotes }
)(withStyles(styles)(HeaderApplication));
