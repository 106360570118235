import React from "react";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "./../../Actions/authAction";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
import { ThemeProvider } from "@material-ui/styles";
import LoginImage from "../../Assets/login.jpg";
import { createMuiTheme } from "@material-ui/core/styles";
import { Typography, Paper, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

const styles = theme => {
  return {
    paper: {
      display: "flex",
      flexDirection: "column",
      height: "-webkit-fill-available",
      padding: "19% 22% 0% 22%",
      backgroundColor: "#fff"
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(1)
    },
    login: {
      backgroundImage: `url(${LoginImage})`,
      width: "100%",
      height: "100vh",
      backgroundSize: "cover",
      backgroundPosition: "50%"
    },
    formfooter: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      marginTop: "5%"
    },
    multilineColor: {
      height: "50px",
      padding: "0px 10px",
      marginBottom: "-7px"
    },
    submitbutton: {
      backgroundColor: "#211b4e !important",
      "&:hover": {
        backgroundColor: "#151039 !important"
      },
      textTransform: "initial"
    },
    paragraph: {
      fontSize: "1.25rem",
      fontWeight: 400,
      letterSpacing: "0.3px",
      lineHeight: "2rem",
      fontFamily: "PT Serif, Georgia, serif",
      margin: "0px"
    },
    muted: {
      opacity: "0.4"
    },
    spacetop: {
      marginTop: "1rem"
    }
  };
};
const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        "&$error": {
          // increase the specificity for the pseudo class
          color: "rgba(0,0,0,0.54)"
        }
      }
    },
    MuiInputBase: {
      input: {
        paddingLeft: "10px !important",
        fontSize: "14px"
      }
    }
  }
});
const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  password: Yup.string().required("Required")
});

class LoginPage extends React.Component {
  state = {
    errors: {}
  };

  componentDidMount() {
    let { auth } = this.props;
    if (auth.isAuthenticated) {
      this.redirectLogin(auth.hasRole);
    }
  }

  redirectLogin(role) {
    let appId =
      (this.props.history.location.state &&
        this.props.history.location.state.appID) ||
      null;
    if (appId) {
      this.props.history.push(`/add-application/${appId}`);
    } else {
      if (role === "candidate") {
        this.props.history.push("/challenges");
      } else {
        this.props.history.push("/dashboard");
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.redirectLogin(nextProps.auth.hasRole);
    }
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} sm={6} md={6} component={Paper}>
          <div className={classes.paper}>
            <h5>Welcome back.</h5>
            <p className={classes.paragraph}>Enter your details below.</p>
            <div className={classes.loginContent}>
              <Formik
                initialValues={{
                  email: "",
                  password: ""
                }}
                validationSchema={LoginSchema}
                onSubmit={(values, { props, setErrors, setSubmitting }) => {
                  this.props.loginUser(values, setErrors, setSubmitting);
                }}
              >
                {({ errors }) => (
                  <Form className={classes.form}>
                    <ThemeProvider theme={theme}>
                      <FormikTextField
                        name="email"
                        label="Email ID"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        InputProps={{
                          classes: {
                            input: classes.multilineColor
                          }
                        }}
                        placeholder="johndoe@mail.com"
                      />
                      <FormikTextField
                        name="password"
                        label="Password"
                        type="password"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        InputProps={{
                          classes: {
                            input: classes.multilineColor
                          }
                        }}
                        placeholder="Enter your Password"
                      />
                      <Typography as="h5" style={{ color: "red" }}>
                        {errors.generic}
                      </Typography>
                      <div className={classes.formfooter}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className={classes.submitbutton}
                        >
                          Submit
                        </Button>
                      </div>

                      <div className={classes.spacetop}>
                        <span className={classes.muted}>
                          Dont have an account?
                        </span>
                        <Link to="/register">Sign Up</Link>
                      </div>
                    </ThemeProvider>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Grid>
        <Grid
          container
          sm={6}
          md={6}
          className={classes.image}
          direction="column"
        >
          <div className={classes.login} />
        </Grid>
      </Grid>
    );
  }
}

LoginPage.propType = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(withStyles(styles)(LoginPage));
