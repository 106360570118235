import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { LinearScale } from "@material-ui/icons";
import checkPermission from "../../Utils/CheckPermissions";
import { Grid, Server, Calendar, File, User, UserCheck } from "react-feather";

const styles = theme => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
    color: "white"
  },
  item: {
    paddingTop: 8,
    paddingBottom: 8
  },
  itemCategory: {
    backgroundColor: "#211B4E",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingBottom: 13
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily
  },
  itemActionable: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)"
    }
  },
  itemActiveItem: {
    color: "#575BDE",
    backgroundColor: "orange"
  },
  itemPrimary: {
    fontSize: theme.typography.fontSize,
    "&$dense": {
      fontSize: theme.typography.fontSize
    }
  },
  dense: {},
  divider: {
    marginTop: theme.spacing.unit * 2
  }
});
const COMPANIES = "/company";
const DASHBOARD = "/dashboard";
const EVENTS = "/events";
const APPLICATIONS = "/applications";
const APPLICANTS = "/applicants";
const ASSOCIATES = "/associates";
const PARTNERS = "/partners";
class NavigatorItems extends React.Component {
  state = {
    selectedIndex: this.props.history.location.pathname
  };

  activeRoute(routeName) {
    return this.props.history.location.pathname.indexOf(routeName) > -1
      ? true
      : false;
  }
  render() {
    const { classes } = this.props;

    return (
      <div>
        <ListItem
          button
          onClick={() => {
            this.props.history.push("/dashboard");
          }}
          className={classNames(classes.item, classes.itemActionable)}
          selected={this.activeRoute(DASHBOARD)}
        >
          <ListItemIcon>
            <Grid
              color="rgb(53, 64, 82)"
              size={20}
              style={{ margin: "0px 0px 0px 7px" }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Dashboard"
            classes={{
              primary: classes.itemPrimary,
              textDense: classes.textDense
            }}
          />
        </ListItem>
        {checkPermission("COMPANY_VIEW") && (
          <ListItem
            button
            onClick={() => {
              this.props.history.push("/company");
            }}
            selected={this.activeRoute(COMPANIES)}
          >
            <ListItemIcon>
              <Server
                color="rgb(53, 64, 82)"
                size={20}
                style={{ margin: "0px 0px 0px 7px" }}
              />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
                textDense: classes.textDense
              }}
              primary="Companies"
            />
          </ListItem>
        )}
        <ListItem
          button
          onClick={() => {
            this.props.history.push("/events");
          }}
          selected={this.activeRoute(EVENTS)}
        >
          <ListItemIcon>
            <Calendar
              color="rgb(53, 64, 82)"
              size={20}
              style={{ margin: "0px 0px 0px 7px" }}
            />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
              textDense: classes.textDense
            }}
            primary="Events"
          />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            this.props.history.push("/applications");
          }}
          selected={this.activeRoute(APPLICATIONS)}
        >
          <ListItemIcon>
            <File
              color="rgb(53, 64, 82)"
              size={20}
              style={{ margin: "0px 0px 0px 7px" }}
            />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
              textDense: classes.textDense
            }}
            primary="Applications"
          />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            this.props.history.push("/applicants");
          }}
          selected={this.activeRoute(APPLICANTS)}
        >
          <ListItemIcon>
            <User
              color="rgb(53, 64, 82)"
              size={20}
              style={{ margin: "0px 0px 0px 7px" }}
            />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
              textDense: classes.textDense
            }}
            primary="Applicants"
          />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            this.props.history.push("/partners");
          }}
          selected={this.activeRoute(PARTNERS)}
        >
          <ListItemIcon>
            <LinearScale
              color="rgb(53, 64, 82)"
              size={20}
              style={{ margin: "0px 0px 0px 7px" }}
            />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
              textDense: classes.textDense
            }}
            primary="Partners"
          />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            this.props.history.push("/associates");
          }}
          selected={this.activeRoute(ASSOCIATES)}
        >
          <ListItemIcon>
            <UserCheck
              color="rgb(53, 64, 82)"
              size={20}
              style={{ margin: "0px 0px 0px 9px" }}
            />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
              textDense: classes.textDense
            }}
            primary="Associates"
          />
        </ListItem>
      </div>
    );
  }
}

export default withStyles(styles)(NavigatorItems);
