import { GET_NOTES, ADD_NOTE, NOTES_LOADING } from "../Actions/types";

const initialState = {
  notes: [],
  loading: false,
  addnote: {}
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_NOTES:
      return {
        ...state,
        loading: false,
        notes: action.payload
      };
    case ADD_NOTE:
      return {
        ...state,
        addnote: {
          success: true,
          loading: false,
          data: action.payload && action.payload.data
        }
      };
    case NOTES_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
