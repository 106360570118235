const EventStatusColor = statusType => {
  switch (statusType) {
    case "completed":
      return "#FF6F00";
    case "cancelled":
      return "#F21634";
    case "onhold":
      return "#FF6F00";
    case "draft":
      return "#FFC400";
    case "published":
      return "#11A711";
    default:
      break;
  }
};
export default EventStatusColor;
