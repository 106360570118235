import axios from "axios";
import {
  GET_EVENTS,
  EVENTS_LOADING,
  EVENT_ERROR,
  GET_EVENT,
  ADD_EVENT,
  UPDATE_EVENT_STATUS_SUCCESS,
  ADD_EVENT_ERROR,
  ADD_EVENT_LOADING,
  ADD_PARTNER_EVENT,
  ADD_ASSOCIATE_EVENT,
  GET_ALL_EVENTS,
  GET_ALL_EVENTS_ERROR,
  ADD_EVENT_TO_PARTNER,
  EDIT_EVENT
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";

export const getEvents = (id, search, associateId, partnerId) => dispatch => {
  dispatch(setEventsLoading());
  let url = "";
  if (id) {
    url = `/api/event?companyid=${id}`;
  } else if (search) {
    url = `/api/event?q=${search}`;
  } else if (associateId) {
    url = `/api/event?associateid=${associateId}`;
  } else if (partnerId) {
    url = `/api/event?partnerid=${partnerId}`;
  } else {
    url = `/api/event`;
  }
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_EVENTS,
        payload: res.data
      });
    })
    .catch(() =>
      dispatch({
        type: GET_EVENTS,
        payload: {}
      })
    );
};

export const getEvent = id => dispatch => {
  dispatch(setEventsLoading());
  axios
    .get(`/api/event/${id}`)
    .then(res => {
      dispatch({
        type: GET_EVENT,
        payload: res.data
      });
    })
    .catch(() =>
      dispatch({
        type: GET_EVENT,
        payload: {}
      })
    );
};

export const registerEvent = (userData, history) => dispatch => {
  dispatch(addEventLoading());
  axios
    .post("/api/event", userData)
    .then(result => {
      dispatch({
        type: ADD_EVENT,
        payload: result.data
      });
      dispatch(showSuccess("Event"));
      history.push({
        pathname: "/events"
      });
      history.push("/events");
    })
    .catch(err => {
      dispatch({ type: ADD_EVENT_ERROR, payload: err.response.data });
      dispatch(showError("There was some error while creating a event"));
    });
};
// ADD PARTNER TO EVENT
export const AddPartnertoEvent = userData => dispatch => {
  dispatch(addEventLoading());
  axios
    .post("/api/event/addeventpartner", userData)
    .then(result => {
      dispatch({
        type: ADD_PARTNER_EVENT,
        payload: result.data
      });
      dispatch(showSuccess("Partner"));
    })
    .catch(err => {
      dispatch({
        type: ADD_EVENT_ERROR,
        payload: err.response && err.response.data
      });
      dispatch(showError("There was some error while add a partner"));
    });
};

//ADD ASSOCIATE TO EVENT
export const AddAssociatetoEvent = userData => dispatch => {
  dispatch(addEventLoading());
  axios
    .post("/api/event/addeventassociate", userData)
    .then(result => {
      dispatch({
        type: ADD_ASSOCIATE_EVENT,
        payload: result.data
      });
      dispatch(showSuccess("Associate"));
    })
    .catch(err => {
      dispatch({
        type: ADD_EVENT_ERROR,
        payload: err.response && err.response.data
      });
      dispatch(showError("There was some error while add a associate"));
    });
};
//GET ALL EVENTS
export const getAllEvents = () => dispatch => {
  dispatch(setEventsLoading());
  let url = `/api/event`;
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_ALL_EVENTS,
        payload: res.data
      });
    })
    .catch(res =>
      dispatch({
        type: GET_ALL_EVENTS_ERROR,
        payload: res && res.data
      })
    );
};

//EDIT EVENT
export const editEvent = (id, data, history) => dispatch => {
  dispatch(setEventsLoading());
  axios
    .put(`/api/event/update/${id}`, data)
    .then(res => {
      dispatch({
        type: EDIT_EVENT,
        payload: res.data
      });
      history.push("/events");
      dispatch(showSuccess("", "Event updated successfully"));
    })

    .catch(() =>
      dispatch({
        type: EVENT_ERROR,
        payload: {}
      })
    );
};

//EVENT STATUS UPDATE
export const updateEventStatus = (id, userData) => dispatch => {
  dispatch(setEventsLoading());
  axios
    .put(`/api/event/update/eventstatus/${id}`, userData)
    .then(result => {
      dispatch({
        type: UPDATE_EVENT_STATUS_SUCCESS,
        payload: result.data
      });
      dispatch(showSuccess("", "Status Updated Successfully"));
    })
    .catch(err => {
      dispatch({
        type: EVENT_ERROR,
        payload: err && err.data
      });
    });
};
//ADD EVENT TO A PARTNER
export const addEventToPartner = userData => dispatch => {
  dispatch(addEventLoading());
  axios
    .post("/api/event/addeventpartner", userData)
    .then(result => {
      dispatch({
        type: ADD_EVENT_TO_PARTNER,
        payload: result.data
      });
      dispatch(showSuccess("Event"));
    })
    .catch(err => {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response && err.response.data
      });
      dispatch(
        showError("There was some error while adding an event to a partner")
      );
    });
};
// Profile Loading
export const setEventsLoading = () => {
  return {
    type: EVENTS_LOADING
  };
};
export const addEventLoading = () => {
  return {
    type: ADD_EVENT_LOADING
  };
};
