import React, { useState, useEffect } from "react";
import * as Showdown from "showdown";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
// var xssFilter = require("showdown-xss-filter");
import xssFilter from "showdown-xss-filter";

const styles = () => ({
  aboutCompanyCont: {
    fontSize: "14px",
    color: "#626262",
    padding: "20px 0px"
  }
});
function RenderHtml(props) {
  let { data, classes } = props;
  let [aboutCompanyHtml, setAboutCompanyHtml] = useState();
  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
    extensions: [xssFilter]
  });
  useEffect(() => {
    Promise.resolve(converter.makeHtml(data)).then(function(html) {
      setAboutCompanyHtml(html);
    });
  }, [data]);

  return (
    <div
      className={classes.aboutCompanyCont}
      dangerouslySetInnerHTML={{ __html: aboutCompanyHtml }}
    />
  );
}
export default connect()(withStyles(styles)(RenderHtml));
