import axios from "axios";
import { GET_ERRORS, GET_NOTES, ADD_NOTE, NOTES_LOADING } from "./types";
import { showSuccess, showError } from "./ActionsHelper";

export const addNote = noteData => dispatch => {
  dispatch(addNoteLoading());
  axios
    .post("/api/application/history", noteData)
    .then(res => {
      dispatch({
        type: ADD_NOTE,
        payload: res.data
      });
      dispatch(showSuccess("Note"));
    })
    .catch(err => {
      dispatch(showError("There was some error while adding a note"));
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};

export const getNotes = id => dispatch => {
  dispatch(addNoteLoading());
  axios
    .get(`/api/application/${id}`)
    .then(res => {
      dispatch({
        type: GET_NOTES,
        payload: res.data.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: { errors: err.data }
      })
    );
};

export const addNoteLoading = () => {
  return {
    type: NOTES_LOADING
  };
};
