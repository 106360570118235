import axios from "axios";
import {
  GET_COMPANIES,
  GET_ASSOCIATED_COMPANIES,
  COMPANY_LOADING,
  COMPANY_ERROR,
  GET_COMPANIES_LIST,
  GET_COMPANY,
  GET_COMPANY_USER,
  GET_COMPANY_CONTACT,
  ADD_COMPANY,
  ADD_COMPANY_LOADING,
  ADD_COMPANY_ERROR,
  GET_ALL_COMPANIES,
  GET_ALL_COMPANIES_ERROR,
  ADD_ASSOCIATE_TO_COMPANY,
  ADD_ASSOCIATE_TO_COMPANY_ERROR,
  GET_ASSOCIATED_PARTNERS,
  ADD_COMPANY_TO_PARTNER,
  ADD_COMPANY_TO_PARTNER_ERROR,
  ADD_COMPANY_CONTACT
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
// import { transformErrorsFromApi } from "./TransformError";

export const getCompanies = (search, associateId, partnerId) => dispatch => {
  dispatch(setCompaniesLoading());
  let url = "";
  if (search) {
    url = `/api/company?q=${search}`;
  } else if (associateId) {
    url = `/api/company?associateid=${associateId}`;
  } else if (partnerId) {
    url = `/api/company?partnerid=${partnerId}`;
  } else {
    url = "/api/company";
  }
  axios
    .get(url)
    .then(res => {
      if (associateId) {
        dispatch({
          type: GET_ASSOCIATED_COMPANIES,
          payload: res.data
        });
      } else if (partnerId) {
        dispatch({
          type: GET_ASSOCIATED_PARTNERS,
          payload: res.data
        });
      } else {
        dispatch({
          type: GET_COMPANIES,
          payload: res.data
        });
      }
    })
    .catch(err =>
      dispatch({
        type: COMPANY_ERROR,
        payload: err.response && err.response.data
      })
    );
};
// company page

export const getCompany = id => dispatch => {
  dispatch(setCompanyLoading());
  axios
    .get(`/api/company/${id}`)
    .then(res => {
      // console.log("Res: ", res);
      dispatch({
        type: GET_COMPANY,
        payload: res.data
      });
    })
    .catch(() =>
      dispatch({
        type: GET_COMPANY,
        payload: {}
      })
    );
};

export const getCurrentCompany = id => dispatch => {
  dispatch(setCompaniesLoading());
  axios
    .get(`/api/company/getcompany/${id}`)
    .then(res => {
      // console.log("Res: ", res.data);
      return dispatch({
        type: GET_COMPANY_USER,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: COMPANY_ERROR,
        payload: err.response.data
      })
    );
};

export const getCompanyList = () => dispatch => {
  axios
    .get("/api/company/companylist")
    .then(result => {
      // console.log("Response 1: ", result);
      dispatch({
        type: GET_COMPANIES_LIST,
        payload: result.data
      });
    })
    .catch(err =>
      dispatch({
        type: COMPANY_ERROR,
        payload: err.response.data
      })
    );
};
export const addCompanyToPartner = (userData, companyid) => dispatch => {
  dispatch(addCompanyLoading());
  axios
    .post("/api/company/addpartner", userData)
    .then(result => {
      dispatch({
        type: ADD_COMPANY_TO_PARTNER,
        payload: result.data
      });
      if (companyid) dispatch(showSuccess("Partner"));
      else dispatch(showSuccess("Company"));
    })
    .catch(err => {
      dispatch(showError("There was some error while associating a company"));
      dispatch({
        type: ADD_COMPANY_TO_PARTNER_ERROR,
        payload: err.response.data
      });
    });
};
export const addCompany = (userData, history) => dispatch => {
  dispatch(addCompanyLoading());
  axios
    .post("/api/company", userData)
    .then(result => {
      dispatch({
        type: ADD_COMPANY,
        payload: result.data
      });
      dispatch(showSuccess("Company"));
      history.push("/company");
    })
    .catch(err => {
      dispatch(showError("There was some error while creating company"));
      dispatch({ type: ADD_COMPANY_ERROR, payload: err.response.data });
    });
};

export const updateCompany = (id, userData, history) => dispatch => {
  // console.log("UserData: ", userData);
  axios
    .put(`/api/company/update/${id}`, userData)
    .then(() => {
      // console.log("Result: ", result);
      dispatch(showSuccess("", "Company Updated Successfully"));
      history.push("/company");
    })
    .catch(err => {
      // console.log("Error: ", err);
      dispatch(showError("There was some error while updating company"));
      dispatch({ type: COMPANY_ERROR, payload: err.response.data });
    });
};

// GET COMPANY CONTACT
export const getCompanyContact = (id, companyid, search) => dispatch => {
  dispatch(setCompaniesLoading());
  let url = "";
  if (id) {
    url = `/api/company/listcontact/${id}`;
  } else if (search) {
    url = `/api/company/listcontact/${companyid}?q=${search}`;
  } else {
    url = `/api/company/listcontact/${companyid}`;
  }

  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_COMPANY_CONTACT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: COMPANY_ERROR,
        payload: err.response && err.response.data
      })
    );
};

// ADD COMPANY CONTACT
export const addCompanyContact = userData => dispatch => {
  axios
    .post("/api/company/addcontact", userData)
    .then(res => {
      dispatch({
        type: ADD_COMPANY_CONTACT,
        payload: res.data
      });
      dispatch(showSuccess("Contact"));
    })
    .catch(err => {
      dispatch(showError("There was some error while creating contact  "));
      dispatch({
        type: COMPANY_ERROR,
        payload: err.response && err.response.data
      });
    });
};
//GET ALL COMPANIES
export const getAllCompanies = () => dispatch => {
  dispatch(setCompaniesLoading());
  let url = "/api/company";
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_ALL_COMPANIES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ALL_COMPANIES_ERROR,
        payload: err.response && err.response.data
      })
    );
};
//ADD ASSOCIATE TO COMPANY
export const AddAssociatetoCompany = userData => dispatch => {
  dispatch(setCompaniesLoading());
  axios
    .post("/api/company/addassociate", userData)
    .then(result => {
      dispatch({
        type: ADD_ASSOCIATE_TO_COMPANY,
        payload: result.data
      });
      dispatch(showSuccess("Associate"));
    })
    .catch(err => {
      dispatch({
        type: ADD_ASSOCIATE_TO_COMPANY_ERROR,
        payload: err.response && err.response.data
      });
      dispatch(showError("There was some error while adding an associate"));
    });
};
// Profile Loading
export const setCompaniesLoading = () => {
  return {
    type: COMPANY_LOADING
  };
};

export const setCompanyLoading = () => {
  return {
    type: COMPANY_LOADING
  };
};

export const addCompanyLoading = () => {
  return {
    type: ADD_COMPANY_LOADING
  };
};
