import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

const TextFieldGroup = ({
  name,
  value,
  label,
  errors,
  info,
  type,
  onChange,
  disabled,
  id,
  ...rest
}) => {
  
  return (
    <FormControl required fullWidth  noValidate autoComplete="off">
      <TextField
       
        id={id}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        margin="normal"
        variant="outlined"
        type={type}
        autoComplete="off"
        helperText={info}
        error={errors[name] ? true : false}
        {...rest}
      />
      {errors[name] && (
        <FormHelperText variant="outlined" error={true}>
          {errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

TextFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  info: PropTypes.string
};

export default TextFieldGroup;
