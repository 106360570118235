import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { connect } from "react-redux";
import classNames from "classnames";
import { Grid, Button } from "@material-ui/core";
import RenderHtml from "../../Components/Common/RenderHtml";
import { spacecapitalize } from "../../Utils/StringFunctions";

const $quoteSym = " ";

const styles = theme => ({
  eventtitle: {
    marginBottom: 14,
    fontSize: 18
  },
  title: {
    marginBottom: 5,
    fontSize: "16px"
  },
  prizetitle: {
    fontSize: 15,
    margin: "2px 9px"
  },
  prize: {
    fontSize: 15,
    margin: "2px 9px"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: "#ca8301"
  },
  mainOverview: {
    padding: "20px 40px"
  },
  subHeader: {
    color: "#000",
    // fontWeight: 600,
    fontSize: "16px",
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "24px",
      left: "0px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute"
    }
  },
  aboutEventCont: {
    fontSize: "14px",
    color: "#626262",
    padding: "20px 0px"
  },
  memberInfo: {
    fontWeight: "normal",
    fontSize: 14,
    color: "#626262",
    padding: "20px 0px"
  },
  themeButton: {
    margin: "20px 10px 0px 0px",
    borderRadius: "20px",
    backgroundColor: "#efefef",
    color: "#626262",
    cursor: "default",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#efefef",
      color: "#626262"
    }
  },
  eventHeader: {
    textTransform: "capitalize"
  }
});

class OverviewEvent extends Component {
  render() {
    const { classes, data } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.mainOverview}>
          <div className={classes.aboutEvent}>
            <span
              className={classNames(classes.subHeader, classes.eventHeader)}
            >
              Description
            </span>
            <RenderHtml data={data ? data.profile_details : ""} />
          </div>

          <div className={classes.themeSection}>
            <span type="About TalentRupt" className={classes.subHeader}>
              Domain & Technology Expertise
            </span>
            <Grid item>
              {data.skills
                ? data.skills.map(el => {
                    return (
                      <Button
                        key={el}
                        variant="contained"
                        color="primary"
                        className={classes.themeButton}
                      >
                        {el ? spacecapitalize(el) : ""}
                      </Button>
                    );
                  })
                : ""}
            </Grid>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

OverviewEvent.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(OverviewEvent));
