import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const styles = () => {
  return {
    bodystyle: {
      color: "white",
      padding: "7px 16px",
      borderRadius: "5px",
      textDecoration: "none",
      fontSize: "0.875rem",
      fontWeight: "500",
      boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
      backgroundColor: "#211b4e !important",
      "&:hover": {
        backgroundColor: "#151039 !important"
      }
    }
  };
};

class AddFormButton extends Component {
  render() {
    const { url, type, params } = this.props;
    const { classes } = this.props;

    return (
      <div>
        <Link
          to={{
            pathname: url,
            params
          }}
          variant="contained"
          className={classes.bodystyle}
          component="button"
        >
          Add&nbsp;{type}
        </Link>
      </div>
      /* )} */
    );
  }
}

export default withStyles(styles)(AddFormButton);
