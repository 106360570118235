import React from "react";
import PropTypes from "prop-types";
import { FormikCheckboxGroupField } from "formik-material-fields";

const CheckboxField = ({ name, label, options, ...rest }) => {
  return (
    <FormikCheckboxGroupField
      name={name}
      label={label}
      margin="normal"
      options={options}
      row
      fullWidth
      multiple
      {...rest}
    />
  );
};

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired
};

export default CheckboxField;
