import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import SelectField from "../Common/Form/SelectField";
import { getCompanies } from "./../../Actions/companiesAction";
import { connect } from "react-redux";
import { capitalize } from "../../Utils/StringFunctions";

const styles = {};

const ClientList = props => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    mandatoryField
  } = props;
  const [companies, setCompanies] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  const getAllClients = companies => {
    return companies
      ? companies.map(company => {
          return {
            value: company._id,
            label: capitalize(company.company_name)
          };
        })
      : [];
  };

  useEffect(() => {
    props.getCompanies();
  }, []);

  useEffect(() => {
    setCompanies(props.companies);
  }, [props.companies]);

  useEffect(() => {
    if (values.company) {
      const newselectedValue = [];
      companies &&
        companies.map(company => {
          if (company.company_name === values.company) {
            newselectedValue.push({
              value: company._id,
              label: capitalize(company.company_name)
            });
          }
        });
      setSelectedValue(newselectedValue[0]);
    }
    setCompanies(companies);
  }, [companies]);

  const changeDropDown = (name, value) => {
    setSelectedValue(value);
    setFieldValue(name, value);
  };

  return (
    <SelectField
      value={selectedValue}
      name="company"
      id="company"
      label="Client Name"
      onChange={changeDropDown}
      onBlur={setFieldTouched}
      error={errors.company_id}
      touched={touched.company_id}
      options={getAllClients(companies)}
      multi={false}
      mandatoryField={mandatoryField ? mandatoryField : ""}
    />
  );
};

const mapStateToProps = state => {
  return {
    companies: state.company.companies
  };
};

export default connect(
  mapStateToProps,
  { getCompanies }
)(withStyles(styles)(ClientList));
