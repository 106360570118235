import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { withStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";

const styles = () => {
  return {
    avatar: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%"
    },
    content: {
      padding: "3%"
    },
    topSection: {
      padding: "2%"
    },
    dashboardCard: {
      marginTop: "2%"
    }
  };
};
const SkeletonCard = props => {
  const { classes, dashboard } = props;
  return dashboard ? (
    <Card className={classes.dashboardCard}>
      <span className={classes.avatar}>
        <Skeleton variant="circle" width={50} height={50} />
      </span>
      <Skeleton width="100%" />
      <Skeleton width="100%" />
    </Card>
  ) : (
    <Card className={classes.card}>
      <Skeleton variant="rect" height={400}>
        <Skeleton variant="rect" height={200} className={classes.topSection}>
          <span className={classes.avatar}>
            <Skeleton variant="circle" width={100} height={100} />
          </span>
          <div className={classes.content}>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </div>
        </Skeleton>
        <Skeleton variant="rect" height={200} className={classes.topSection}>
          <div className={classes.content}>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </div>
          <div className={classes.content}>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </div>
        </Skeleton>
      </Skeleton>
    </Card>
  );
};

export default withStyles(styles)(SkeletonCard);
