import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";

const styles = () => {
  return {};
};
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
    padding: "8px 15px 8px 15px !important"
  }
}))(TableCell);

const Header = props => {
  return (
    <StyledTableCell width={props.width}>{props.children}</StyledTableCell>
  );
};

export default withStyles(styles)(Header);
