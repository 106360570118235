import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Table, TableHead, TableRow, TableBody, Grid } from "@material-ui/core";
import Cell from "../../Components/Common/Table/TableCell";
import Header from "../../Components/Common/Table/TableHeader";
import { MapPin } from "react-feather";
import Tooltip from "../../Components/Common/NameToolTip";
import { capitalize } from "../../Utils/StringFunctions";

const moment = require("moment");

const styles = () => ({
  eventName: {
    color: "black"
  },
  table: {
    boxShadow: "0 5px 10px rgba(0,0,0,0.04), 0 5px 5px rgba(0,0,0,0.05)",
    border: "1px solid #efefef",
    borderRadius: "1px",
    minHeight: "340px"
  },
  mapPin: { paddingRight: "2px" },
  image: {
    height: "299px"
  },
  ImageContainer: {
    height: "269px"
  },
  text: {
    color: "grey"
  }
});
const renderHead = () => {
  return (
    <TableHead>
      <Header width="60%">Event Details</Header>
      <Header>Applications</Header>
      <Header>Applicants</Header>
    </TableHead>
  );
};
const renderBody = props => {
  const { classes, data } = props;
  if (data && data.length <= 0) {
    return (
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={classes.ImageContainer}
      >
        <Grid item className={classes.text}>
          No Data Found
        </Grid>
      </Grid>
    );
  }
  return (
    <TableBody>
      {data &&
        data.length > 0 &&
        data.slice(0, 5).map((item, id) => {
          return (
            <React.Fragment key={id}>
              <TableRow>
                <Cell>
                  <span className={classes.eventName}>
                    <Tooltip
                      amount="50"
                      text={capitalize(item.event_name ? item.event_name : "-")}
                    />
                  </span>
                  <br />
                  {moment(item.start_date).format("DD/MM/YYYY")}{" "}
                  <MapPin size={12} className={classes.mapPin} />
                  {item.location ? item.location.cityName : ""}
                </Cell>

                <Cell>{item ? item.application : "-"}</Cell>
                <Cell>{item ? item.applicants : "-"}</Cell>
              </TableRow>
            </React.Fragment>
          );
        })}
    </TableBody>
  );
};
function DashboardEvents(props) {
  const { classes } = props;
  return (
    <React.Fragment>
      <Table className={classes.table}>
        {renderHead()}
        {renderBody(props)}
      </Table>
    </React.Fragment>
  );
}

export default withStyles(styles)(DashboardEvents);
