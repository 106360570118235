import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import FilterButton from "../../Components/Common/FilterButton";
import { withRouter } from "react-router-dom";

const styles = () => {
  return {
    status: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: "20px"
    }
  };
};

class ApplicationStatusFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "all",
      allActive: "active"
    };
  }
  handleClickStatus = status => {
    this.setState(
      {
        status
      },
      () => {
        this.props.onClick(status);
      }
    );
  };
  render() {
    const { classes, count } = this.props;
    const { status } = this.state;
    const checkStausActive = givenStatus => {
      return status === givenStatus ? "active" : "inActive";
    };
    return (
      <div className={classes.status}>
        <FilterButton
          type="All"
          onClick={() => this.handleClickStatus("all")}
          status={checkStausActive("all")}
          count={count && count.all}
        />
        <FilterButton
          type="Registered"
          onClick={() => this.handleClickStatus("registered")}
          status={checkStausActive("registered")}
          count={count && count.registered}
        />
        <FilterButton
          type="On Board"
          onClick={() => this.handleClickStatus("onboarded")}
          status={checkStausActive("onboarded")}
          count={count && count.onboarded}
        />
        <FilterButton
          type="Shortlisted"
          onClick={() => this.handleClickStatus("shortlisted")}
          status={checkStausActive("shortlisted")}
          count={count && count.shortlisted}
        />
        <FilterButton
          type="Rejected"
          onClick={() => this.handleClickStatus("rejected")}
          status={checkStausActive("rejected")}
          count={count && count.rejected}
        />
        <FilterButton
          type="On hold"
          onClick={() => this.handleClickStatus("on_hold")}
          status={checkStausActive("on_hold")}
          count={count && count.on_hold}
        />
        <FilterButton
          type="Revoked"
          onClick={() => this.handleClickStatus("revoked")}
          status={checkStausActive("revoked")}
          count={count && count.revoked}
        />
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(ApplicationStatusFilter));
