import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { getPartners } from "../../Actions/partnerActions";
import PartnerCard from "../../Components/Common/Cards/PartnerCard";
import PartnerDropdown from "../../Components/Common/PartnerDropdown";
import { AddPartnertoEvent } from "../../Actions/eventActions";
import RightDrawer from "../../Components/Common/RightDrawer";
import PartnerForm from "../Partners/PartnerForm";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import SkeletonCard from "../../Components/Common/Cards/SkeletonCard";

const styles = () => ({
  layout: {
    padding: "22px 32px",
    backgroundColor: "#ffffff"
  },
  noPartners: {
    padding: "24px 24px 11px",
    color: "lightgrey"
  },
  addPartnerSection: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "9px"
  },
  addButton: {
    marginTop: "24px",
    backgroundColor: "#ABABAB",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#ababab",
      color: "#fff"
    }
  }
});
class PartnersEvent extends Component {
  constructor(props) {
    super(props);

    this.state = { openModal: false, partner_id: {}, selectPartner: "" };
  }
  componentDidMount() {
    let eventid = this.props.eventData && this.props.eventData._id;
    this.props.getPartners("", "", eventid);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.partners && nextProps.partners !== prevState.partners) {
      const partnerIds = nextProps.partners.map(el => {
        return el && el._id;
      });
      return { partners: nextProps.partners, partnerIds };
    } else return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.partners !== this.props.partners) {
      this.setState({
        partners: this.props.partners
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
    if (prevProps.addPartnertoEvent !== this.props.addPartnertoEvent) {
      this.setState({
        addPartnertoEvent: this.props.addPartnertoEvent
      });
      if (this.props.addPartnertoEvent.success) {
        let eventid = this.props.eventData && this.props.eventData._id;
        this.props.getPartners("", "", eventid);
      }
    }
    if (prevProps.addPartner !== this.props.addPartner) {
      this.setState({
        addPartner: this.props.addPartner
      });
      if (this.props.addPartner.success) {
        this.closePartnerModal(false);
        let eventid = this.props.eventData && this.props.eventData._id;
        this.props.getPartners("", "", eventid);
      }
    }
  }
  toggleDrawer = (flag, event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ openModal: !this.state.openModal });
  };

  OpenPartnerModal = flag => {
    this.setState({
      openModal: flag
    });
    this.toggleDrawer(false);
  };
  closePartnerModal = flag => {
    this.setState({
      openModal: flag
    });
    this.toggleDrawer(true);
  };
  handleSetField = (label, value) => {
    this.setState({ selectPartner: value });
  };
  handleSubmit = () => {
    let { selectPartner } = this.state;
    let { eventData } = this.props;
    let event_id = "";
    const val = selectPartner ? selectPartner.value : "";
    let partner_id: "";
    partner_id = val;
    event_id = eventData._id;
    const values = { partner_id, event_id };
    this.props.AddPartnertoEvent(values, this.props.history);
    this.setState({ selectPartner: null });
  };
  AddPartner = () => {
    const { classes, eventData } = this.props;
    const { openModal, partnerIds } = this.state;

    return (
      <form className={classes.container} noValidate autoComplete="off">
        <Grid
          container
          xs={12}
          lg={12}
          spacing={2}
          className={classes.addPartnerSection}
        >
          <Grid item xs={3} lg={3}>
            <PartnerDropdown
              values={this.state.selectPartner}
              setFieldValue={this.handleSetField}
              name="partner_id"
              onCreateOption={this.props.OpenPartnerModal}
              openModaldata={this.OpenPartnerModal}
              partnerdata={partnerIds}
            />
          </Grid>
          <Grid item xs={1} lg={1}>
            <Button
              variant="contained"
              size="small"
              onClick={this.handleSubmit}
              className={classes.addButton}
              disabled={!this.state.selectPartner}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <RightDrawer
          padding="no"
          openModal={openModal}
          toggleDrawer={this.toggleDrawer}
        >
          <ScreenHeader type="Add Partner" />
          <PartnerForm
            eventid={eventData._id}
            partnername={this.state.partner_id}
            closeModaldata={this.closePartnerModal}
            openModal={this.state.openModal}
          />
        </RightDrawer>
      </form>
    );
  };
  render() {
    const { classes } = this.props;
    const { partners, loading } = this.state;

    if (!loading && partners && partners.length <= 0) {
      return (
        <div style={{ textAlign: "center" }}>
          {this.AddPartner()}
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item align="center" className={classes.noPartners}>
              <span>No Partners found for this event.</span>
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          {this.AddPartner()}
          <Grid container spacing={2}>
            {loading
              ? Array.from(new Array(6)).map(id => {
                  return (
                    <Grid item key={id} xs={12} lg={4} sm={6}>
                      <SkeletonCard />
                    </Grid>
                  );
                })
              : partners &&
                partners.map((element, id) => {
                  return (
                    <Grid item xs={3} sm={4} key={id}>
                      <PartnerCard
                        data={element}
                        history={this.props.history}
                      />
                    </Grid>
                  );
                })}
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

PartnersEvent.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    partners: state.partners.partners,
    loading: state.partners.loading,
    addPartnertoEvent: state.events.addPartnertoEvent,
    addPartner: state.partners.addPartner
  };
};

export default connect(
  mapStateToProps,
  { getPartners, AddPartnertoEvent }
)(withStyles(styles)(PartnersEvent));
