import React from "react";
import { Paper, Tabs, Tab, Typography, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import OverviewEvent from "./OverviewEvent";
import AssociateEvent from "./AssociateEvent";
import ApplicationEvent from "./ApplicationEvent";
import PartnerEvent from "./PartnerEvent";
import ApplicantsEvent from "./ApplicantsEvent";
const styles = () => ({
  root: {
    paddingTop: "18px",
    paddingBottom: "15px"
  },
  noCoworker: {
    padding: "24px 24px 11px",
    color: "lightgrey"
  },
  layout: {
    marginTop: "19px !important",
    padding: "20px 25px"
  },
  paper: {
    backgroundColor: "#fcfdfe"
  }
});
const AntTabs = withStyles({
  indicator: {
    color: "#435bf2",
    backgroundColor: " #4682B4",
    top: "38px",
    display: "none"
  }
})(Tabs);

const AntTab = withStyles(() => ({
  selected: {
    color: " #4D7CFE !important",
    backgroundColor: "#E2EAFF",
    borderRadius: "5px"
  },
  root: {
    minWidth: "60px",
    maxWidth: "100%",
    textTransform: "capitalize",
    minHeight: "0px !important"
  }
}))(props => <Tab disableRipple {...props} />);

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: "0px 14px",
        marginTop: " 14px",
        backgroundColor: "#fff"
      }}
    >
      {props.children}
    </Typography>
  );
}

function EventTabs(props) {
  const { classes } = props;
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }
  //   useEffect(() => {
  //     props.getCoworker(props.id);
  //   }, []);

  //   useEffect(() => {
  //     setCoworkers(props.companyCoworkers);
  //   }, [props.companyCoworkers]);

  return (
    <Paper className={classes.root} style={{ backgroundColor: "#FCFDFE" }}>
      <AntTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <AntTab label="Overview" />
        <AntTab label="Applications" />
        <AntTab label="Applicants" />
        <AntTab label="Partners" />
        <AntTab label="Associates" />
      </AntTabs>
      <TabContainer>
        {value === 0 && <OverviewEvent eventData={props.data} />}
        {value === 1 && <ApplicationEvent eventData={props.data} />}
        {value === 2 && (
          <ApplicantsEvent eventData={props.data} history={props.history} />
        )}
        {value === 3 && <PartnerEvent eventData={props.data} />}
        {value === 4 && <AssociateEvent eventData={props.data} />}
      </TabContainer>
    </Paper>
  );
}

const mapStateToProps = state => {
  return {
    errors: state.errors
    // companyCoworkers: state.coworkers.companyCoworkers
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(EventTabs));
