import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { Search } from "react-feather";

const styles = theme => {
  return {
    appbar: {
      backgroundColor: "#ffffff"
    },
    search: {
      position: "relative",
      padding: "0px",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: "#f1efef",
      "&:hover": {
        backgroundColor: "#f1efef"
      },
      color: "#4e4b4b",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "auto"
      }
    },
    icon: {
      color: "#4D7CFE"
    },
    searchIcon: {
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      top: "-1px",
      left: "15px"
    },
    grow: {
      flexGrow: 1
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
      backgroundColor: "#eff3f6"
    },
    inputInput: {
      backgroundColor: "#fff",
      border: "1px solid #efefef",
      color: "#000",
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: 44,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: 200
      },
      fontSize: "12px"
    }
  };
};
class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = { q: " " };
  }

  render() {
    const { classes } = this.props;
    const { type } = this.props;
    const { q } = this.state;
    this.keyPress = e => {
      if (e.keyCode === 13) {
        this.props.onSearch(q);
      }
    };
    this.handleChange = e => {
      this.setState({ q: e.target.value });
    };
    return (
      <React.Fragment>
        <div className={classes.search}>
          <InputBase
            placeholder={type}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            onKeyDown={this.keyPress}
            onChange={this.handleChange}
          />
          <div className={classes.searchIcon}>
            <Search size={16} className={classes.icon} />
          </div>
        </div>
        <div className={classes.grow} />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SearchBar);
