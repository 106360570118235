import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { connect } from "react-redux";
import NoteCard from "./../../Components/Common/NoteCard";
import { Grid, Button } from "@material-ui/core";
import { Formik } from "formik";
import { Form } from "react-formik-ui";
import TextareaField from "../../Components/Common/Form/TextareaField";
import * as Yup from "yup";
import { addNote, getNotes } from "../../Actions/noteActions";

const styles = {
  name: {
    display: "flex",
    flexDirection: "row",
    fontWeight: "501",
    margin: "1px 0 10px 0px"
  },
  input_field: {
    border: "1px solid #f5f5f5",
    height: "100%",
    position: "relative",
    width: "100%",
    padding: "6px 8px",
    margin: "0px",
    borderBottom: "none",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: "grey",
    fontSize: "14px",
    resize: "none"
  },
  comment_section: {
    border: "1px solid #efefef",
    textAlign: "right",
    position: "relative",
    height: "81px",
    margin: "0px 43px 0px 44px",
    borderRadius: "10px"
  },
  mainHistory: {
    padding: "38px 27px"
  },
  submitbutton: {
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important"
    },
    left: "-5px",
    marginTop: "-77px",
    // marginBottom: "35px",
    "&:disabled": {
      backgroundColor: "lightgrey !important"
    }
  },
  historysection: {
    marginTop: "20px"
  }
};
const AddPostSchema = Yup.object().shape({
  title: Yup.string().required("This field is required.")
});
class History extends Component {
  state = {
    dense: false,
    secondary: false
  };

  componentDidMount() {
    this.props.getNotes(this.props.data._id);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.notes !== prevState.notes) {
      return { notes: nextProps.notes };
    } else return null;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.addnote !== this.props.addnote) {
      this.setState({
        addnote: this.props.addnote
      });
      if (this.props.addnote.success) {
        this.props.getNotes(this.props.data._id);
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { history } = this.state.notes;
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <div className={classes.mainHistory}>
            <div className={classes.historysection}>
              {history &&
                history.map(note => {
                  return <NoteCard note={note} key={note._id} />;
                })}
            </div>
            <Grid
              className={classes.createPostSection}
              container
              item
              lg={12}
              xs={12}
            >
              <Grid item lg={12} xs={12}>
                <Formik
                  initialValues={{
                    title: "",
                    applicationid: this.props.data._id
                  }}
                  enableReinitialize
                  validationSchema={AddPostSchema}
                  onSubmit={(values, { resetForm }) => {
                    this.props.addNote(values);
                    resetForm(this.initialValues);
                  }}
                >
                  {({ values, handleSubmit }) => (
                    <Form
                      onSubmit={handleSubmit}
                      className={classes.form}
                      encType="multipart/form-data"
                      autoComplete="off"
                    >
                      <div className={classes.comment_section}>
                        <TextareaField
                          name="title"
                          className={classes.input_field}
                          placeholder="Add Note"
                        />
                        <Button
                          className={classes.submitbutton}
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={this.onSubmit}
                          disabled={!values.title}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    notes: state.notes.notes,
    addnote: state.notes.addnote
  };
};

export default connect(
  mapStateToProps,
  { getNotes, addNote }
)(withStyles(styles)(History));
