import React from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import {
  addAssociate,
  editAssociate,
  addingAssociateLoader,
  getAssociate
} from "../../Actions/associateActions";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import FormLabel from "@material-ui/core/FormLabel";
import { UploadField, InputField } from "../../Components/Common/Form";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as Yup from "yup";
import CityDropdown from "../../Components/Common/CityDropdown";
import SocialHandle from "../../Components/Common/SocialHandle";
import AssociateSkills from "../../Components/Common/AssociateSkills";
import CustomButton from "../../Components/Common/Button";
import Editor from "../../Components/Common/Form/Editor";

const styles = theme => ({
  layout: {
    width: "auto",
    background: "#fff",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    backgroundColor: props =>
      props.eventid || props.companyid ? "#F9F9F9 " : "#FFFFFF ",
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    boxShadow: "none",
    backgroundColor: props =>
      props.eventid || props.companyid ? "#F9F9F9 " : "#FFFFFF ",
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  descriptionLabel: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)"
  },
  skillsContainer: {
    marginLeft: "0.1%"
  },
  cancelButton: {
    width: "146.93px",
    height: "46px",
    backgroundColor: "#F1F1F1",
    color: "#4D7CFE",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#E2E2E2"
    }
  },
  cancelContainer: {
    display: "flex",
    alignItems: "center"
  }
});

const AssociateSchema = Yup.object().shape({
  first_name: Yup.string().required("Field Required"),
  last_name: Yup.string().required("Field Required"),
  photo: Yup.string().required("Field Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Field Required"),
  contact_number: Yup.number().required("Field Required"),
  location: Yup.string().required("Field Required"),
  skills: Yup.string().required("Field Required"),
  profile_details: Yup.string().required("Field Required"),
  designation: Yup.string().required("Field Required"),
  company_name: Yup.string().required("Field Required")
});

class AssociateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: "",
      path: this.props.match.path,
      id: this.props.match.params.id
    };
  }

  componentDidMount() {
    if (this.state.path === "/associate/edit/:id") {
      this.props.getAssociate(this.state.id);
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.addAssociateData !== prevState.addAssociateData) {
      return { addAssociateData: nextProps.addAssociateData };
    }
    if (nextProps.associate !== prevState.associate) {
      return { associate: nextProps.associate };
    } else return [];
  }

  getInitialValues = id => {
    const associate = this.state.associate ? this.state.associate : "";
    const city = associate.location ? associate.location.cityName : "";
    if (id) {
      return {
        first_name: associate.user ? associate.user.first_name : "",
        last_name: associate.user ? associate.user.last_name : "",
        photo: associate.photo,
        skills:
          associate && associate.skills
            ? associate.skills.map(skill => {
                return { label: skill, value: skill };
              })
            : [],
        email: associate.user ? associate.user.email : "",
        contact_number: associate.user ? associate.user.contact_number : "",
        profile_details: associate.profile_details,
        location: city,
        company_name: associate.company_name,
        designation: associate.designation,
        linkedin: associate.linkedin,
        twitter: associate.twitter,
        facebook: associate.facebook,
        instagram: associate.instagram
      };
    } else {
      return {
        first_name: "",
        last_name: "",
        photo: "",
        skills: [],
        email: "",
        contact_number: "",
        profile_details: "",
        location: "",
        company_name: "",
        designation: "",
        linkedin: "",
        twitter: "",
        facebook: "",
        instagram: ""
      };
    }
  };

  closeAssociateModal = flag => {
    if (this.props.eventid || this.props.companyid) {
      this.props.closeModaldata(flag);
    } else {
      this.props.history.push("/associates");
    }
  };

  handlevalue = data => {
    this.setState({ details: data });
  };
  formatData = values => {
    const formated = { ...values };
    const val = formated.selectedLocationObj
      ? formated.selectedLocationObj.value
      : "";
    formated.location = val;
    const skill = formated.skills && formated.skills.map(el => el && el.label);
    formated.skills = skill;
    if (this.props.eventid) {
      formated.eventid = this.props.eventid;
    }
    if (this.props.companyid) {
      formated.companyid = this.props.companyid;
    }
    return formated;
  };
  render() {
    const { classes } = this.props;
    const { id, addAssociateData, associate, path } = this.state;
    const { loading: addLoading } = addAssociateData;
    return (
      <React.Fragment>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={this.getInitialValues(id)}
              enableReinitialize={true}
              validationSchema={AssociateSchema}
              onSubmit={values => {
                const data = this.formatData(values);
                if (path !== "/associate/edit/:id") {
                  if (this.props.eventid || this.props.companyid)
                    this.props.addAssociate(data);
                  else this.props.addAssociate(data, this.props.history);
                } else if (path === "/associate/edit/:id") {
                  this.props.editAssociate(id, data, this.props.history);
                }
              }}
            >
              {({
                setFieldValue,
                setFieldTouched,
                values,
                errors,
                touched
              }) => (
                <Form
                  className={classes.container}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputField
                        name="first_name"
                        label="First Name"
                        placeholder="Enter First Name"
                        mandatoryField
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputField
                        name="last_name"
                        label="Last Name"
                        placeholder="Enter Last Name"
                        mandatoryField
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputField
                        name="email"
                        label="Email ID"
                        placeholder="johndoe@mail.com"
                        mandatoryField
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputField
                        name="contact_number"
                        label="Contact No"
                        placeholder="91XXXXXXXX"
                        mandatoryField
                        type="tel"
                        max="10"
                      />
                    </Grid>
                    <Grid item lg={12} sm={12} xs={12}>
                      <FormLabel
                        className={classes.descriptionLabel}
                        component="legend"
                      >
                        Profile Details
                      </FormLabel>
                      <Editor
                        getval={data => setFieldValue("profile_details", data)}
                        data={associate ? associate.profile_details : ""}
                        error={errors.profile_details}
                        name="profile_details"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <UploadField
                        label="Upload Photo"
                        name="photo"
                        id="photo"
                        onChange={setFieldValue}
                        value={values.photo}
                        folder="profile/picture"
                        required
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <CityDropdown
                        values={values.location}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        errors={errors}
                        touched={touched}
                        name="location"
                        mandatoryField
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputField
                        name="company_name"
                        label="Current Company"
                        placeholder="Enter Current Company"
                        mandatoryField
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputField
                        name="designation"
                        label="Designation"
                        placeholder="Enter Your Designation"
                        mandatoryField
                      />
                    </Grid>
                    <Grid
                      container
                      sm={12}
                      xs={12}
                      spacing={2}
                      className={classes.skillsContainer}
                    >
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <AssociateSkills
                          values={values.skills}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          touched={touched}
                          name="skills"
                          mandatoryField
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={6} sm={6}>
                      <InputField
                        name="website"
                        label="Website"
                        placeholder="Enter Your Website"
                      />
                    </Grid> */}
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputField
                        paddingfield
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment className={classes.inputSocialIcon}>
                              <SocialHandle
                                type="linkedin"
                                height="25px"
                                width="25px"
                                inputFeildSocial
                              />
                            </InputAdornment>
                          )
                        }}
                        name="linkedin"
                        label="Linkedin"
                        placeholder="Enter Your Linkedin"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputField
                        paddingfield
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment className={classes.inputSocialIcon}>
                              <SocialHandle
                                type="twitter"
                                height="25px"
                                width="25px"
                                inputFeildSocial
                              />
                            </InputAdornment>
                          )
                        }}
                        name="twitter"
                        label="Twitter"
                        placeholder="Enter Your Twitter"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputField
                        paddingfield
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment className={classes.inputSocialIcon}>
                              <SocialHandle
                                type="facebook"
                                height="25px"
                                width="25px"
                                inputFeildSocial
                              />
                            </InputAdornment>
                          )
                        }}
                        name="facebook"
                        label="Facebook"
                        placeholder="Enter Your Facebook"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputField
                        paddingfield
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment className={classes.inputSocialIcon}>
                              <SocialHandle
                                type="instagram"
                                height="25px"
                                width="25px"
                                inputFeildSocial
                              />
                            </InputAdornment>
                          )
                        }}
                        name="instagram"
                        label="Instagram"
                        placeholder="Enter Your Instagram"
                      />
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      spacing={2}
                      direction="row-reverse"
                    >
                      <Grid item>
                        <CustomButton
                          type="submit"
                          label={
                            path === "/associate/edit/:id" ? "update" : "Add"
                          }
                          loader={addLoading}
                        />
                      </Grid>
                      <Grid item className={classes.cancelContainer}>
                        <Button
                          onClick={() => this.closeAssociateModal(false)}
                          className={classes.cancelButton}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    addAssociateData: state.associates.addAssociate,
    auth: state.auth,
    errors: state.errors,
    associate: state.associates.associate
  };
};

export default connect(
  mapStateToProps,
  { addAssociate, editAssociate, addingAssociateLoader, getAssociate }
)(withStyles(styles)(withRouter(AssociateForm)));
