import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
// import { checkPermission } from "Utils/checkPermission";
import classNames from "classnames";

const styles = () => {
  return {
    Button: {
      color: "#4D7CFE",
      backgroundColor: "#109fb2",
      borderColor: "#4D7CFE",
      textTransform: "capitalize",
      borderBottom: "1px solid",
      fontSize: "12px",
      marginRight: "20px",
      boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#4D7CFE"
      }
    },
    active: {
      backgroundColor: "#fff",
      color: "#4D7CFE",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#4D7CFE"
      }
    },
    inActive: {
      color: "grey",
      backgroundColor: "#fff",
      borderColor: "grey",
      borderBottom: "1px solid",
      fontSize: "12px",
      "&:hover": {
        backgroundColor: "#fff",
        color: "grey"
      }
    },
    statusCount: {
      color: "#4D7CFE",
      margin: "0px 7px"
    }
  };
};

class FilterButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { type, classes, status, count } = this.props;
    return (
      <div>
        <Button
          className={classNames(classes[status], classes.Button)}
          variant="contained"
          component="button"
          size="small"
          onClick={() => this.props.onClick()}
        >
          {type} &nbsp;({count})
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(FilterButton);
