import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid, Typography, Avatar } from "@material-ui/core";
import logo from "../../Assets/offerbanner1.png";
import { connect } from "react-redux";
import Text from "../../Components/Common/Text";
import { capitalize } from "../../Utils/StringFunctions";

const styles = () => ({
  headersection: {
    backgroundColor: "#fff",
    padding: "24px 5px 40px 10px"
  },
  eventtitle: {
    marginBottom: 14,
    color: "#ABABAB",
    fontWeight: "normal",
    fontSize: 12
  },
  eventIdea: {
    textTransform: "capitalize"
  },
  eventcontent: {
    padding: "20px 0px 11px 1px"
  },
  eventname: {
    fontSize: "14px",
    color: "#626262"
  },
  eventbasicdetail: {
    margin: "50px 0px 1px 0px",
    textAlign: "center"
  },
  avatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  avatarContainer: {
    marginLeft: "2%"
  }
});

class Header extends React.Component {
  render() {
    const { classes, data } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <div className={classes.applicationpage} key>
            <div className={classes.headersection}>
              <Grid container spacing={1}>
                <Grid item xs={1} lg={1} className={classes.avatarContainer}>
                  <Avatar
                    className={classes.avatar}
                    src={data.logo ? data.logo : logo}
                  />
                </Grid>
                <Grid item xs={5} lg={5}>
                  <div className={classes.eventcontent}>
                    <Text
                      type="header2"
                      color="#4D7CFE"
                      label={`${capitalize(
                        data.user && data.user.first_name
                      )}   ${capitalize(data.user && data.user.last_name)}`}
                      gutterBottom
                    />
                    {/* Designation is Static for Now */}
                    <Text
                      type="header4"
                      color="#626262"
                      label="Startup Evangelist"
                    />
                  </div>
                </Grid>
              </Grid>

              <div className={classes.eventbasicdetail} key>
                <Grid container spacing={16}>
                  <Grid item xs={3} lg={3}>
                    <Typography variant="h1" className={classes.eventtitle}>
                      Contact No
                    </Typography>
                    {data.user && data.user.contact_number ? (
                      <Typography className={classes.eventname}>
                        +91 {data.user.contact_number}
                      </Typography>
                    ) : (
                      "-"
                    )}
                  </Grid>

                  <Grid item xs={3} lg={3}>
                    <Typography variant="h1" className={classes.eventtitle}>
                      Email ID
                    </Typography>
                    <Typography className={classes.eventname}>
                      {data.user && data.user.email}
                    </Typography>
                  </Grid>
                  {/* Company name is static for now */}
                  <Grid item xs={3} lg={3}>
                    <Typography variant="h1" className={classes.eventtitle}>
                      Company Name
                    </Typography>
                    <Typography className={classes.eventname}>DevX</Typography>
                  </Grid>
                  {/* Location is static for now */}
                  <Grid item xs={3} lg={3}>
                    <Typography variant="h1" className={classes.eventtitle}>
                      Location
                    </Typography>
                    <Typography className={classes.eventname}>
                      Ahmedabad
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default connect()(withStyles(styles)(Header));
