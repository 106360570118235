import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { getAssociates } from "../../Actions/associateActions";
import AssociateCard from "../../Components/Common/Cards/AssociateCard";
import SkeletonCard from "../../Components/Common/Cards/SkeletonCard";
import RightDrawer from "../../Components/Common/RightDrawer";
import AssociateDropdown from "../../Components/Common/AssociateDropdown";
import { Form } from "formik";
import { AddAssociatetoEvent } from "../../Actions/eventActions";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import AssociateForm from "../Associates/AssociateForm";

const styles = () => ({
  layout: {
    padding: "22px 32px",
    backgroundColor: "#FFFFFF"
  },
  noassociate: {
    padding: "24px 24px 11px",
    color: "lightgrey"
  },
  addAssociateSection: {
    display: "flex",
    justifyContent: "flex-end"
  },
  addButton: {
    marginTop: "26px"
  }
});
class AssociateEvent extends Component {
  constructor(props) {
    super(props);
    this.state = { openModal: false, associate_id: {}, selectAssociate: "" };
  }
  componentDidMount() {
    let event_id = this.props.eventData && this.props.eventData._id;
    this.props.getAssociates("", "", event_id);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.associates && nextProps.associates !== prevState.associates) {
      const associateId = nextProps.associates.map(el => {
        return el._id;
      });
      return { associates: nextProps.associates, associateId };
    } else return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.associates !== this.props.associates) {
      this.setState({
        associates: this.props.associates
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
    if (prevProps.addAssociatetoEvent !== this.props.addAssociatetoEvent) {
      this.setState({
        addAssociatetoEvent: this.props.addAssociatetoEvent
      });
      if (this.props.addAssociatetoEvent.success) {
        let event_id = this.props.eventData && this.props.eventData._id;
        this.props.getAssociates("", "", event_id);
      }
    }
    if (prevProps.addAssociateData !== this.props.addAssociateData) {
      this.setState({
        addAssociateData: this.props.addAssociateData
      });
      if (this.props.addAssociateData.success) {
        this.closeAssociateModal(false);
        let event_id = this.props.eventData && this.props.eventData._id;
        this.props.getAssociates("", "", event_id);
      }
    }
  }
  closeAssociateModal = flag => {
    this.setState({
      openModal: flag
    });
    this.toggleDrawer(true);
  };
  toggleDrawer = (flag, event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ openModal: !this.state.openModal });
  };

  OpenAssociateModal = flag => {
    this.setState({
      openModal: flag
    });
    this.toggleDrawer(false);
  };
  handleSetField = (label, value) => {
    this.setState({ selectAssociate: value });
  };
  handleSubmit = () => {
    let { selectAssociate } = this.state;
    let { eventData } = this.props;
    let event_id = "";
    const val = selectAssociate ? selectAssociate.value : "";
    let associate_id = "";
    associate_id = val;
    event_id = eventData._id;
    const values = { associate_id, event_id };
    this.props.AddAssociatetoEvent(values, this.props.history);
    this.setState({ selectAssociate: "" });
  };
  AddAssociate = () => {
    const { classes, eventData } = this.props;
    const { openModal, associateId } = this.state;
    return (
      <Form className={classes.container} noValidate autoComplete="off">
        <Grid
          container
          xs={12}
          lg={12}
          spacing={2}
          className={classes.addAssociateSection}
        >
          <Grid item xs={3} lg={3}>
            <AssociateDropdown
              values={this.state.selectAssociate}
              setFieldValue={this.handleSetField}
              name="associate_id"
              onCreateOption={this.props.OpenAssociateModal}
              openModaldata={this.OpenAssociateModal}
              aasociatedata={associateId}
            />
          </Grid>
          <Grid item xs={1} lg={1}>
            <Button
              variant="contained"
              size="small"
              className={classes.addButton}
              onClick={this.handleSubmit}
              disabled={!this.state.selectAssociate}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <RightDrawer
          padding="no"
          openModal={openModal}
          toggleDrawer={this.toggleDrawer}
        >
          <ScreenHeader type="Add Associate" />
          <AssociateForm
            eventid={eventData._id}
            associatename={this.state.associate_id}
            closeModaldata={this.closeAssociateModal}
            openModal={this.state.openModal}
          />
        </RightDrawer>
      </Form>
    );
  };
  render() {
    const { classes } = this.props;
    const { associates, loading } = this.state;
    if (!loading && associates && associates.length <= 0) {
      return (
        <div style={{ textAlign: "center" }}>
          {this.AddAssociate()}
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item align="center" className={classes.noassociate}>
              <span>No Associates found for this event.</span>
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          {this.AddAssociate()}
          <Grid container spacing={2}>
            {loading
              ? Array.from(new Array(6)).map(id => {
                  return (
                    <Grid item key={id} xs={12} lg={4} sm={6}>
                      <SkeletonCard />
                    </Grid>
                  );
                })
              : associates &&
                associates.map((element, id) => {
                  // eventid.push(element._id);
                  return (
                    <Grid item xs={3} sm={4} key={id}>
                      <AssociateCard
                        data={element}
                        history={this.props.history}
                      />
                    </Grid>
                  );
                })}
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

AssociateEvent.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    associates: state.associates.associates,
    loading: state.associates.loading,
    addAssociatetoEvent: state.events.addAssociatetoEvent,
    addAssociateData: state.associates.addAssociate
  };
};

export default connect(
  mapStateToProps,
  { getAssociates, AddAssociatetoEvent }
)(withStyles(styles)(AssociateEvent));
