import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { Typography, AppBar, Toolbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { getCompany } from "../../Actions/companiesAction";
import { connect } from "react-redux";
import bannerImage from "../../Assets/companybanner.png";
import logo from "../../Assets/companylogo.png";
import CompanyTabs from "./CompanyTabs";
import { MapPin } from "react-feather";
import SocialHandle from "../../Components/Common/SocialHandle";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;

const styles = {
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    height: "-webkit-fill-available"
  },
  logo: {
    minWidth: "100px",
    minHeight: "100px"
  },
  orangeAvatar: {
    width: "60%",
    height: 95,
    fontSize: "36px",
    position: "relative",
    top: "-30px",
    left: "10%",
    borderRadius: "4px",
    objectFit: "contain",
    backgroundColor: "#fff"
  },
  eventTitle: {
    margin: "11px 0px 0px -8px",
    paddingBottom: "8px"
  },
  eventbasicdetail: {
    backgroundColor: "#fff",
    position: "relative"
  },
  social: {
    height: "26px",
    width: "26px",
    marginRight: "14px",
    borderRadius: "50px"
  },
  eventmaintitle: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#4d7cfe",
    textTransform: "capitalize",
    lineHeight: 1.3
  },
  socialSection: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "13px 14px 0px"
  },
  subFont: {
    fontSize: "14px",
    paddingLeft: "4px",
    fontWeight: "normal",
    color: "#626262"
  },
  locationIcon: {
    position: "relative",
    top: "3px",
    left: "-4px"
  }
};

export class CompanyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: {}
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.getCompany(id);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.company !== prevState.company) {
      return { company: nextProps.company };
    }
    return {};
  }

  render() {
    const { classes } = this.props;
    const { company } = this.state;
    const Banner = company.banner_image
      ? `${ASSET_URL + company.banner_image}`
      : bannerImage;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.mainsection}>
          <main>
            <div
              style={{
                backgroundImage: `url(${Banner})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "340px"
              }}
            >
              <AppBar position="static" className={classes.appBar}>
                <Toolbar />
              </AppBar>
            </div>
            <div className={classNames(classes.layout, classes.cardGrid)}>
              {/* End hero unit */}
              <div className={classes.eventbasicdetail}>
                <Grid
                  container
                  item
                  lg={12}
                  xs={12}
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  <Grid item lg={2} xs={2}>
                    <div className={classes.logo}>
                      <img
                        className={classes.orangeAvatar}
                        src={
                          company.logo ? `${ASSET_URL}${company.logo}` : logo
                        }
                        alt="logo"
                      />
                    </div>
                  </Grid>
                  <Grid item lg={5} xs={5}>
                    <div className={classes.eventTitle}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classNames(
                          classes.eventmaintitle,
                          classes.fontSize
                        )}
                      >
                        {company && company.company_name}
                      </Typography>
                      <Typography
                        className={classNames(
                          classes.eventsubtitle1,
                          classes.subFont
                        )}
                        variant="subtitle1"
                        component="p"
                        style={{ paddingTop: "3px" }}
                      >
                        <MapPin size={15} className={classes.locationIcon} />
                        {company && company.location
                          ? company.location.cityName
                          : ""}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={5} lg={5} className={classes.socialSection}>
                    <Grid container xs={12} justify="flex-end">
                      <Grid item xs={1}>
                        <SocialHandle
                          type="linkedin"
                          width="25px"
                          height="25px"
                          url={company.linkedin ? company.linkedin : ""}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <SocialHandle
                          type="facebook"
                          width="25px"
                          height="25px"
                          url={company.twitter ? company.twitter : ""}
                        />
                      </Grid>
                      <Grid item>
                        <SocialHandle
                          type="twitter"
                          width="25px"
                          height="25px"
                          url={company.twitter ? company.twitter : ""}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <CompanyTabs
                company={company}
                id={this.props.match.params.id}
                history={this.props.history}
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

CompanyPage.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    company: state.company.company,
    auth: state.auth
  };
};

export default connect(
  mapStateToProps,
  { getCompany }
)(withStyles(styles)(CompanyPage));
