import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import classNames from "classnames";
import { updateApplicationStatus } from "../../Actions/applicationActions";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import { spacecapitalize } from "../../Utils/StringFunctions";
import ApplicationStatusPopup from "../../Screens/Applications/ApplicationStatusPopup";
import getApplicationTypes from "./Types/ApplicationTypes";

const styles = theme => {
  return {
    onboarded: {
      backgroundColor: "#fff",
      color: "#05c46b ",
      border: `1px solid #05c46b`
    },
    registered: {
      backgroundColor: "#fff",
      color: "#05c46b ",
      border: `1px solid #05c46b`
    },
    revoked: {
      backgroundColor: "#fff",
      color: "#485460 ",
      border: `1px solid #485460`
    },
    applied: {
      backgroundColor: "#fff",
      color: "#F6F6F9 ",
      border: `1px solid #F6F6F9`
    },
    shortlisted: {
      backgroundColor: "#fff",
      color: "#8854d0 ",
      border: `1px solid #8854d0`
    },
    rejected: {
      backgroundColor: "#fff",
      color: "#f53b57 ",
      border: `1px solid #f53b57`
    },
    attended: {
      backgroundColor: "#fff",
      color: "#ffa801 ",
      border: `1px solid #ffa801`
    },
    won: {
      backgroundColor: "#fff",
      color: "#05c46b ",
      border: `1px solid #05c46b`
    },
    on_hold: {
      backgroundColor: "#fff",
      color: "#FF6F00 ",
      border: `1px solid #FF6F00`
    },
    participated: {
      backgroundColor: "#fff",
      color: " #4D7CFE ",
      border: `1px solid #4D7CFE`
    },
    disqualified: {
      backgroundColor: "#fff",
      color: "#f53b57",
      border: `1px solid #f53b57`
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 50
    },
    statusDropdown: {
      borderRadius: `${theme.shape.borderRadius * 4}px`,
      height: "25px",
      padding: "12px 11px 13px 13px",
      fontSize: "10px",
      fontWeight: "500"
    },
    wonStatus: {
      color: "#05c46b",
      border: `1px solid #05c46b`,
      borderRadius: `${theme.shape.borderRadius * 4}px`,
      height: "25px",
      padding: "4px 11px 13px 13px",
      fontSize: "10px",
      fontWeight: "500"
    },
    icon: {
      color: "#626262",
      right: "10px",
      top: "1px"
    },
    disqualifiedStatus: {
      color: "#f53b57",
      border: `1px solid #f53b57`,
      borderRadius: `${theme.shape.borderRadius * 4}px`,
      height: "25px",
      padding: "4px 11px 13px 13px",
      fontSize: "10px",
      fontWeight: "500"
    }
  };
};

const StatusSelector = props => {
  const { classes, id, selectedItem } = props;
  const [selected, setSelected] = useState(props.selectedItem || "");
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setSelected(props.selectedItem);
  }, []);

  const handleClickOpen = event => {
    setSelected(event.target.value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <FormControl variant="filled" className={classes.formControl}>
        {selectedItem === "won" || selectedItem === "disqualified" ? (
          <React.Fragment>
            {selectedItem === "won" && (
              <div className={classes.wonStatus}>Won</div>
            )}
            {selectedItem === "disqualified" && (
              <div className={classes.disqualifiedStatus}>Disqualified</div>
            )}
          </React.Fragment>
        ) : (
          <Select
            className={classNames(classes[selected], classes.statusDropdown)}
            value={selected}
            variant="standard"
            onChange={handleClickOpen}
            name="name"
            disableUnderline
            classes={{
              icon: classes.icon
            }}
          >
            {getApplicationTypes(selectedItem) &&
              getApplicationTypes(selectedItem).map(element => {
                return (
                  <MenuItem value={element} key={element}>
                    {spacecapitalize(element)}
                  </MenuItem>
                );
              })}
          </Select>
        )}
        {selected !== selectedItem && (
          <ApplicationStatusPopup
            open={open}
            handleClose={handleClose}
            selected={selected}
            // options={ApplicationTypes}
            id={id}
          />
        )}
      </FormControl>
    </React.Fragment>
  );
};
const mapStateToProps = state => {
  return {
    errors: state.errors
  };
};
export default connect(
  mapStateToProps,
  { updateApplicationStatus }
)(withStyles(styles)(StatusSelector));
