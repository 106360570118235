import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { getApplications } from "../../Actions/applicationActions";
import ApplicationCard from "../../Components/Common/Cards/ApplicationCard";
import SkeletonCard from "../../Components/Common/Cards/SkeletonCard";
import ApplicationStatusFilter from "../Applications/ApplicationStatusFilter";

const styles = () => ({
  layout: {
    padding: "22px 32px",
    backgroundColor: "#ffffff",
    height: "604px"
  },
  noCoworker: {
    padding: "24px 24px 11px",
    color: "lightgrey",
    height: "401px",
    margin: "0 auto"
  },
  noDataSection: {
    height: "603px",
    padding: "22px 32px"
  }
});

class ApplicationEvent extends Component {
  constructor(props) {
    super(props);
    this.state = { applications: [], count: [] };
  }
  componentDidMount() {
    let eventid = this.props.eventData && this.props.eventData._id;
    this.props.getApplications("", "", eventid);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.applications !== prevState.applications) {
      return { applications: nextProps.applications };
    } else return [];
  }
  componentDidUpdate(prevProps) {
    if (prevProps.applications !== this.props.applications) {
      this.setState({
        applications: this.props.applications
      });
    }
    if (prevProps.count !== this.props.count) {
      this.setState({
        count: this.props.count
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
  }

  onClick = status => {
    const id = this.props.eventData && this.props.eventData._id;
    this.props.getApplications("", "", id, status);
  };

  render() {
    const { classes } = this.props;
    const { loading, count } = this.state;
    const applications = this.props.applications;
    if (!loading && applications && applications.length <= 0) {
      return (
        <div className={classes.noDataSection}>
          <Grid container direction="row">
            <ApplicationStatusFilter onClick={this.onClick} count={count} />
            <Grid item align="center" className={classes.noCoworker} xs={12}>
              <span>No Applications found for this event.</span>
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          {/* Hero unit */}
          <ApplicationStatusFilter onClick={this.onClick} count={count} />
          <Grid container spacing={2}>
            {loading
              ? Array.from(new Array(6)).map(id => {
                  return (
                    <Grid item key={id} xs={12} lg={4} sm={6}>
                      <SkeletonCard />
                    </Grid>
                  );
                })
              : applications &&
                applications.length > 0 &&
                applications.map((element, id) => {
                  return (
                    <Grid item xs={3} sm={4} key={id}>
                      <ApplicationCard
                        data={element}
                        changeStatus={this.changeStatus}
                      />
                    </Grid>
                  );
                })}
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

ApplicationEvent.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    applications: state.applications.applications.application || {},
    loading: state.applications.loading,
    count: state.applications.count
  };
};

export default connect(
  mapStateToProps,
  { getApplications }
)(withStyles(styles)(ApplicationEvent));
