import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { getApplications } from "../../Actions/applicationActions";
import SkeletonCard from "../../Components/Common/Cards/SkeletonCard";
import ApplicationCard from "../../Components/Common/Cards/ApplicationCard";
import ApplicationStatusFilter from "../Applications/ApplicationStatusFilter";

const styles = () => ({
  mainOverview: {
    padding: "20px 40px"
  },
  noCoworker: {
    padding: "24px 24px 11px",
    color: "lightgrey",
    height: "401px",
    margin: "0 auto"
  }
});

class ApplicationCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applications: [],
      count: []
    };
  }

  componentDidMount() {
    let id = this.props.id;
    this.props.getApplications("", id);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.applications !== prevState.applications) {
      return { applications: nextProps.applications };
    } else return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applications !== this.props.applications) {
      this.setState({
        applications: this.props.applications
      });
    }
    if (prevProps.count !== this.props.count) {
      this.setState({
        count: this.props.count
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
  }
  onClick = status => {
    const id = this.props.id;
    this.props.getApplications("", id, "", status);
  };

  render() {
    const { classes } = this.props;
    // console.log("application data in company", this.props.applications);
    // const applications = this.props.applications.data;
    const { loading, applications, count } = this.state;
    if (applications && applications.length <= 0) {
      return (
        <div className={classes.mainOverview}>
          <Grid container direction="row">
            <ApplicationStatusFilter onClick={this.onClick} count={count} />
          </Grid>
          <Grid item align="center" className={classes.noCoworker}>
            <span>No Applications found for this company.</span>
          </Grid>
        </div>
      );
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.mainOverview}>
          <ApplicationStatusFilter onClick={this.onClick} count={count} />
          <Grid container spacing={2}>
            {loading
              ? Array.from(new Array(6)).map(id => {
                  return (
                    <Grid item key={id} xs={12} lg={4} sm={6}>
                      <SkeletonCard />
                    </Grid>
                  );
                })
              : applications &&
                applications.map((element, id) => {
                  return (
                    <Grid item xs={3} sm={4} key={id}>
                      <ApplicationCard
                        data={element}
                        changeStatus={this.changeStatus}
                      />
                    </Grid>
                  );
                })}
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

ApplicationCompany.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    applications: state.applications.applications.application,
    loading: state.applications.loading,
    count: state.applications.count
  };
};

export default connect(
  mapStateToProps,
  { getApplications }
)(withStyles(styles)(ApplicationCompany));
