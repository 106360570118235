import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import heroIllustration from "./../../Assets/media/design.svg";
import TIMELINE from "./../../Assets/Timeline.svg";
import LEARNING from "./../../Assets/Learning.svg";
import THINKING from "./../../Assets/Networking.svg";
import Authentication from "./Authentication";

const styles = theme => ({
  "@global": {
    body: {
      backgroundColor: "#fff"
    }
  },
  appBar: {
    position: "relative"
  },
  toolbarTitle: {
    flex: 1
  },
  layout: {
    width: "auto",
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 1200,
      marginLeft: "auto",
      marginRight: "auto"
    },
    paddingLeft: 0,
    paddingRight: 0
  },

  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing.unit * 2
  },
  cardActions: {
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing.unit * 2
    }
  },
  footer: {
    marginTop: theme.spacing.unit * 8,
    borderTop: `1px solid rgba(0, 0, 0, 0.12)`,
    padding: `${theme.spacing.unit * 6}px 0`
  },
  toolBar: {
    width: "auto",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 1200,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  heroUnit: {
    minHeight: "600px"
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9,
    backgroundSize: "30%"
  }
});

const tiers = [
  {
    title: "You Need More Talent",
    price: "0",
    description: [
      "10 users included",
      "2 GB of storage",
      "Help center access",
      "Email support"
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
    icon: "Learning"
  },
  {
    title: "You need to Innovate",
    subheader: "Most popular",
    price: "15",
    description: [
      "20 users included",
      "10 GB of storage",
      "Help center access",
      "Priority email support"
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
    icon: "Thinking"
  },
  {
    title: "You need fast result",
    price: "30",
    description: [
      "50 users included",
      "30 GB of storage",
      "Help center access",
      "Phone & email support"
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
    icon: "Timeline"
  }
];
const footers = [
  {
    title: "Company",
    description: ["Team", "History", "Contact us", "Locations"]
  },
  {
    title: "Features",
    description: [
      "Cool stuff",
      "Random feature",
      "Team feature",
      "Developer stuff",
      "Another one"
    ]
  },
  {
    title: "Resources",
    description: [
      "Resource",
      "Resource name",
      "Another resource",
      "Final resource"
    ]
  },
  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use"]
  }
];
const cardIcon = icon => {
  if (icon === "Learning") {
    return LEARNING;
  } else if (icon === "Thinking") {
    return THINKING;
  } else {
    return TIMELINE;
  }
};

function Landing(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <CssBaseline />

      <Authentication auth={props.auth.isAuthenticated} />

      <main className={classes.layout}>
        {/* Hero unit */}
        <Grid
          container
          spacing={16}
          alignItems="center"
          className={classes.heroUnit}
        >
          <Grid item xs={12} sm={6}>
            <div className={classes.heroContent}>
              <Typography
                component="h1"
                variant="h3"
                align="left"
                color="textPrimary"
                gutterBottom
              >
                Hackathon AS Solution
              </Typography>
              <Typography
                variant="p"
                align="left"
                color="textSecondary"
                component="p"
              >
                Quickly build an effective pricing table for your potential
                customers with this layout. It&apos;s built with default
                Material-UI components with little customization.
              </Typography>
              <Button
                color="primary"
                variant="outlined"
                style={{ marginTop: "15px", marginRight: "10px" }}
              >
                Learn More
              </Button>
              <Button
                color="primary"
                variant="contained"
                style={{ marginTop: "15px" }}
              >
                Request a Demo
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div>
              <img
                src={heroIllustration}
                alt="DevX Hackathon"
                style={{ padding: "30px" }}
              />
            </div>
          </Grid>
        </Grid>
        {/* End hero unit */}
        <Grid>
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
            style={{ marginTop: "100px" }}
          >
            Why run Hackathon?
          </Typography>
        </Grid>
        <Grid container spacing={40} alignItems="flex-end">
          {tiers.map(tier => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "Enterprise" ? 12 : 6}
              md={4}
            >
              <Card>
                <CardMedia
                  className={classes.media}
                  image={cardIcon(tier.icon)}
                />
                <CardContent>
                  <Typography
                    variant="h5"
                    align="center"
                    style={{ marginBottom: "20px" }}
                  >
                    {tier.title}
                  </Typography>
                  {tier.description.map(line => (
                    <Typography variant="subtitle1" align="center" key={line}>
                      {line}
                    </Typography>
                  ))}
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button variant="contained" color="primary">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </main>
      {/* Footer */}
      <footer className={classNames(classes.footer, classes.layout)}>
        <Grid container spacing={32} justify="space-evenly">
          {footers.map(footer => (
            <Grid item xs key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              {footer.description.map(item => (
                <Typography
                  key={item}
                  variant="subtitle1"
                  color="textSecondary"
                >
                  {item}
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}

Landing.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    auth: state.auth,
    profile: state.profile
  };
};
export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(Landing));
