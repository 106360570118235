var axios = require("axios");

// https://github.com/sindresorhus/is-absolute-url/blob/master/index.js#L7
var isAbsoluteURLRegex = /^(?:\w+:)\/\//;
const interceptor = () => {
  axios.interceptors.request.use(function(config) {
    // Concatenate base path if not an absolute URL
    if (!isAbsoluteURLRegex.test(config.url)) {
      config.url = `${process.env.REACT_APP_api_url}${config.url}`;
      config.asset_url = `${process.env.ASSET_URL}${config.asset_url}`;
    }

    return config;
  });
};

export default interceptor;
