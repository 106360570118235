import React from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  addPartner,
  getPartner,
  editPartner
} from "../../Actions/partnerActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  InputField,
  RadioField,
  UploadField,
  SelectField
} from "../../Components/Common/Form";
import CityDropdown from "../../Components/Common/CityDropdown";
import CustomButton from "../../Components/Common/Button";
import SocialHandle from "../../Components/Common/SocialHandle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Editor from "../../Components/Common/Form/Editor";
import {
  categoryOptions,
  PartnerType
} from "../../Components/Common/DropDownData";
import FormLabel from "@material-ui/core/FormLabel";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import { getRole } from "../../Utils/CheckPermissions";

const styles = theme => ({
  layout: {
    width: "auto",
    backgroundColor: props =>
      props.eventid || props.companyid ? "#F9F9F9 " : "#FFFFFF ",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      // width: 700,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    boxShadow: "none",
    backgroundColor: props =>
      props.eventid || props.companyid ? "#F9F9F9 " : "#FFFFFF ",
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  upperSection: {
    backgroundColor: props =>
      props.eventid || props.companyid ? "#F9F9F9" : "#FFFFFF",
    boxShadow: "none"
  },
  descriptionLabel: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)"
  },
  cancelButton: {
    width: "146.93px",
    height: "46px",
    backgroundColor: "#F1F1F1",
    color: "#4D7CFE",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#E2E2E2"
    }
  },
  cancelContainer: {
    display: "flex",
    alignItems: "center"
  }
});

const PartnerSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "please enter more than 2 character")
    .max(15, "")
    .required("Field Required"),
  last_name: Yup.string()
    .min(3, "please enter more than 3 character")
    .max(15, "")
    .required("Field Required"),
  logo: Yup.string().required("Field Required"),
  category: Yup.string().required("Field Required"),
  location: Yup.string().required("Field Required"),
  contact_number: Yup.number()
    .required("Field Required")
    .positive("A phone number can't start with a minus")
    .min(1111111111, "Please enter 10 digit number")
    .max(9999999999, "Please enter 10 digit number"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Field Required"),
  partner_name: Yup.string()
    .min(2, "please enter more than 2 character")
    .max(15, "")
    .required("Field Required"),
  description: Yup.string().required("Field Required")
});

class PartnerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      desc: "",
      id: this.props.match.params.id,
      path: this.props.match.path
    };
  }
  componentDidMount() {
    if (this.state.path === "/partner/edit/:id") {
      this.props.getPartner(this.state.id);
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.addPartnerData !== prevState.addPartnerData) {
      return { addPartnerData: nextProps.addPartnerData };
    }
    if (nextProps.partner !== prevState.partner) {
      return { partner: nextProps.partner };
    } else return [];
  }

  getInitialValues = id => {
    const partner = this.state.partner ? this.state.partner : "";
    const city = partner.location ? partner.location.cityName : "";
    if (id) {
      return {
        partner_name: partner.partner_name,
        logo: partner.logo,
        category: categoryOptions.filter(
          option => option.value === partner.category
        )[0],
        location: city,
        website: partner.website,
        linkedin: partner.linkedin,
        twitter: partner.twitter,
        facebook: partner.facebook,
        instagram: partner.instagram,
        first_name: partner.user ? partner.user.first_name : "",
        last_name: partner.user ? partner.user.last_name : "",
        contact_number: partner.user ? partner.user.contact_number : "",
        email: partner.user ? partner.user.email : "",
        description: partner.description,
        global: partner.global ? partner.global : ""
      };
    } else {
      return {
        partner_name: "",
        logo: "",
        category: "",
        location: "",
        website: "",
        linkedin: "",
        twitter: "",
        facebook: "",
        instagram: "",
        first_name: "",
        last_name: "",
        contact_number: "",
        email: "",
        description: "",
        global: "true"
      };
    }
  };

  closePartnerModal = flag => {
    if (this.props.eventid || this.props.companyid) {
      this.props.closeModaldata(flag);
    } else {
      this.props.history.push("/partners");
    }
  };
  formatData = values => {
    const formated = { ...values };
    const val = formated.selectedLocationObj
      ? formated.selectedLocationObj.value
      : "";
    formated.location = val;
    const categories = formated.category ? formated.category.value : "";
    formated.category = categories;
    if (this.props.eventid) {
      formated.eventid = this.props.eventid;
    }
    if (this.props.companyid) {
      formated.companyid = this.props.companyid;
    }
    return formated;
  };
  render() {
    const { classes, partnername, history } = this.props;
    const { id, addPartnerData, partner, path } = this.state;
    const { loading: addLoading } = addPartnerData;
    const globalhidden =
      history && history.location && history.location.params
        ? history.location.params.isGlobalHidden
        : {};
    const role = getRole();
    return (
      <React.Fragment>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={this.getInitialValues(id)}
              enableReinitialize={true}
              validationSchema={PartnerSchema}
              onSubmit={(values, { resetForm }) => {
                const data = this.formatData(values);
                if (path !== "/partner/edit/:id") {
                  if (this.props.eventid || this.props.companyid) {
                    this.props.addPartner(data);
                  } else this.props.addPartner(data, this.props.history);
                } else if (path === "/partner/edit/:id") {
                  this.props.editPartner(
                    this.state.id,
                    data,
                    this.props.history
                  );
                }
                resetForm(this.initialValues);
              }}
            >
              {({
                values,
                setFieldTouched,
                errors,
                touched,
                setFieldValue
              }) => (
                <Form
                  className={classes.container}
                  noValidate
                  autoComplete="off"
                  encType="multipart/form-data"
                >
                  <Paper className={classes.upperSection}>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          name="partner_name"
                          label="Name of Partner"
                          placeholder="Enter Name"
                          values={partnername}
                          mandatoryField
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <UploadField
                          label="Upload Logo"
                          name="logo"
                          id="logo"
                          onChange={setFieldValue}
                          value={values.logo}
                          folder="partner/logo"
                          required
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <SelectField
                          value={values.category}
                          name="category"
                          id="category"
                          label="Category"
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={errors.category}
                          touched={touched.category}
                          options={categoryOptions}
                          mandatoryField
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CityDropdown
                          values={values.location}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          touched={touched}
                          name="location"
                          mandatoryField
                        />
                      </Grid>
                      {role === "admin" && !globalhidden ? (
                        ""
                      ) : (
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <RadioField
                            name="global"
                            label="Partner across the system ?"
                            id="global"
                            options={PartnerType}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12}>
                        <FormLabel
                          className={classes.descriptionLabel}
                          component="legend"
                        >
                          About
                        </FormLabel>
                        <Editor
                          getval={data => setFieldValue("description", data)}
                          data={partner ? partner.description : ""}
                          error={errors.description}
                          name="description"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          name="website"
                          label="Website"
                          placeholder="Enter Website"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          paddingfield
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment
                                className={classes.inputSocialIcon}
                              >
                                <SocialHandle
                                  type="linkedin"
                                  height="25px"
                                  width="25px"
                                  inputFeildSocial
                                />
                              </InputAdornment>
                            )
                          }}
                          name="linkedin"
                          label="Linkedin"
                          placeholder="Enter Linkedin Link"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          paddingfield
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment
                                className={classes.inputSocialIcon}
                              >
                                <SocialHandle
                                  type="twitter"
                                  height="25px"
                                  width="25px"
                                  inputFeildSocial
                                />
                              </InputAdornment>
                            )
                          }}
                          name="twitter"
                          label="Twitter"
                          placeholder="Enter Twitter Link"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          paddingfield
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment
                                className={classes.inputSocialIcon}
                              >
                                <SocialHandle
                                  type="facebook"
                                  height="25px"
                                  width="25px"
                                  inputFeildSocial
                                />
                              </InputAdornment>
                            )
                          }}
                          name="facebook"
                          label="Facebook"
                          placeholder="Enter Facebook Link"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          paddingfield
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment
                                className={classes.inputSocialIcon}
                              >
                                <SocialHandle
                                  type="instagram"
                                  height="25px"
                                  width="25px"
                                  inputFeildSocial
                                />
                              </InputAdornment>
                            )
                          }}
                          name="instagram"
                          label="Instagram"
                          placeholder="Enter Instagram Link"
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper className={classes.upperSection}>
                    <Grid container spacing={2} className={classes.spocSection}>
                      <Grid item xs={12} sm={12}>
                        <ScreenHeader type="SPOC Details" />
                        {/* <span className={classes.applicationHeader}>
                          SPOC Details
                        </span> */}
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          name="first_name"
                          label="First Name"
                          placeholder="Enter First Name"
                          mandatoryField
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          name="last_name"
                          label="Last Name"
                          placeholder="Enter Last Name"
                          mandatoryField
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          name="email"
                          label="Email ID"
                          placeholder="johndoe@mail.com"
                          mandatoryField
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          name="contact_number"
                          label="Contact"
                          placeholder="91XXXXXXXX"
                          mandatoryField
                          type="tel"
                          max="10"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      xs={12}
                      sm={12}
                      spacing={2}
                      direction="row-reverse"
                    >
                      <Grid item>
                        <CustomButton
                          type="submit"
                          label={
                            path === "/partner/edit/:id" ? "update" : "Add"
                          }
                          loader={addLoading}
                        />
                      </Grid>
                      <Grid item className={classes.cancelContainer}>
                        <Button
                          onClick={() => this.closePartnerModal(false)}
                          className={classes.cancelButton}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Form>
              )}
            </Formik>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    addPartnerData: state.partners.addPartner,
    auth: state.auth,
    errors: state.errors,
    partner: state.partners.partner
  };
};

export default connect(
  mapStateToProps,
  { addPartner, getPartner, editPartner }
)(withStyles(styles)(withRouter(PartnerForm)));
