import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";

const $quoteSym = " ";

const styles = () => ({
  mainAbout: {
    padding: "20px 40px"
  },
  title: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "29px",
      left: "0px",
      width: "24px",
      borderBottom: "2px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute"
    }
  },
  ideaCont: {
    fontSize: "14px",
    fontWeight: "normal",
    color: "#626262"
  }
});

class About extends Component {
  state = {
    dense: false,
    secondary: false
  };
  render() {
    const { classes, data } = this.props;
    // const { dense, secondary } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <div className={classes.mainAbout}>
            <Typography variant="h6" className={classes.title}>
              Idea Brief
            </Typography>
            {/* <p>{application.idea}</p> */}

            <p className={classes.ideaCont}>{data.idea}</p>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

About.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(About);
