import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Header } from "../Layout";
import { useToasts } from "../../ToastManager";

const PrivateRoute = props => {
  const { component, auth, title, ...rest } = props;
  const toastManager = useToasts();

  useEffect(() => {
    const { type, text, show } = props.notifications;
    if (show) toastManager.addToast({ type, text });
  }, [props.notifications]);
  return (
    <Route
      {...rest}
      render={props => {
        return auth.isAuthenticated === true ? (
          <React.Fragment>
            {/* <NavContainer /> */}
            <Header title={title} component={component} data={props} />
            {/* <div
              style={{
                marginLeft: "240px",
                padding: "20px",
                backgroundColor: "#EFF3F6",
                minHeight: "calc(100vh - 65px)"
              }}
            >
              <Component {...props} />
            </div> */}
          </React.Fragment>
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProp = state => {
  return {
    auth: state.auth,
    notifications: state.notifications.notification
  };
};

export default connect(mapStateToProp)(PrivateRoute);
