import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import SelectField from "../Common/Form/SelectField";
import { getAssociateSkills } from "./../../Actions/associateActions";
import { connect } from "react-redux";
import { spacecapitalize } from "../../Utils/StringFunctions";

const styles = {};

const AssociateSkills = props => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    mandatoryField
  } = props;
  const [skillsList, setSkillsList] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  const getSkills = skillsList => {
    return skillsList
      ? skillsList.map(item => {
          return {
            value: item._id,
            label: spacecapitalize(item.skill_type)
          };
        })
      : [];
  };

  useEffect(() => {
    props.getAssociateSkills();
  }, []);

  useEffect(() => {
    setSkillsList(props.skillsList);
  }, [props.skillsList]);

  useEffect(() => {
    setSelectedValue(values);
  }, [values]);

  const changeDropDown = (name, values) => {
    setSelectedValue(values);
    setFieldValue(name, values);
  };
  return (
    <SelectField
      value={selectedValue}
      name="skills"
      id="skills"
      label="Domain & Technology Expertise"
      onChange={changeDropDown}
      onBlur={setFieldTouched}
      error={errors.skillsList}
      touched={touched}
      options={getSkills(skillsList)}
      multi={true}
      creatable
      mandatoryField={mandatoryField ? mandatoryField : ""}
    />
  );
};

const mapStateToProps = state => {
  return {
    skillsList: state.associates.skillsList
  };
};

export default connect(
  mapStateToProps,
  { getAssociateSkills }
)(withStyles(styles)(AssociateSkills));
