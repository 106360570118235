import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import RenderHtml from "../../Components/Common/RenderHtml";
const $quoteSym = " ";
const styles = () => ({
  mainOverview: {
    padding: "20px 40px"
  },
  mainheading: {
    fontWeight: 600,
    fontSize: 16,
    color: "#000",
    position: "relative",
    padding: " 14px 10px 10px 10px",
    "&:after": {
      top: "34px",
      left: "11px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute"
    }
  },
  offerDetail: {
    padding: "10px"
  }
});

function AboutPartner(props) {
  const { classes, data } = props;
  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.mainOverview}>
        <Grid container xs={12} lg={12} spacing={2}>
          <Grid item xs={12} lg={12} className={classes.offerDetail}>
            <Typography variant="subheading" className={classes.mainheading}>
              About {data.partner_name}
            </Typography>
            <RenderHtml data={data ? data.description : ""} />
          </Grid>
        </Grid>
      </main>
    </React.Fragment>
  );
}

export default withStyles(styles)(AboutPartner);
