import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PartnerCard from "../../Components/Common/Cards/PartnerCard";
import { getPartners } from "../../Actions/partnerActions";
import { connect } from "react-redux";
import SearchBar from "../../Components/Common/SearchBar";
import AddFormButton from "../../Components/Common/AddFormButton";
import SkeletonCard from "../../Components/Common/Cards/SkeletonCard";
import NoRecordsFound from "../../Components/Common/NoRecordsFound";
import NoDataFound from "../../Components/Common/NoDataFound";
import { getRole } from "../../Utils/CheckPermissions";

const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    backgroundColor: "white",
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  heroUnit: {
    margin: "20px 0"
  },
  buttons: {
    display: "inline-block",
    textAlign: "right",
    width: "70%",
    margin: "27px 0px"
  },
  title: {
    fontSize: "26px",
    margin: "20px 11px 0px 11px",
    borderBottom: "3px solid #edc40c",
    display: "inline-block",
    paddingBottom: "7px",
    width: "22px"
  },
  search: {
    backgroundColor: "whitesmoke",
    border: "1px solid #e1dbdb"
  },
  subHeader: {
    display: "flex",
    alignItems: "center",
    margin: "30px 0px"
  },
  main: {
    background: "#fff",
    padding: "24px"
  }
});
class AdminPartners extends React.Component {
  constructor(props) {
    super(props);
    this.state = { partners: [], search: "", isSearch: false };
  }
  componentDidMount() {
    this.props.getPartners();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.partners !== prevState.partners) {
      return { partners: nextProps.partners };
    } else return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.partners !== this.props.partners) {
      this.setState({
        partners: this.props.partners
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
  }

  onChange = e => {
    this.setState({ search: e.target.value }, () => {
      this.props.getAssociates(e.target.value);
    });
  };
  searchPartners = (search, isSearch) => {
    this.setState({ isSearch: true, search });
    this.props.getPartners("", search, isSearch);
  };
  render() {
    const { classes } = this.props;
    const { partners, loading, isSearch, search } = this.state;
    const role = getRole();
    if (!loading && partners && partners.length <= 0 && !isSearch) {
      return (
        <div style={{ textAlign: "center" }}>
          <NoDataFound />
          <AddFormButton
            type="Partner"
            url="/partners/add"
            params={{ isGlobalHidden: role === "admin" ? false : true }}
          />
        </div>
      );
    }
    return (
      <div className={classes.main}>
        <div style={{ marginBottom: "10px" }}>
          {/* <ScreenHeader type="partners" /> */}

          <div className={classes.subHeader}>
            <SearchBar type="Search Partner" onSearch={this.searchPartners} />
            <AddFormButton
              type="Partner"
              url="/partners/add"
              params={{ isGlobalHidden: role === "admin" ? false : true }}
            />
          </div>
        </div>
        {this.state.partners && this.state.partners.length <= 0 && isSearch && (
          <div>
            <NoRecordsFound
              text={`No records found for your text "${search}"`}
            />
          </div>
        )}
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <div style={{ clear: "both" }} />
            <div className={classes.heroUnit}></div>

            <Grid container spacing={2}>
              {loading
                ? Array.from(new Array(6)).map(id => {
                    return (
                      <Grid item key={id} xs={12} lg={4} sm={6}>
                        <SkeletonCard />
                      </Grid>
                    );
                  })
                : partners &&
                  partners.map(element => {
                    return (
                      <Grid item xs={12} lg={4} md={6} sm={12} key={element.id}>
                        <PartnerCard
                          data={element}
                          history={this.props.history}
                          viewMore
                          collapseIcon
                        />
                      </Grid>
                    );
                  })}
            </Grid>
          </Paper>
        </main>
      </div>
    );
  }
}

AdminPartners.propTypes = {};

const mapStateToProps = state => {
  return {
    partners: state.partners.partners,
    loading: state.partners.loading
  };
};

export default connect(
  mapStateToProps,
  { getPartners }
)(withStyles(styles)(AdminPartners));
