import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { User } from "react-feather";
import { Menu } from "react-feather";
import { connect } from "react-redux";
import { logoutUser } from "./../../Actions/authAction";
import { clearCurrentProfile } from "./../../Actions/profileAction";
import { withRouter } from "react-router-dom";
import { RenderMenu } from "./userMenu";
import DevxLogo from "./../../Assets/HaaSLogo.png";
import { Typography, IconButton, MenuItem } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import NavigatorItems from "./NavigatorItems";
import CssBaseline from "@material-ui/core/CssBaseline";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex",
    border: "none"
  },
  toolbar: {
    paddingRight: 24,
    minHeight: "50px"
  },
  userIcon: {
    color: "#110F24"
  },
  grow: {
    flexGrow: 1
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    minHeight: "50px"
  },
  appBar: {
    backgroundColor: "#fff",
    Height: "30px",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36,
    color: "#211B4E"
  },
  menuButtonHidden: {
    color: "#211B4E",
    display: "none"
  },
  title: {
    flexGrow: 1,
    color: "#211B4E",
    fontWeight: 600,
    fontSize: "16px"
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  ImageAlign: {
    width: "100%"
  },
  devxlogo: {
    // height: "60px",
    // width: "60px",
    color: theme.palette.primary.main,
    [theme.breakpoints.up("sm")]: {
      display: "block"
    },
    marginLeft: "12px"
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: {
    marginTop: "50px"
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  ListItems: {
    fontSize: "14px"
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 240
  },
  MuiDivider: {
    root: {
      border: "none",
      height: "1px",
      margin: "0",
      flexShrink: "0",
      backgroundColor: "#fff"
    }
  }
});

class Header extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    open: true
  };

  handleDrawerOpen = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  logout = () => {
    this.props.logoutUser();
    this.props.clearCurrentProfile();
    this.props.history.push("/");
  };
  render() {
    const { anchorEl, mobileMoreAnchorEl, open } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchororigin={{ vertical: "top", horizontal: "right" }}
        transformorigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        {/* <MenuItem onClick={this.handleMobileMenuClose}>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        <MenuItem onClick={this.handleMobileMenuClose}>
          <IconButton color="inherit">
            <Badge badgeContent={11} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem> */}
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton color="primary">
            <User />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );
    const { auth, title, component: Component, data } = this.props;
    const isAuthenticated = this.props.auth.isAuthenticated;

    return isAuthenticated && auth.hasRole !== "candidate" ? (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              component="h1"
              variant="h6"
              noWrap
              className={classes.title}
            >
              {title}
            </Typography>
            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton
                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <User className={classes.userIcon} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <div className={classes.ImageAlign}>
              <img src={DevxLogo} alt="" className={classes.devxlogo} />
            </div>
            <IconButton onClick={this.handleDrawerOpen}>
              <ChevronLeftIcon />
            </IconButton>
          </div>

          <List className={classes.ListItems}>
            {<NavigatorItems history={this.props.history} />}
          </List>
        </Drawer>
        <RenderMenu
          anchorEl={this.state.anchorEl}
          isMenuOpen={isMenuOpen}
          handleMenuClose={this.handleMenuClose}
          logout={this.logout}
        >
          {renderMobileMenu}
        </RenderMenu>
        {/* <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
           
          </Container>
        </main> */}
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open
          })}
        >
          <div className={classes.appBarSpacer} />
          <Component {...data} />
        </main>
      </div>
    ) : (
      ""
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser, clearCurrentProfile }
)(withStyles(styles, { withTheme: true })(withRouter(Header)));

// export default withStyles(styles, { withTheme: true })(Header);
