import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
// import { checkPermission } from "Utils/checkPermission";
import { Edit2 } from "react-feather";
const styles = () => {
  return {
    editicon: {
      color: "#4D7CFE",
      position: "relative",
      top: "2px"
    },
    linkEdit: {
      backgroundColor: "#DFF1FC",
      borderRadius: "12px",
      padding: "3px 8px"
    }
  };
};

class EditButton extends Component {
  render() {
    // const { url, type } = this.props;
    const { classes } = this.props;
    return (
      /* {checkPermission(renderpermission()) && ( */
      <div>
        <Link className={classes.linkEdit} {...this.props}>
          <Edit2 className={classes.editicon} size="17" />
        </Link>
      </div>
      /* )} */
    );
  }
}

export default withStyles(styles)(EditButton);
