import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Search } from "react-feather";
const styles = () => {
  return {
    image: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "center"
    },
    maintext: {
      color: "lightgrey"
    },
    headerSpacing: {
      marginTop: "15%"
    },
    height: {
      height: props => (props.height ? "320px" : "")
    }
  };
};

const DataNotFoundTable = props => {
  const { text, classes, colSpan } = props;
  return (
    <tr className={classes.root1} style={{ textAlign: "center" }}>
      <td
        colSpan={colSpan}
        style={{ padding: "10px 0px" }}
        className={classes.height}
      >
        <Search size={45} />
        <Typography variant="h6" className={classes.maintext}>
          {text}
        </Typography>
      </td>
    </tr>
  );
};

export default withStyles(styles)(DataNotFoundTable);
