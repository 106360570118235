import {
  GET_EVENTS,
  GET_EVENT,
  EVENTS_LOADING,
  GET_ALL_CHALLENGES,
  CHALLENGES_LOADING,
  GET_SINGLE_CHALLENGE,
  GET_MY_CHALLENGES,
  ADD_EVENT,
  ADD_EVENT_ERROR,
  ADD_EVENT_LOADING,
  ADD_PARTNER_EVENT,
  ADD_ASSOCIATE_EVENT,
  GET_ALL_EVENTS,
  GET_ALL_EVENTS_ERROR,
  EDIT_EVENT,
  ADD_EVENT_TO_PARTNER,
  ADD_EVENT_TO_PARTNER_ERROR,
  UPDATE_EVENT_STATUS_SUCCESS,
  EVENT_ERROR
} from "./../Actions/types";

const initialState = {
  events: [],
  loading: false,
  event: [],
  challenges: [],
  challenge: [],
  myChallenge: [],
  addEvent: { eventLoading: false },
  allEvents: [],
  addAssociatetoEvent: {},
  eventPartner: {},
  updateEventStatus: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        loading: false,
        events: action.payload && action.payload.data
      };
    case GET_EVENT:
      return {
        ...state,
        loading: false,
        event: action.payload && action.payload.data
      };
    case EVENTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_ALL_CHALLENGES:
      return {
        ...state,
        loading: false,
        challenges: action.payload && action.payload.data
      };
    case CHALLENGES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_SINGLE_CHALLENGE:
      return {
        ...state,
        loading: false,
        challenge: action.payload && action.payload.data
      };
    case GET_MY_CHALLENGES:
      return {
        ...state,
        loading: false,
        myChallenge: action.payload && action.payload.data
      };
    case ADD_EVENT:
      return {
        ...state,
        addEvent: {
          success: true,
          loading: false,
          data: action.payload.data
        }
      };
    case ADD_PARTNER_EVENT:
      return {
        ...state,
        addPartnertoEvent: {
          success: true,
          loading: false,
          data: action.payload && action.payload.data
        }
      };
    case ADD_ASSOCIATE_EVENT:
      return {
        ...state,
        addAssociatetoEvent: {
          success: true,
          loading: false,
          data: action.payload && action.payload.data
        }
      };
    case ADD_EVENT_LOADING:
      return {
        ...state,
        addEvent: {
          loading: true
        }
      };
    case ADD_EVENT_ERROR:
      return {
        ...state,
        addEvent: {
          success: false,
          loading: false
        }
      };
    case GET_ALL_EVENTS:
      return {
        ...state,
        loading: false,
        allEvents: action.payload && action.payload.data
      };
    case GET_ALL_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        success: false
      };
    case EDIT_EVENT:
      return {
        ...state,
        loading: false,
        event: action.payload && action.payload.data
      };
    case ADD_EVENT_TO_PARTNER:
      return {
        ...state,
        eventPartner: {
          success: true,
          loading: false
        }
      };
    case ADD_EVENT_TO_PARTNER_ERROR:
      return {
        ...state,
        eventPartner: {
          success: false,
          loading: false
        }
      };
    case UPDATE_EVENT_STATUS_SUCCESS:
      return {
        ...state,
        updateEventStatus: {
          success: true,
          loading: false,
          data: action.payload.data
        }
      };
    case EVENT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        data: action.payload && action.payload.data
      };
    default:
      return state;
  }
}
