import axios from "axios";
import {
  GET_APPLICANTS,
  APPLICANTS_LOADING,
  GET_APPLICANT,
  GET_APPLICANTS_ERROR
} from "./types";
// import { stat } from "fs";

export const getApplicants = (search, id, eventid, status) => dispatch => {
  dispatch(setApplicantsLoading());
  let url = "";
  if (search) {
    url = `/api/application/applicant/allapplicant?q=${search}`;
  } else if (id) {
    url = `/api/application/applicant/allapplicant?companyid=${id}`;
  } else if (eventid) {
    url = `/api/application/applicant/allapplicant?eventid=${eventid}`;
  } else {
    url = `/api/application/applicant/allapplicant`;
  }
  if (status && status !== "all") {
    url = `${url}&applicantstatus=${status}`;
  }
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_APPLICANTS,
        payload: res.data,
        status: status || "all"
      });
    })
    .catch(err =>
      dispatch({
        type: GET_APPLICANTS_ERROR,
        payload: err.response && err.response.data
      })
    );
};

export const getApplicant = id => dispatch => {
  dispatch(setApplicantsLoading());
  axios
    .get(`/api/application/applicant/${id}`)
    .then(res => {
      dispatch({
        type: GET_APPLICANT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_APPLICANTS_ERROR,
        payload: err.response && err.response.data
      })
    );
};

// Profile Loading
export const setApplicantsLoading = () => {
  return {
    type: APPLICANTS_LOADING
  };
};
