import { GET_ERRORS } from "../Actions/types";
const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      console.log("Action:", action);
      return action.payload;
    default:
      return state;
  }
}
