import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import FilterButton from "../../Components/Common/FilterButton";
import { withRouter } from "react-router-dom";

const styles = () => {
  return {
    status: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: "20px"
    }
  };
};

class ApplicantStatusFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "all",
      activeStatus: ""
    };
  }

  handleClickStatus = status => {
    this.setState(
      {
        status
      },
      () => {
        this.props.onClick(status);
      }
    );
  };
  render() {
    const { classes, count } = this.props;
    const { status } = this.state;
    const checkStatusActive = givenStatus => {
      return status === givenStatus ? "active" : "inActive";
    };

    return (
      <div className={classes.status}>
        <FilterButton
          type="All"
          onClick={() => this.handleClickStatus("all")}
          status={checkStatusActive("all")}
          count={count && count.all}
        />
        <FilterButton
          type="Registered"
          onClick={() => this.handleClickStatus("registered")}
          status={checkStatusActive("registered")}
          count={count && count.registered ? count.registered : 0}
        />
        <FilterButton
          type="Invited"
          onClick={() => this.handleClickStatus("invited")}
          status={checkStatusActive("invited")}
          count={count && count.invited}
        />
        <FilterButton
          type="Invitation Accepted"
          onClick={() => this.handleClickStatus("invitation-accepted")}
          status={checkStatusActive("invitation-accepted")}
          count={count && count.invitation_accepted}
        />
        <FilterButton
          type="Invitation Rejected"
          onClick={() => this.handleClickStatus("invitation-rejected")}
          status={checkStatusActive("invitation-rejected")}
          count={count && count.invitation_rejected}
        />
        <FilterButton
          type="On hold"
          onClick={() => this.handleClickStatus("on_hold")}
          status={checkStatusActive("on_hold")}
          count={count && count.on_hold}
        />
        <FilterButton
          type="Participated"
          onClick={() => this.handleClickStatus("participated")}
          status={checkStatusActive("participated")}
          count={count && count.participated}
        />
      </div>
    );
  }
}
export default withStyles(styles)(withRouter(ApplicantStatusFilter));
