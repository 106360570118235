import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import SkeletonCard from "../../Components/Common/Cards/SkeletonCard";
import ApplicantCard from "../../Components/Common/Cards/ApplicantCard";
import { getApplicants } from "../../Actions/applicantActions";
import ApplicantStatusFilter from "../Applicants/ApplicantStatusFilter";

const styles = () => ({
  mainOverview: {
    padding: "20px 40px"
  },
  noCoworker: {
    padding: "24px 24px 11px",
    color: "lightgrey",
    height: "401px",
    margin: "0 auto"
  }
});

class ApplicantsCompany extends Component {
  constructor(props) {
    super(props);
    this.state = { applicants: [], count: [] };
  }
  componentDidMount() {
    let id = this.props.id;
    this.props.getApplicants("", id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicants !== this.props.applicants) {
      this.setState({
        applicants: this.props.applicants
      });
    }
    if (prevProps.count !== this.props.count) {
      this.setState({
        count: this.props.count
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
  }
  onClick = status => {
    let id = this.props.id;
    this.props.getApplicants("", id, "", status);
  };
  render() {
    const { classes } = this.props;
    // const { applicants } = this.props.applicants ? this.props : {};
    const { loading, applicants, count } = this.state;
    if (applicants && applicants.length <= 0) {
      return (
        <div className={classes.mainOverview}>
          <Grid container direction="row">
            <ApplicantStatusFilter onClick={this.onClick} count={count} />
            <Grid item align="center" className={classes.noCoworker}>
              <span>No Applicants found for this company.</span>
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.mainOverview}>
          <ApplicantStatusFilter onClick={this.onClick} count={count} />
          <Grid container spacing={2}>
            {loading
              ? Array.from(new Array(6)).map(id => {
                  return (
                    <Grid item key={id} xs={12} lg={4} sm={6}>
                      <SkeletonCard />
                    </Grid>
                  );
                })
              : applicants &&
                applicants.map((element, id) => {
                  return (
                    // element &&
                    // element.map((element, id) => {
                    // return (
                    <Grid item xs={3} sm={4} key={id}>
                      <ApplicantCard
                        data={element}
                        history={this.props.history}
                        viewMore
                      />
                    </Grid>
                    // );
                    // })
                  );
                  // })
                  // );
                })}
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

ApplicantsCompany.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    applicants: state.applicants.applicants.applicant,
    loading: state.applicants.loading,
    count: state.applicants.count
  };
};

export default connect(
  mapStateToProps,
  { getApplicants }
)(withStyles(styles)(ApplicantsCompany));
