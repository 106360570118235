import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  label: {
    fontSize: "15px",
    fontWeight: "400",
    color: "#252631",
    marginBottom: "1rem"
  }
};
const TextareaField = ({ name, ...rest }) => {
  return (
    <Field
      name={name}
      component="textarea"
      margin="normal"
      placeholder="Type text here.."
      fullWidth
      InputLabelProps={{ shrink: true }}
      {...rest}
    />
  );
};

TextareaField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default withStyles(styles)(TextareaField);
