import { GET_ERRORS, SET_CURRENT_USER } from "./types";
import axios from "axios";
import setAuthToken from "./../Utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";
// Register
export const registerUser = (userData, history) => dispatch => {
  axios
    .post("/api/signup", userData)
    .then(() => {
      // console.log("Result: ", result);
      dispatch(showSuccess("User"));
      history.push("/login");
    })
    .catch(err => {
      // console.log("Error: ", err);
      dispatch(showError("There was some error while creating user"));
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data
      });
    });
};

// Login User - Get user token
export const loginUser = (userData, setErrors, setSubmitting) => dispatch => {
  axios
    .post("/api/signin", userData)
    .then(result => {
      // SAVE to local storage
      const { token } = result.data.data;

      // Set token to local storage
      localStorage.setItem("jwtToken", token);
      // Set Token to Auth Header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      console.log(decoded, "decoded data is");
      // Set Current User
      dispatch(setCurrentUser(decoded));
    })
    .catch(err => {
      const error = err.response;

      dispatch({ type: GET_ERRORS, payload: err.response.data });
      // dispatch(showError("There was some error while login."));
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (setErrors) {
        setErrors(transformErrorsFromApi(error));
      }
    });
};

// Set loggedin users
export const setCurrentUser = decoded => {
  console.log(decoded, "deconded from current");
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // remove token from local storage
  localStorage.removeItem("jwtToken");
  // remove Auth header
  setAuthToken(false);
  // Set current user to {} which will also set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
