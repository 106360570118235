import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import { Lock } from "react-feather";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "./../../Actions/authAction";
import TextFieldGroup from "./../../Components/Common/TextFieldGroup";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 450,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  textFieldHelper: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: 0
  }
});

class Forgotpasswordpage extends React.Component {
  state = {
    email: "",
    password: "",
    errors: {}
  };

  componentDidMount() {
    let { auth } = this.props;
    if (auth.isAuthenticated) {
      this.redirectLogin(auth.hasRole);
    }
  }

  redirectLogin(role) {
    let appId =
      (this.props.history.location.state &&
        this.props.history.location.state.appID) ||
      null;

    if (appId) {
      this.props.history.push(`/add-application/${appId}`);
    } else {
      if (role === "candidate") {
        this.props.history.push("/");
      } else {
        this.props.history.push("/dashboard");
      }
    }
  }
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.auth.isAuthenticated) {
  //     this.redirectLogin(nextProps.auth.hasRole);
  //   }
  //   if (nextProps.errors) {
  //     this.setState({ errors: nextProps.errors });
  //   }
  // }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSubmit = e => {
    e.preventDefault();

    const loginInfo = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(loginInfo);
  };
  render() {
    const { errors } = this.state;
    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Lock color="black" />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            gutterBottom
            style={{ fontWeight: "500" }}
          >
            Did you forgot your password
          </Typography>
          <Typography
            component="h2"
            variant="body1"
            style={{ textAlign: "center", padding: "12px 8px" }}
          >
            Enter your email address you're using for your account below and we
            will send you a password reset link
          </Typography>
          <form className={classes.form} onSubmit={this.onSubmit}>
            <FormControl required fullWidth>
              <TextFieldGroup
                name="email"
                errors={errors}
                label="Email Address"
                id="email"
                onChange={this.onChange}
                type="input"
                value={this.state.email}
              />
            </FormControl>
            {/* <FormControl margin="none" required fullWidth>
              <TextFieldGroup
                name="password"
                errors={errors}
                label="Password"
                id="password"
                onChange={this.onChange}
                type="password"
                value={this.state.password}
              />
            </FormControl> */}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Request Reset Link
            </Button>
            <Typography
              component="h2"
              variant="body1"
              style={{ textAlign: "center", padding: "16px 5px" }}
            >
              Back to <Link to="/Login">Login</Link>
            </Typography>
          </form>
        </Paper>
      </main>
    );
  }
}

Forgotpasswordpage.propType = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(withStyles(styles)(Forgotpasswordpage));
