import React from "react";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";
import Twitter from "../../Assets/icons/Twitter.svg";
import Linkedin from "../../Assets/icons/Linkedin.svg";
import Instagram from "../../Assets/icons/Instagram.svg";
import FacebookRoundIcon from "../../Assets/icons/FacebookRoundIcon.svg";

// USAGE

/* <SocialHandle type="facebook" height="25px" width="25px" />; */
// URL is optional
// TODO WHILE DISPLAYING CARDS IF THERE IS NO URL DECREASE THE OPACITY

const styles = () => {
  return {
    social: {
      height: props => (props.height ? props.height : "40px"),
      width: props => (props.height ? props.height : "40px")
    },
    socialIcon: {
      height: props => (props.height ? props.height : "40px"),
      width: props => (props.height ? props.height : "40px"),
      position: "absolute",
      marginTop: "-2%",
      marginLeft: "2%"
    },
    disable: {
      opacity: "0.5"
    }
  };
};
const SocialHandle = props => {
  const { type, url, classes, inputFeildSocial } = props;
  const getSocialType = type => {
    switch (type) {
      case "twitter":
        return Twitter;
      case "instagram":
        return Instagram;
      case "linkedin":
        return Linkedin;
      case "facebook":
        return FacebookRoundIcon;

      default:
        break;
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a target="_blank" href={url}>
      <img
        src={getSocialType(type)}
        alt=""
        className={classNames(
          inputFeildSocial ? classes.socialIcon : classes.social,
          url === "" ? classes.disable : ""
        )}
      />
    </a>
  );
};

export default withStyles(styles)(SocialHandle);
