import React from "react";
import { withRouter } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Lock } from "react-feather";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { createProfile } from "../../Actions/profileAction";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Form } from "react-formik-ui";

import * as Yup from "yup";

import InputField from "../../Components/Common/Form/InputField";
import RadioField from "../../Components/Common/Form/RadioField";
import DatePickerField from "../../Components/Common/Form/DatePickerField";
import UploadField from "../../Components/Common/Form/UploadField";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  textFieldHelper: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: 0
  }
});

const ProfileSchema = Yup.object().shape({
  location: Yup.string().required("please Enter Your Location")
  // gender: Yup
  // .oneOf(['female', 'male'])
  // .required('Please select your gender'),
});

const radioOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" }
];

// const [selectedDate, handleDateChange] = useState(new Date());

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date()
    };
  }
  handleDateChange = selectedDate => {
    // console.log("date is", selectedDate);
    this.setState({ selectedDate });
  };

  state = {
    errors: {}
  };
  render() {
    const { classes } = this.props;
    console.log("Props: ", this.props);

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Lock color="black" />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create Profile
          </Typography>
          <Formik
            initialValues={{
              image: "",
              date_of_birth: new Date(),
              gender: "",
              location: "",
              githubusername: "",
              twitter: "",
              facebook: "",
              linkedin: "",
              user: this.props.auth.user.id
            }}
            validationSchema={ProfileSchema}
            onSubmit={(values, { setSubmitting }) => {
              console.log("Values: ", values);
              this.props.createProfile(values, this.props.history);
            }}
          >
            {({
              values,
              isSubmitting,
              handleBlur,
              setFieldTouched,
              setFieldValue
            }) => (
              <Form>
                <UploadField
                  label="Upload Profile Photo"
                  name="image"
                  id="image"
                  onChange={setFieldValue}
                  value={values}
                />

                <RadioField
                  name="gender"
                  label="Gender"
                  options={radioOptions}
                />

                <DatePickerField
                  name="date_of_birth"
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  value={values.date_of_birth}
                  disableFuture
                  label="Birth Date"
                />
                <InputField name="location" label="Location" />

                <InputField name="githubusername" label="Github Username" />
                <InputField name="twitter" label="Twitter" />

                <InputField name="facebook" label="Facebook" />

                <InputField name="linkedin" label="Linked In" />

                <Button type="submit" variant="contained">
                  Create Profile
                </Button>
              </Form>
            )}
          </Formik>
        </Paper>
      </main>
    );
  }
}

ProfileForm.propTypes = {
  createProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    errors: state.errors,
    auth: state.auth
  };
};

export default connect(
  mapStateToProps,
  { createProfile }
)(withStyles(styles)(withRouter(ProfileForm)));
