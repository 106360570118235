import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import cardLogo from "../../../Assets/CardPhoto.png";
import { Link } from "react-router-dom";
import { capitalize } from "../../../Utils/StringFunctions";
const ASSET_URL = process.env.REACT_APP_ASSET_URL;

const styles = {
  card: {
    maxWidth: 400,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.10)"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  }
};

function CardView(props) {
  const { classes, data } = props;
  return (
    <Card className={classes.card} boxShadow={1}>
      <CardMedia
        className={classes.media}
        image={data.banner ? `${ASSET_URL}${data.banner}` : cardLogo}
        title="Image title"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {capitalize(data.event_name)}
        </Typography>

        <Typography gutterBottom variant="p" component="p">
          {data.details}
        </Typography>
        {/* {data.info && data.info[0] && (
          <Grid container style={{ marginTop: "20px" }}>
            <Grid item style={{ marginRight: "10px" }}>
              <Chip
                label={
                  data.info[0] &&
                  moment(data.info[0].starting_date).format("DD/MM/YYYY")
                }
                color="primary"
                variant="outlined"
                avatar={
                  <Avatar>
                    <Calendar />
                  </Avatar>
                }
              />
            </Grid>
            <Grid item>
              <Chip
                label={data.info[0] && data.info[0].location}
                color="primary"
                variant="outlined"
                avatar={
                  <Avatar>
                    <MapPin />
                  </Avatar>
                }
              />
            </Grid>
          </Grid>
        )} */}
      </CardContent>
      <CardActions>
        <Link to={`/add-application/${data._id}`}>
          <Button size="medium" color="primary">
            Apply Now
          </Button>
        </Link>

        <Link to={`/event/${data._id}`}>
          <Button size="medium" color="primary">
            Learn More
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
}
export default withStyles(styles)(CardView);
