import React from "react";
import { Card, CardContent, Grid, CardHeader, Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DefaultImage from "../../../Assets/noimage.png";
import { MapPin, PhoneCall, Mail, User, Home } from "react-feather";
import Text from "../../Common/Text";
import { capitalize } from "../../../Utils/StringFunctions";
import CloseIcon from "@material-ui/icons/Close";
import SocialHandle from "../../Common/SocialHandle";
import Settings from "../../Common/Settings";
import Tooltip from "../../Common/NameToolTip";
import IconButton from "@material-ui/core/IconButton";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;

const styles = theme => {
  return {
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      border: "1px solid #efefef",
      boxShadow: "0 5px 10px rgba(0,0,0,0.04), 0 5px 5px rgba(0,0,0,0.05)"
      // backgroundColor: "#FCFDFE"
    },
    cardContent: {
      flexGrow: 1,
      textAlign: "center",
      borderBottom: "1px solid #efefef",
      marginBottom: "10px",
      backgroundColor: "#fff",
      "& p": {
        color: theme.palette.font.head1,
        textTransform: "capitalize"
      },
      "& h2": {
        color: "#82A3FE",
        textTransform: "capitalize"
      }
    },
    image: {
      height: "auto"
    },
    locationIcon: {
      position: "relative",
      // top: "2px",
      color: "#626262"
    },
    button: {
      borderRadius: "50px",
      backgroundColor: "#959595",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#959595",
        color: "#fff"
      }
    },
    location: {
      fontWeight: "normal",
      fontSize: 11,
      textAlign: "left",
      color: "#626262"
    },
    footerButton: {
      textDecoration: "none"
    },
    Icon: {
      display: "flex",
      justifyContent: "space-between",
      width: "108%"
    },
    MailIcon: {
      display: "flex",
      width: "104%",
      justifyContent: "space-between",
      textTransform: "capitalize"
    },
    DisplayMail: {
      display: "flex",
      width: "104%",
      justifyContent: "space-between"
    },
    ContentContainer: {
      marginLeft: "1%"
    },
    titleColor: {
      color: "#4d7cfe"
    }
  };
};

function AssociateCard(props) {
  const { classes, data, history } = props;
  const firstNameChar = data.user ? capitalize(data.user.first_name) : "";
  const lastNameChar = data.user ? capitalize(data.user.last_name) : "";
  const totalLength =
    Number(firstNameChar.length) + Number(lastNameChar.length);
  const name = firstNameChar.concat(" ", capitalize(lastNameChar));

  const handleCardClick = () => {
    props.viewMore && history.push(`/associates/${data._id}`);
  };

  const handleEditButton = () => {
    history.push(`/associate/edit/${data._id}`);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          data.photo ? (
            <Avatar
              className={classes.avatar}
              src={`${ASSET_URL}${data.photo}`}
            />
          ) : (
            <Avatar className={classes.avatar} src={DefaultImage} />
          )
        }
        title={
          totalLength > 30 ? (
            <Tooltip amount="29" text={capitalize(name)} />
          ) : (
            `${firstNameChar} ${lastNameChar}`
          )
        }
        action={
          props.collapseIcon ? (
            <Settings editMethod={handleEditButton} />
          ) : (
            <IconButton aria-label="settings">
              <CloseIcon />
            </IconButton>
          )
        }
        className={classes.titleColor}
      />

      <CardContent className={classes.cardContent} onClick={handleCardClick}>
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          spacing={1}
          className={classes.ContentContainer}
        >
          <Grid item>
            <div className={classes.Icon}>
              <PhoneCall size={16} className={classes.locationIcon} />
              {data.user && (
                <Text
                  type="header4"
                  label={data.user.contact_number}
                  color="#626262"
                />
              )}
            </div>
          </Grid>
          <Grid item>
            <div className={classes.DisplayMail}>
              <Mail size={16} className={classes.locationIcon} />
              {data.user && (
                <Text
                  type="header4"
                  padding="1px 3px"
                  label={data.user.email}
                  color="#626262"
                />
              )}
            </div>
          </Grid>
          <Grid item>
            <div className={classes.MailIcon}>
              <User size={16} className={classes.locationIcon} />
              {data.designation ? (
                <Text
                  type="header4"
                  padding="2px 4px"
                  label={capitalize(data.designation)}
                  color="#626262"
                />
              ) : (
                <span>&nbsp;-</span>
              )}
            </div>
          </Grid>
          <Grid item>
            <div className={classes.MailIcon}>
              <Home size={16} className={classes.locationIcon} />
              {data.company_name ? (
                <Text
                  type="header4"
                  padding="2px 4px"
                  label={capitalize(data.company_name)}
                  color="#626262"
                />
              ) : (
                <span>&nbsp;-</span>
              )}
            </div>
          </Grid>
          <Grid item>
            <div className={classes.Icon}>
              <MapPin size={16} className={classes.locationIcon} />
              {data.location ? (
                <Text
                  type="header4"
                  padding="1px"
                  label={capitalize(data.location.cityName)}
                  color="#626262"
                />
              ) : (
                <span>&nbsp;-</span>
              )}
            </div>
          </Grid>
        </Grid>
      </CardContent>

      <CardContent>
        <Grid
          container
          justify="flex-start"
          spacing={2}
          className={classes.ContentContainer}
        >
          <Grid item>
            <SocialHandle
              type="linkedin"
              width="20px"
              height="20px"
              url={data.linkedin ? data.linkedin : ""}
            />
          </Grid>
          <Grid item>
            <SocialHandle
              type="facebook"
              width="20px"
              height="20px"
              url={data.facebook ? data.facebook : ""}
            />
          </Grid>
          <Grid item>
            <SocialHandle
              type="twitter"
              width="20px"
              height="20px"
              url={data.twitter ? data.twitter : ""}
            />
          </Grid>

          <Grid item>
            <SocialHandle
              type="instagram"
              width="20px"
              height="20px"
              url={data.instagram ? data.instagram : ""}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
export default withStyles(styles)(AssociateCard);
