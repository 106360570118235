import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import SelectField from "../Common/Form/SelectField";
import { connect } from "react-redux";
import { getAllCompanies } from "../../Actions/companiesAction";
import { capitalize } from "../../Utils/StringFunctions";

const styles = {};

const CompanyDropDown = props => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    companyData
  } = props;
  const [companies, setCompanylist] = useState([]);
  const companyArr = [];
  const getSelectedCompany = company => {
    // Will return company which is not in companydata array
    company.map(company => {
      if (companyData && !companyData.includes(company._id)) {
        return companyArr.push({
          value: company._id,
          label: capitalize(company.company_name)
        });
      }
    });
    return companyArr ? companyArr : [];
  };

  const getAllCompany = companies => {
    //Will return all companies
    return companies
      ? companies.map(companies => {
          return {
            value: companies._id,
            label: companies.company_name
          };
        })
      : [];
  };

  useEffect(() => {
    props.getAllCompanies();
  }, []);

  useEffect(() => {
    setCompanylist(props.companies);
  }, [props.companies]);

  return (
    <SelectField
      value={values}
      name="company_id"
      id="company_id"
      onChange={setFieldValue}
      onBlur={setFieldTouched}
      error={errors ? errors.company_id : ""}
      touched={touched}
      options={
        companyData ? getSelectedCompany(companies) : getAllCompany(companies)
      }
      multi={false}
      placeholder="Add Company"
    />
  );
};

const mapStateToProps = state => {
  return {
    companies: state.company.allCompanies
  };
};

export default connect(
  mapStateToProps,
  { getAllCompanies }
)(withStyles(styles)(CompanyDropDown));
