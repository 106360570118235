import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  AppBar,
  Toolbar,
  Typography,
  CssBaseline
} from "@material-ui/core";
import bannerImage from "../../Assets/eventbanner.png";
import logo from "../../Assets/eventlogo.png";
import classNames from "classnames";
import { connect } from "react-redux";
import EventTabs from "./EventTabs";
import { MapPin } from "react-feather";
import { getEvent } from "../../Actions/eventActions";
import Text from "../../Components/Common/Text";
import { Circle } from "react-feather";
import { capitalize } from "../../Utils/StringFunctions";
import Settings from "../../Components/Common/Settings";
// import CloseIcon from "@material-ui/icons/Close";
import { spacecapitalize } from "../../Utils/StringFunctions";
import SocialHandle from "../../Components/Common/SocialHandle";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;
const moment = require("moment");
const styles = () => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    height: "-webkit-fill-available"
  },
  logo: {
    minWidth: "70%"
  },
  orangeAvatar: {
    width: "60%",
    height: 95,
    fontSize: "36px",
    position: "relative",
    top: "-30px",
    left: "10%",
    borderRadius: "4px",
    objectFit: "contain",
    backgroundColor: "#fff"
  },
  eventTitle: {
    margin: "11px 0px 0px -8px",
    paddingBottom: "8px"
  },
  eventbasicdetail: {
    backgroundColor: "#fff",
    position: "relative"
  },
  social: {
    height: "26px",
    width: "26px",
    marginRight: "14px",
    borderRadius: "50px"
  },
  socialSection: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "13px 10px 0px"
  },
  subFont: {
    fontSize: "14px",
    paddingLeft: "4px",
    color: "#222222"
  },
  locationIcon: {
    position: "relative",
    top: "3px",
    left: "-4px"
  },
  alignTitle: {
    display: "flex"
  },
  eventType: {
    paddingLeft: "2%",
    textTransform: "capitalize"
  },
  circleFill: {
    paddingLeft: "2%",
    display: "flex",
    marginTop: "5px"
  },
  locationPadding: {
    paddingLeft: "2%"
  },
  verticalIcon: {
    display: "flex",
    justifyContent: "flex-end",
    // position: "absolute",
    // top: "12%",
    // right: "2%",
    marginTop: "-5%"
  }
});

class ViewaEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.id = this.props.match.params.id;
  }

  componentDidMount() {
    this.props.getEvent(this.state.id);
  }

  handleChange = (event, value) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ value });
  };

  handleEditButton = () => {
    const event = this.props && this.props.event;
    this.props.history.push(`/events/edit/${event._id}`);
  };
  render() {
    const { classes, event } = this.props;
    const Banner = event.banner ? `${ASSET_URL}${event.banner}` : bannerImage;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.mainsection}>
          <main>
            <div
              style={{
                backgroundImage: `url(${Banner})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "340px"
              }}
            >
              <AppBar position="static" className={classes.appBar}>
                <Toolbar />
                <div className={classes.verticalIcon}>
                  <Settings editMethod={this.handleEditButton} />
                </div>
              </AppBar>
            </div>
            <div className={classNames(classes.layout, classes.cardGrid)}>
              <div className={classes.eventbasicdetail}>
                <Grid
                  container
                  item
                  lg={12}
                  xs={12}
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  <Grid item lg={2} xs={2}>
                    <div className={classes.logo}>
                      <img
                        className={classes.orangeAvatar}
                        src={
                          event.company
                            ? `${ASSET_URL}${event.company.logo}`
                            : logo
                        }
                        alt="logo"
                      />
                    </div>
                  </Grid>
                  <Grid item lg={7} xs={7}>
                    <div className={classes.eventTitle}>
                      <div className={classes.alignTitle}>
                        <Text
                          type="header3"
                          label={capitalize(event.event_name)}
                          color="#4D7CFE"
                          gutterBottom
                        />
                        <span className={classes.circleFill}>
                          <Circle fill="#CDCDCD " color="#CDCDCD" size={8} />
                        </span>
                        <span className={classes.eventType}>
                          <Text
                            type="header4"
                            label={spacecapitalize(event.event_type)}
                            color="#626262"
                            gutterBottom
                          />
                        </span>
                      </div>
                      <div>
                        <MapPin size={15} className={classes.locationIcon} />
                        <Text
                          type="caption"
                          label={event.location ? event.location.cityName : ""}
                          color="#626262"
                          gutterBottom
                        />
                      </div>
                      <Typography
                        className={classNames(classes.subFont)}
                        variant="subtitle1"
                        component="p"
                      >
                        <span>Start Date:</span>{" "}
                        {moment(event.start_date).format("DD/MM/YYYY")} |{" "}
                        <span>End Date:</span>{" "}
                        {moment(event.end_date).format("DD/MM/YYYY")}{" "}
                        <span style={{ paddingLeft: "2%" }}>
                          <Circle fill="#CDCDCD " color="#CDCDCD" size={8} />
                        </span>
                        <span className={classes.locationPadding}>
                          Location:{" "}
                        </span>
                        {capitalize(event.venue)},
                        {event.location ? event.location.cityName : ""}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3} lg={3} className={classes.socialSection}>
                    <SocialHandle
                      type="facebook"
                      width="25px"
                      height="25px"
                      url={event.facebook ? event.facebook : ""}
                    />
                  </Grid>
                </Grid>
              </div>
              <EventTabs
                company={this.state.company}
                id={this.state.id}
                data={event}
                history={this.props.history}
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

ViewaEvent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    event: state.events.event
  };
};

export default connect(
  mapStateToProps,
  { getEvent }
)(withStyles(styles)(ViewaEvent));
