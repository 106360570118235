import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { getCompanies } from "../../Actions/companiesAction";
import { connect } from "react-redux";
import { Table, TableHead, TableRow, TableBody } from "@material-ui/core";
import AddFormButton from "../../Components/Common/AddFormButton";
import SearchBar from "../../Components/Common/SearchBar";
import Cell from "../../Components/Common/Table/TableCell";
import Header from "../../Components/Common/Table/TableHeader";
import Pagination from "../../Components/Common/Table/TablePagination";
import SkeletonTable from "../../Components/Common/SkeletonTable";
import { Link } from "react-router-dom";
import EditButton from "../../Components/Common/EditButton";
import NoRecordaFoundTable from "../../Components/Common/NoRecordaFoundTable";
import NoDataFound from "../../Components/Common/NoDataFound.js";

const styles = () => ({
  subHeader: {
    display: "flex",
    alignItems: "center",
    margin: "30px 0px"
  },
  main: {
    background: "#fff",
    padding: "24px"
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE"
    }
  },
  link: {
    color: "#000",
    textDecoration: "none"
  },
  viewLink: {
    textDecoration: "none",
    color: "#626262"
  },
  maintext: {
    color: "lightgrey"
  },
  noRecordSearch: {
    height: "300px"
  }
});
class AdminCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      page: 0,
      rowsPerPage: 10,
      search: "",
      isSearch: false
    };
  }
  componentDidMount() {
    this.props.getCompanies();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.companies !== prevState.companies) {
      return { companies: nextProps.companies };
    } else return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // params.api.sizeColumnsToFit();
    params.api.showLoadingOverlay();
  };

  onBtExport() {
    var params = {
      name: true
    };
    this.gridApi.exportDataAsCsv(params);
  }
  // onChange = e => {
  //   this.setState(
  //     {
  //       searchValue: e.target.value
  //     },
  //     () => {
  //       this.gridApi.setQuickFilter(this.state.searchValue);
  //       // console.log("123 ", this.state.columnDefs);
  //     }
  //   );
  // };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };
  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <Header>Sr</Header>
          <Header>Company</Header>
          <Header>Events</Header>
          <Header>Applications</Header>
          <Header>Applicants</Header>
          <Header>Partners</Header>
          <Header>Associates</Header>
          <Header></Header>
        </TableRow>
      </TableHead>
    );
  };
  renderBody = () => {
    const { classes } = this.props;
    const { page, rowsPerPage, isSearch, search } = this.state;
    return (
      <TableBody>
        {this.state.companies &&
          this.state.companies.length <= 0 &&
          isSearch && (
            <NoRecordaFoundTable
              colSpan="7"
              height
              text={`No records found for your text "${search}"`}
            />
          )}
        {this.state.companies &&
          this.state.companies
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, id) => {
              return (
                <React.Fragment key>
                  <TableRow className={classes.root1}>
                    <Cell>{page * rowsPerPage + id + 1}</Cell>
                    <Cell>
                      <Link
                        key={id}
                        to={`companies/${item._id}`}
                        className={classes.viewLink}
                      >
                        {item.company_name}
                      </Link>
                    </Cell>
                    <Cell>{item.events}</Cell>
                    <Cell>{item.applications}</Cell>
                    <Cell>{item.applicant}</Cell>
                    <Cell>{item.partners}</Cell>
                    <Cell>{item.associate}</Cell>
                    <Cell>
                      <EditButton key={id} to={`company/${item._id}`} />
                    </Cell>
                  </TableRow>
                </React.Fragment>
              );
            })}
      </TableBody>
    );
  };

  searchCompany = (search, isSearch) => {
    this.setState({ isSearch: true, search });
    this.props.getCompanies(search, isSearch);
  };
  render() {
    const { classes } = this.props;
    const { loading, isSearch, companies } = this.state;
    const itemLength = this.state.companies ? this.state.companies.length : 0;

    if (!loading && companies && companies.length <= 0 && !isSearch) {
      return (
        <div style={{ textAlign: "center" }}>
          <NoDataFound />
          <AddFormButton type="Company" url="/company/add" />
        </div>
      );
    }
    return (
      <div className={classes.main}>
        <div style={{ marginBottom: "10px" }}>
          {/* <ScreenHeader type=" Companies" /> */}

          <div className={classes.subHeader}>
            <SearchBar type="Search Companies" onSearch={this.searchCompany} />
            {/* TODO IN VERSION-2 */}
            {/* <Button
              variant="outlined"
              color="primary"
              size="small"
              className={classes.button}
              onClick={this.onBtExport.bind(this)}
              style={{ marginRight: "10px" }}
            >
              Export
            </Button> */}
            <AddFormButton type="Company" url="/company/add" />
          </div>
        </div>

        {loading ? (
          Array.from(new Array(10)).map(id => {
            return <SkeletonTable key={id} />;
          })
        ) : (
          <div className={classes.root}>
            <div>
              <div className={classes.header}>
                <Table className={classes.table}>
                  {this.renderHead()}
                  {this.renderBody()}
                </Table>
              </div>
              <Pagination
                count={itemLength}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

AdminCompany.propTypes = {};

const mapStateToProps = state => {
  return {
    companies: state.company.companies,
    loading: state.company.loading
  };
};

export default connect(
  mapStateToProps,
  { getCompanies }
)(withStyles(styles)(AdminCompany));
