import React from "react";
import { DateTimePicker } from "material-ui-pickers";

class DateTimePickerField extends React.Component {
  handleChange = value => {
    this.props.onChange(this.props.name, value);
  };

  render() {
    const { ...other } = this.props;
    return (
      <DateTimePicker
        {...other}
        name={this.props.name}
        keyboard
        label={this.props.label}
        disableOpenOnEnter
        onChange={this.handleChange}
        value={this.props.value}
        animateYearScrolling={false}
        fullWidth
        className={this.props.className}
        // style={{ marginBottom: "0", marginTop: "0px !important" }}
      />
    );
  }
}

export default DateTimePickerField;
