import React from "react";
import PropTypes from "prop-types";
import { FormikRadioGroupField } from "formik-material-fields";
import { withStyles } from "@material-ui/core/styles";
import { ErrorMessage } from "formik";

const styles = () => {
  return {
    mainLabel: {
      fontSize: "13px",
      fontWeight: 400,
      color: "#110F24",
      margin: "0px"
    },
    error: {
      color: "#f53b57",
      fontSize: "0.75rem",
      fontWeight: "400",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ],
      textAlign: "left",
      lineHeight: "1em",
      marginTop: "7px",
      padding: "0px 0px 0px 5px"
    }
  };
};

const RadioField = ({ classes, name, label, options, ...rest }) => {
  return (
    <React.Fragment>
      <FormikRadioGroupField
        className={classes.mainLabel}
        name={name}
        label={label}
        margin="normal"
        options={options}
        row
        fullWidth
        {...rest}
      />
      <div className={classes.error}>
        <ErrorMessage name={name} component="div" />
      </div>
    </React.Fragment>
  );
};

RadioField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired
};

export default withStyles(styles)(RadioField);
