import jwt_decode from "jwt-decode";
import permissions from "./Permissions";
const checkPermission = permission => {
  let role = {};
  if (localStorage.jwtToken) {
    const decoded = jwt_decode(localStorage.jwtToken);
    role = decoded.role;
    if (role === "admin") return true;
    return !permissions.includes(permission);
  }
};

export default checkPermission;

export const getRole = () => {
  let role = "";
  if (localStorage.jwtToken) {
    const decoded = jwt_decode(localStorage.jwtToken);
    role = decoded.role;
  }
  return role;
};
