import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { logoutUser } from "./../../Actions/authAction";
const styles = () => ({
  appBar: {
    position: "relative"
  },
  toolbarTitle: {
    flex: 1
  },

  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9,
    backgroundSize: "30%"
  }
});

function Authentication(props) {
  const { classes } = props;
  const logout = () => {
    props.logoutUser();
  };
  return (
    <AppBar position="static" color="default" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          className={classes.toolbarTitle}
        >
          HAAS
        </Typography>
        <Link to="/challenges">
          <Button>Challenges</Button>
        </Link>
        <Button>About</Button>
        <Button>Contact</Button>
        {!props.auth.isAuthenticated ? (
          <div>
            <Link to="/login">
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: "10px" }}
              >
                Login
              </Button>
            </Link>
            <Link to="/register">
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: "10px" }}
              >
                Signup
              </Button>
            </Link>
          </div>
        ) : (
          <Link to="">
            <Button
              color="primary"
              variant="contained"
              style={{ marginLeft: "10px" }}
              onClick={logout}
            >
              LOGOUT
            </Button>
          </Link>
        )}
      </Toolbar>
    </AppBar>
  );
}

Authentication.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    auth: state.auth,
    profile: state.profile
  };
};
export default connect(
  mapStateToProps,
  { logoutUser }
)(withStyles(styles)(Authentication));
