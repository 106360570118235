import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import RenderHtml from "../../Components/Common/RenderHtml";

const $quoteSym = " ";

const styles = theme => ({
  eventtitle: {
    marginBottom: 14,
    fontSize: 18
  },
  title: {
    marginBottom: 5,
    fontSize: "16px"
  },
  prizetitle: {
    fontSize: 15,
    margin: "2px 9px"
  },
  prize: {
    fontSize: 15,
    margin: "2px 9px"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: "#ca8301"
  },
  mainOverview: {
    padding: "20px 40px"
  },
  subHeader: {
    color: "#000",
    // fontWeight: 600,
    fontSize: "16px",
    paddingBottom: "5px",
    position: "relative",
    textTransform: "capitalize",
    "&:after": {
      top: "24px",
      left: "0px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute"
    }
  },
  aboutCompanyCont: {
    fontSize: "14px",
    color: "#626262",
    padding: "20px 0px"
  },
  contactInner: {
    fontWeight: "normal",
    fontSize: 14,
    color: "#626262",
    padding: "20px 0px"
  },
  themeButton: {
    margin: "20px 10px 0px 0px",
    borderRadius: "20px",
    backgroundColor: "#efefef",
    color: "#626262",
    cursor: "default",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#efefef",
      color: "#626262"
    }
  }
});

function AboutCompany(props) {
  let { classes, company } = props;
  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.mainOverview}>
        <div className={classes.aboutCompany}>
          <span className={classes.subHeader}>
            About {company.company_name}
          </span>
          <RenderHtml data={company ? company.description : ""} />
        </div>
        <div className={classes.companyContactDetails}>
          <span className={classes.subHeader}>Contact Details</span>
          <Typography
            className={classes.contactInner}
            variant="subtitle1"
            component="p"
          >
            Employee Strength: {company.employee_strength} <br />
            Email: {company.email} | Website:{" "}
            <a target="#" href={company.website}>
              {company.website}
            </a>
          </Typography>
        </div>
      </main>
    </React.Fragment>
  );
}
export default connect()(withStyles(styles)(AboutCompany));
