import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
const styles = () => {
  return {
    root1: {
      "&:nth-child(even)": {
        backgroundColor: "#FCFDFE"
      }
    },
    viewLink: {
      textDecoration: "none",
      textTransform: "capitalize",
      color: "#000"
    },
    widthfix: {
      padding: "8px 40px 8px 16px !important"
    }
  };
};
const theme = createMuiTheme({
  overrides: {
    MuiTablePagination: {
      caption: {
        color: "grey"
      },
      select: {
        color: "grey"
      },
      selectIcon: {
        color: "grey"
      }
    }
  }
});
const Pagination = props => {
  const { rowsPerPage, page, count } = props;
  return (
    <div>
      <ThemeProvider theme={theme}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "previous page"
          }}
          nextIconButtonProps={{
            "aria-label": "next page"
          }}
          onChangePage={props.onChangePage}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
        />
      </ThemeProvider>
    </div>
  );
};
export default withStyles(styles)(Pagination);
