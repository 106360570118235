import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import classNames from "classnames";
import { updateEventStatus } from "../../Actions/eventActions";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import { capitalize } from "../../Utils/StringFunctions";
import EventType from "./Types/EventTypes";

const styles = theme => {
  return {
    published: {
      backgroundColor: "#fff",
      color: "#11A711 ",
      border: "1px solid #11A711 "
    },
    completed: {
      backgroundColor: "#fff",
      color: "#626262 ",
      border: "1px solid #626262 "
    },
    cancelled: {
      backgroundColor: "#fff",
      color: "#F21634 ",
      border: "1px solid #F21634 "
    },
    draft: {
      backgroundColor: "#fff",
      color: "#FFC400 ",
      border: "1px solid #FFC400 "
    },
    onhold: {
      backgroundColor: "#fff",
      color: "#FF6F00 ",
      border: "1px solid #FF6F00 "
    },
    formControl: {
      minWidth: 100,
      textAlign: "center"
    },
    statusDropdown: {
      borderRadius: `${theme.shape.borderRadius * 4}px`,
      height: "25px",
      padding: "9px 10px 10px 10px",
      fontSize: "10px",
      fontWeight: "500"
    },
    icon: {
      color: "#626262",
      right: "10px",
      top: "1px"
    },
    completedStatus: {
      height: "25px",
      padding: "4px 17px 13px 1px",
      fontSize: "10px",
      fontWeight: "500",
      backgroundColor: "#fff",
      color: "#626262 ",
      border: "1px solid #626262 ",
      borderRadius: `${theme.shape.borderRadius * 4}px`
    }
  };
};
const EventStatus = props => {
  const [selected, setSelected] = useState(props.selectedItem || "published");
  const { classes, id, selectedItem } = props;
  const callStatusChangeAPI = el => {
    props.updateEventStatus(id, {
      event_status: el.target.value
    });
    setSelected(el.target.value);
  };
  return (
    <React.Fragment>
      <FormControl variant="filled" className={classes.formControl}>
        {selectedItem === "completed" ? (
          <div className={classes.completedStatus}>Completed</div>
        ) : (
          <Select
            className={classNames(classes[selected], classes.statusDropdown)}
            value={selected}
            variant="standard"
            onChange={callStatusChangeAPI}
            name="name"
            disableUnderline
            classes={{
              icon: classes.icon
            }}
          >
            {EventType.map(element => {
              return (
                <MenuItem value={element} key={element}>
                  {capitalize(element)}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </FormControl>
    </React.Fragment>
  );
};
const mapStateToProps = () => {
  return {};
};
export default connect(
  mapStateToProps,
  { updateEventStatus }
)(withStyles(styles)(EventStatus));
