import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
/*
 Example to use this component
<Text type="header1" label="text to be displayed"/>
<Text variant="subheader" label="text to be displayed"/>
*/

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: "20px",
        fontWeight: 400,
        fontFamily: "Sans-serif",
        color: props => (props.color ? props.color : "#222222")
      },
      h2: {
        fontSize: "18px",
        fontWeight: 400,
        fontFamily: "Sans-serif",
        color: props => (props.color ? props.color : "#222222")
      },
      h3: {
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Sans-serif",
        color: props => (props.color ? props.color : "#222222")
      },

      h4: {
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Sans-serif",
        padding: props => (props.padding ? props.padding : "0px"),
        color: props => (props.color ? props.color : "#222222")
      },
      caption: {
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: "Sans-serif",
        color: props => (props.color ? props.color : "#222222")
      }
    }
  }
});

function Text(props) {
  const { type, variant } = props;
  const getVariant = type => {
    switch (type) {
      case "header1":
        return "h1";
      case "header2":
        return "h2";
      case "header3":
        return "h3";
      case "header4":
        return "h4";
      case "caption":
        return "caption";
      default:
        break;
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Typography variant={variant ? variant : getVariant(type)} {...props}>
        {props.label}
      </Typography>
    </ThemeProvider>
  );
}

export default Text;
