import axios from "axios";
import {
  GET_ASSOCIATES,
  ASSOCIATES_LOADING,
  ASSOCIATE_ERRORS,
  ADD_ASSOCIATE,
  GET_ASSOCIATE,
  ADD_ASSOCIATE_LOADING,
  ADD_ASSOCIATE_ERROR,
  GET_ASSOCIATES_SKILLS,
  GET_ASSOCIATES_SKILLS_LOADING,
  GET_ALL_ASSOCIATE,
  EDIT_ASSOCIATE
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";

export const getAssociates = (id, search, event_id) => dispatch => {
  dispatch(setAssociatesLoading());
  let url = "";
  if (id) {
    url = `/api/associate?companyid=${id}`;
  } else if (search) {
    url = `/api/associate?q=${search}`;
  } else if (event_id) {
    url = `/api/associate?eventid=${event_id}`;
  } else {
    url = `/api/associate`;
  }
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_ASSOCIATES,
        payload: res.data
      });
    })
    .catch(() =>
      dispatch({
        type: GET_ASSOCIATES,
        payload: {}
      })
    );
};

export const getAssociate = id => dispatch => {
  axios
    .get(`/api/associate/${id}`)
    .then(res => {
      dispatch({
        type: GET_ASSOCIATE,
        payload: res.data.data
      });
    })
    .catch(err =>
      dispatch({
        type: ASSOCIATE_ERRORS,
        payload: err.response.data
      })
    );
};
export const getAllAssociate = () => dispatch => {
  axios
    .get(`/api/associate`)
    .then(res => {
      dispatch({
        type: GET_ALL_ASSOCIATE,
        payload: res.data.data
      });
    })
    .catch(err =>
      dispatch({
        type: ASSOCIATE_ERRORS,
        payload: err.response && err.response.data
      })
    );
};

export const addAssociate = (userData, history) => dispatch => {
  dispatch(addingAssociateLoader());
  axios
    .post("/api/associate", userData)
    .then(result => {
      dispatch({
        type: ADD_ASSOCIATE,
        payload: result.data
      });
      dispatch(showSuccess("Associate"));
      if (history) {
        history.push({
          pathname: "/associates"
        });
        history.push("/associates");
      }
    })
    .catch(err => {
      dispatch(showError("There was some error while creating an associate"));
      dispatch({
        type: ADD_ASSOCIATE_ERROR,
        payload: err.response && err.response.data
      });
    });
};

export const editAssociate = (id, userData, history) => dispatch => {
  axios
    .put(`/api/associate/update/${id}`, userData)
    .then(result => {
      dispatch({
        type: EDIT_ASSOCIATE,
        payload: result.data
      });
      dispatch(showSuccess("", "Associate updated successfully"));
      history.push("/associates");
    })
    .catch(err => {
      dispatch({ type: ASSOCIATE_ERRORS, payload: err.response.data });
      dispatch(showError("There was some error while updating an associate"));
    });
};

export const getAssociateSkills = () => dispatch => {
  dispatch(associatesSkillsLoading());
  axios
    .get("/api/associateskills")
    .then(res => {
      dispatch({
        type: GET_ASSOCIATES_SKILLS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ASSOCIATE_ERRORS,
        payload: err.response && err.response.data
      })
    );
};
// Profile Loading
export const setAssociatesLoading = () => {
  return {
    type: ASSOCIATES_LOADING
  };
};
export const addingAssociateLoader = () => {
  return {
    type: ADD_ASSOCIATE_LOADING
  };
};
export const associatesSkillsLoading = () => {
  return {
    type: GET_ASSOCIATES_SKILLS_LOADING
  };
};
