import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Table, TableHead, TableRow, TableBody, Grid } from "@material-ui/core";
import Cell from "../../Components/Common/Table/TableCell";
import Header from "../../Components/Common/Table/TableHeader";
import Tooltip from "../../Components/Common/NameToolTip";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";
import checkPermission from "../../Utils/CheckPermissions";

const styles = () => ({
  eventName: {
    color: "black"
  },
  table: {
    boxShadow: "0 5px 10px rgba(0,0,0,0.04), 0 5px 5px rgba(0,0,0,0.05)",
    border: "1px solid #efefef",
    borderRadius: "1px",
    minHeight: "340px"
  },
  mapPin: { paddingRight: "2px" },
  image: {
    height: "290px"
  },
  ImageContainer: {
    height: "269px"
  },
  text: {
    color: "grey"
  }
});
const renderHead = () => {
  return (
    <TableHead>
      <Header width="60%">Application Details</Header>
      <Header width="30%">SPOC</Header>
      <Header>Status</Header>
    </TableHead>
  );
};
const renderBody = props => {
  const { classes, data } = props;
  if (data && data.length <= 0) {
    return (
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={classes.ImageContainer}
      >
        <Grid item className={classes.text}>
          No Data Found
        </Grid>
      </Grid>
    );
  }
  return (
    <TableBody>
      {data &&
        data.length > 0 &&
        data.slice(0, 5).map((item, id) => {
          return (
            <React.Fragment key={id}>
              <TableRow>
                <Cell>
                  <span className={classes.eventName}>
                    <Tooltip
                      amount="50"
                      text={capitalize(item.team_name ? item.team_name : "-")}
                    />
                  </span>
                  <br />
                  {checkPermission("COMPANY_VIEW") &&
                  item.event &&
                  item.event.company
                    ? item.event.company.company_name
                    : ""}
                  {checkPermission("COMPANY_VIEW") && " - "}

                  <span>
                    <Tooltip
                      amount="20"
                      text={capitalize(item.event ? item.event.event_name : "")}
                    />
                  </span>
                </Cell>
                <Cell>
                  {item.createdBy ? item.createdBy.first_name : "-"}{" "}
                  {item.createdBy ? item.createdBy.last_name : "-"}(
                  {item.active_members ? item.active_members.length : ""})
                  <br />
                  {item.createdBy ? item.createdBy.contact_number : "-"}
                </Cell>
                <Cell>{item.status ? spacecapitalize(item.status) : "-"}</Cell>
              </TableRow>
            </React.Fragment>
          );
        })}
    </TableBody>
  );
};
function DashboardApplications(props) {
  const { classes } = props;
  return (
    <React.Fragment>
      <Table className={classes.table}>
        {renderHead()}
        {renderBody(props)}
      </Table>
    </React.Fragment>
  );
}

export default withStyles(styles)(DashboardApplications);
