// these are the types we get from backend
export const SHORTLISTED = "shortlisted";
export const ONBOARDED = "onboarded";
export const REJECTED = "rejected";
export const ONHOLD = "on_hold";
export const REVOKED = "revoked";
export const PARTICIPATED = "participated";
export const WON = "won";
export const DISQUALIFIED = "disqualified";
export const REGISTERED = "registered";

//these are the types we show on frontend
export const view = {
  SHORTLISTED: "shortlisted",
  ONBOARDED: "onboarded",
  REJECTED: "rejected",
  ONHOLD: "on_hold",
  REVOKED: "revoked",
  PARTICIPATED: "participated",
  WON: "won",
  DISQUALIFIED: "disqualified",
  REGISTERED: "registered"
};

export const showStatus = status => {
  switch (status) {
    case SHORTLISTED:
      return view.SHORTLISTED;

    default:
      break;
  }
};
