import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const useStyles = makeStyles({
  list: {
    width: 660,
    backgroundColor: "#F9F9F9",
    // padding: "69px 24px 24px",
    padding: props =>
      props.padding === "yes" ? "69px 24px 24px" : "69px 24px 24px"
  },
  fullList: {
    width: "auto"
  },
  paper: {
    top: "48px"
  }
});
const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#F9F9F9"
      }
    }
  }
});

function SwipeableTemporaryDrawer(props) {
  const classes = useStyles();
  const sideList = () => (
    <div className={classes.list} padding="yes" role="presentation">
      {props.children}
    </div>
  );
  return (
    <ThemeProvider theme={theme}>
      <SwipeableDrawer
        anchor="right"
        open={props.openModal}
        className={classes.mainDrawer}
        onClose={() => {
          props.toggleDrawer(false);
        }}
        onOpen={() => {
          props.toggleDrawer(true);
        }}
      >
        {sideList()}
      </SwipeableDrawer>
    </ThemeProvider>
  );
}
export default SwipeableTemporaryDrawer;
