import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import SelectField from "../Common/Form/SelectField";
import { getCities } from "./../../Actions/CityActions";
import { connect } from "react-redux";

const styles = {};

const CityDropDown = props => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    mandatoryField
  } = props;
  const [citylist, setCitylist] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const getAllCities = citylist => {
    return citylist
      ? citylist.map(cityList => {
          return {
            value: cityList._id,
            label: cityList.cityName
          };
        })
      : [];
  };

  useEffect(() => {
    props.getCities();
  }, []);

  useEffect(() => {
    setCitylist(props.citylist);
  }, [props.citylist]);

  useEffect(() => {
    const newselectedValue = [];
    citylist &&
      citylist.map(el => {
        if (el.cityName === values) {
          newselectedValue.push({
            value: el._id,
            label: el.cityName
          });
        }
      });

    setSelectedValue(newselectedValue[0]);
  }, [values]);

  useEffect(() => {
    if (values) {
      const newselectedValue = [];
      citylist &&
        citylist.map(el => {
          if (el.cityName === values) {
            newselectedValue.push({
              value: el._id,
              label: el.cityName
            });
          }
        });
      setSelectedValue(newselectedValue[0]);
      setFieldValue("selectedLocationObj", newselectedValue[0]);
    }
    setCitylist(citylist);
  }, [citylist]);

  const changeDropDown = (name, value) => {
    setSelectedValue(value);
    setFieldValue(name, value);
    setFieldValue("selectedLocationObj", value);
  };

  return (
    <SelectField
      value={selectedValue}
      name="location"
      id="location"
      label="Location"
      onChange={changeDropDown}
      onBlur={setFieldTouched}
      error={errors.citylist}
      touched={touched}
      options={getAllCities(citylist)}
      multi={false}
      mandatoryField={mandatoryField ? mandatoryField : ""}
    />
  );
};

const mapStateToProps = state => {
  return {
    citylist: state.citylist.citylist
  };
};

export default connect(
  mapStateToProps,
  { getCities }
)(withStyles(styles)(CityDropDown));
