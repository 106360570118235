import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { MapPin, Calendar, Users } from "react-feather";
import { connect } from "react-redux";
import { getEvent } from "../../Actions/eventActions";
import UserNavBar from "../Landing/userNavBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import bannerImage from "./../../Assets/banner.jpg";
import Data from "./../../Assets/Artwork/Data.svg";
import Level1 from "./../../Assets/Artwork/Level1.svg";
import Level2 from "./../../Assets/Artwork/Level2.svg";
import Level3 from "./../../Assets/Artwork/Level3.svg";
import Level4 from "./../../Assets/Artwork/Level4.svg";
import Description from "./../../Assets/Artwork/Description.svg";
import persona from "./../../Assets/Artwork/persona.png";
import companyLogo from "./../../Assets/company_logo.png";
import AI from "./../../Assets/Artwork/AI.svg";
import Global from "./../../Assets/Artwork/Global.svg";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

const styles = theme => ({
  "@global": {
    body: {
      backgroundColor: "#fff"
    }
  },
  appBar: {
    position: "relative"
  },
  toolbarTitle: {
    flex: 1
  },
  layout: {
    width: "auto",
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 1200,
      marginLeft: "auto",
      marginRight: "auto"
    },
    paddingLeft: 0,
    paddingRight: 0
  },
  heroContent: {
    background: "url(" + bannerImage + ") no-repeat",
    // backgroundColor: "#2d3034",
    backgroundSize: "cover",
    minHeight: "500px",
    color: "white",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },

  eventInfo: {
    paddingTop: "50px",
    paddingBottom: "50px",
    backgroundColor: "#f9fafb"
  },
  infoTitle: {
    fontWeight: "normal",
    fontSize: "24px",
    color: "#3e4145",
    lineHeight: 0.9
  },
  infoBlockTitle: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #ccc"
  },
  infoBlock: {
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  levelBlock: {
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  descriptionBlock: {
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "flex",
    flexDirection: "column"
  },
  infoBlockContent: {
    color: "#6c7a89",
    paddingTop: "20px",
    fontSize: "18px",
    fontFamily: "Rubik"
  },
  sectionTitle: {
    textAlign: "center",
    fontSize: "34px",
    fontFamily: "Rubik",
    marginBottom: "30px",
    color: "#3e4145"
  },
  sectionTitleLevel: {
    fontSize: "34px",
    fontFamily: "Rubik",
    marginBottom: "30px",
    color: "#3e4145"
  },
  sectionTitleDescription: {
    fontSize: "34px",
    fontFamily: "Rubik",
    marginBottom: "20px",
    color: "#3e4145"
  },
  cardTitle: {
    textAlign: "center"
  },
  cardTitleAssociate: {
    color: "white"
  },
  card: {
    boxShadow: "0 0 32px 0 rgba(12,12,13,.1), 0 2px 16px 0 rgba(12,12,13,.05);"
  },
  cardAssociate: {
    backgroundColor: "transparent",
    color: "white"
  },
  cardDescription: {
    backgroundColor: "transparent"
  },
  media: {
    padding: "20px 20px 0 20px"
  },
  mediaDescription: {
    backgroundColor: "white",
    padding: "30px"
  },
  cardSponsors: {
    backgroundColor: "transparent",
    marginBottom: "30px",
    padding: "15px"
  },
  mediaAssociate: {
    padding: 0,
    borderRadius: 5
  },
  themeInfo: {
    backgroundColor: "#f9fafb",
    paddingTop: "80px",
    marginTop: "50px",
    paddingBottom: "80px"
  },
  levelInfo: {
    paddingTop: "80px",
    paddingBottom: "80px"
  },
  descriptionInfo: {
    paddingTop: "80px",
    paddingBottom: "80px"
  },
  associateInfo: {
    backgroundColor: "#2d3034",
    paddingTop: "80px",
    paddingBottom: "80px"
  },
  partnersInfo: {
    paddingTop: "80px",
    paddingBottom: "80px"
  },
  cardLevel: {
    marginBottom: "30px",
    boxShadow: "0 0 32px 0 rgba(12,12,13,.1), 0 2px 16px 0 rgba(12,12,13,.05);"
  }
});

class EventPage extends React.Component {
  state = {
    value: 0,
    event: {},
    open: false
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.getEvent(id);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.event !== prevState.event) {
      return { events: nextProps.event };
    } else return null;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event) {
      this.setState({
        event: this.props.event
      });
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              className={classes.toolbarTitle}
            >
              HAAS
            </Typography>
            <Link to="/challenges">
              <Button>Challenges</Button>
            </Link>
            <Button>About</Button>
            <Button>Contact</Button>
            <Button
              color="primary"
              variant="contained"
              style={{ marginLeft: "10px" }}
            >
              Login
            </Button>
          </Toolbar>
        </AppBar>
        <main>
          {/* Hero unit */}
          <div>
            <header className="header-main dark">
              <UserNavBar />
            </header>
            <div className={classes.heroContent}>
              <Typography
                variant="h3"
                component="h2"
                color="inherit"
                gutterBottom
                style={{ fontFamily: "Rubik", fontWeight: 100 }}
              >
                DevX Hackathon
              </Typography>
              <Typography
                variant="p"
                component="p"
                color="inherit"
                gutterBottom
                style={{
                  width: "550px",
                  textAlign: "center",
                  marginBottom: "20px"
                }}
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Corrupti harum temporibus beatae veritatis minus quae quibusdam
                praese
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                style={{ color: "white" }}
              >
                Register
              </Button>
            </div>
          </div>
          <div className={classes.eventInfo}>
            <div className={classNames(classes.layout)}>
              <Grid container lg={12} xs={12}>
                <Grid lg={4} xs={12} className={classes.infoBlock}>
                  <div className={classes.infoBlockTitle}>
                    <h3 className={classes.infoTitle}>Where?</h3>
                    <MapPin size={24} color="#3e4145" />
                  </div>
                  <div className={classes.infoBlockContent}>
                    <p>DevX</p>
                    <p>The First Ahmedabad</p>
                  </div>
                </Grid>

                <Grid lg={4} xs={12} className={classes.infoBlock}>
                  <div className={classes.infoBlockTitle}>
                    <h3 className={classes.infoTitle}>When?</h3>
                    <Calendar size={24} color="#3e4145" />
                  </div>
                  <div className={classes.infoBlockContent}>
                    <p>Saturday to Sunday</p>
                    <p>14 April to 15 April 2019</p>
                  </div>
                </Grid>

                <Grid lg={4} xs={12} className={classes.infoBlock}>
                  <div className={classes.infoBlockTitle}>
                    <h3 className={classes.infoTitle}>Who?</h3>
                    <Users size={24} color="#3e4145" />
                  </div>
                  <div className={classes.infoBlockContent}>
                    <p>300 Applicnats / 20 Mentors</p>
                    <p>40 Partners</p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={classes.descriptionInfo}>
            <div className={classNames(classes.layout)}>
              <Grid container lg={12} xs={12}>
                <Grid item lg={6} xs={6} className={classes.descriptionBlock}>
                  <Card className={classes.cardDescription}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      image={Description}
                      title="Contemplative Reptile"
                      className={classes.mediaDescription}
                    />
                  </Card>
                </Grid>

                <Grid item lg={6} xs={6} className={classes.descriptionBlock}>
                  <Typography
                    component="h3"
                    variant="h3"
                    className={classes.sectionTitleDescription}
                  >
                    Description
                  </Typography>
                  <Typography
                    variant="p"
                    color="textSecondary"
                    component="p"
                    style={{
                      fontFamily: "Lato",
                      fontSize: "16px"
                    }}
                    gutterBottom
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maxime fugiat, dolores enim suscipit dolor similique optio
                    dolorem quas deleniti assumenda velit totam magnam impedit
                    amet. Commodi nam illum quis voluptate.
                  </Typography>
                  <Typography
                    variant="p"
                    color="textSecondary"
                    component="p"
                    style={{ fontFamily: "Lato", fontSize: "16px" }}
                    gutterBottom
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maxime fugiat, dolores enim suscipit dolor similique optio
                    dolorem quas deleniti assumenda velit totam magnam impedit
                    amet. Commodi nam illum quis voluptate.
                  </Typography>
                  <Typography
                    variant="p"
                    color="textSecondary"
                    component="p"
                    style={{ fontFamily: "Lato", fontSize: "16px" }}
                    gutterBottom
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maxime fugiat, dolores enim suscipit dolor similique optio
                    dolorem quas deleniti assumenda velit totam magnam impedit
                    amet. Commodi nam illum quis voluptate.
                  </Typography>
                  <Typography
                    variant="p"
                    color="textSecondary"
                    component="p"
                    style={{ fontFamily: "Lato", fontSize: "16px" }}
                    gutterBottom
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maxime fugiat, dolores enim suscipit dolor similique optio
                    dolorem quas deleniti assumenda velit totam magnam impedit
                    amet. Commodi nam illum quis voluptate.
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={classes.themeInfo}>
            <div className={classNames(classes.layout)}>
              <Typography
                component="h3"
                variant="h3"
                className={classes.sectionTitle}
              >
                Themes
              </Typography>
              <Grid container lg={12} xs={12}>
                <Grid lg={4} xs={12} className={classes.infoBlock}>
                  <Card className={classes.card}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="240"
                      image={AI}
                      title="Contemplative Reptile"
                      className={classes.media}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ fontFamily: "Rubik" }}
                        className={classes.cardTitle}
                      >
                        Artificial Intelligence
                      </Typography>
                      <Typography
                        variant="p"
                        color="textSecondary"
                        component="p"
                        style={{ fontFamily: "Lato" }}
                        className={classes.cardTitle}
                        gutterBottom
                      >
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Sint corrupti illo voluptas deserunt mollitia nam
                        commodi. Esse, obcaecati dolorum dicta nobis adipisci
                        qui pariatur sequi illo et rerum alias corporis.
                      </Typography>
                      <Typography
                        variant="p"
                        color="textSecondary"
                        component="p"
                        style={{ fontFamily: "Lato" }}
                        className={classes.cardTitle}
                      >
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Sint corrupti illo voluptas deserunt mollitia nam
                        commodi. Esse, obcaecati dolorum dicta nobis adipisci
                        qui pariatur sequi illo et rerum alias corporis.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid lg={4} xs={12} className={classes.infoBlock}>
                  <Card className={classes.card}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="240"
                      image={Data}
                      title="Contemplative Reptile"
                      className={classes.media}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ fontFamily: "Rubik" }}
                        className={classes.cardTitle}
                      >
                        Data Security
                      </Typography>
                      <Typography
                        variant="p"
                        color="textSecondary"
                        component="p"
                        style={{ fontFamily: "Lato" }}
                        className={classes.cardTitle}
                      >
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Sint corrupti illo voluptas deserunt mollitia nam
                        commodi. Esse, obcaecati dolorum dicta nobis adipisci
                        qui pariatur sequi illo et rerum alias corporis.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid lg={4} xs={12} className={classes.infoBlock}>
                  <Card className={classes.card}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="240"
                      image={Global}
                      title="Contemplative Reptile"
                      className={classes.media}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ fontFamily: "Rubik" }}
                        className={classes.cardTitle}
                      >
                        Global Climate
                      </Typography>
                      <Typography
                        variant="p"
                        color="textSecondary"
                        component="p"
                        style={{ fontFamily: "Lato" }}
                        className={classes.cardTitle}
                      >
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Sint corrupti illo voluptas deserunt mollitia nam
                        commodi. Esse, obcaecati dolorum dicta nobis adipisci
                        qui pariatur sequi illo et rerum alias corporis.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={classes.levelInfo}>
            <div className={classNames(classes.layout)}>
              <Grid container lg={12} xs={12}>
                <Grid item lg={3} xs={6} className={classes.infoBlock}>
                  <Card className={classes.cardLevel}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="100"
                      image={Level1}
                      title="Contemplative Reptile"
                      className={classes.media}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ fontFamily: "Rubik" }}
                        className={classes.cardTitle}
                      >
                        Level 1
                      </Typography>
                      <Typography
                        variant="p"
                        color="textSecondary"
                        component="p"
                        style={{ fontFamily: "Lato" }}
                        className={classes.cardTitle}
                      >
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit.
                      </Typography>
                    </CardContent>
                  </Card>

                  <Card className={classes.cardLevel}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="100"
                      image={Level3}
                      title="Contemplative Reptile"
                      className={classes.media}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ fontFamily: "Rubik" }}
                        className={classes.cardTitle}
                      >
                        Level 3
                      </Typography>
                      <Typography
                        variant="p"
                        color="textSecondary"
                        component="p"
                        style={{ fontFamily: "Lato" }}
                        className={classes.cardTitle}
                      >
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} xs={6} className={classes.infoBlock}>
                  <Card className={classes.cardLevel}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="100"
                      image={Level2}
                      title="Contemplative Reptile"
                      className={classes.media}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ fontFamily: "Rubik" }}
                        className={classes.cardTitle}
                      >
                        Level 2
                      </Typography>
                      <Typography
                        variant="p"
                        color="textSecondary"
                        component="p"
                        style={{ fontFamily: "Lato" }}
                        className={classes.cardTitle}
                      >
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit.
                      </Typography>
                    </CardContent>
                  </Card>

                  <Card className={classes.cardLevel}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="100"
                      image={Level4}
                      title="Contemplative Reptile"
                      className={classes.media}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ fontFamily: "Rubik" }}
                        className={classes.cardTitle}
                      >
                        Level 4
                      </Typography>
                      <Typography
                        variant="p"
                        color="textSecondary"
                        component="p"
                        style={{ fontFamily: "Lato" }}
                        className={classes.cardTitle}
                      >
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={6} xs={6} className={classes.levelBlock}>
                  <Typography
                    component="h3"
                    variant="h3"
                    className={classes.sectionTitleLevel}
                  >
                    Level Information
                  </Typography>
                  <Typography
                    variant="p"
                    color="textSecondary"
                    component="p"
                    style={{
                      fontFamily: "Lato",
                      fontSize: "16px",
                      marginBottom: "20px"
                    }}
                    gutterBottom
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maxime fugiat, dolores enim suscipit dolor similique optio
                    dolorem quas deleniti assumenda velit totam magnam impedit
                    amet. Commodi nam illum quis voluptate.
                  </Typography>
                  <Typography
                    variant="p"
                    color="textSecondary"
                    component="p"
                    style={{ fontFamily: "Lato", fontSize: "16px" }}
                    gutterBottom
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maxime fugiat, dolores enim suscipit dolor similique optio
                    dolorem quas deleniti assumenda velit totam magnam impedit
                    amet. Commodi nam illum quis voluptate.
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={classes.associateInfo}>
            <div className={classNames(classes.layout)}>
              <Typography
                component="h3"
                variant="h3"
                className={classes.sectionTitle}
                style={{ color: "white", marginBottom: "40px" }}
              >
                Assocoiates
              </Typography>
              <Grid container lg={12} xs={12}>
                <Grid item lg={3} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardAssociate}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="340"
                      image={persona}
                      title="Contemplative Reptile"
                      className={classes.mediaAssociate}
                    />
                    <CardContent style={{ paddingLeft: 0 }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ fontFamily: "Rubik", marginBottom: 0 }}
                        className={classes.cardTitleAssociate}
                      >
                        Parth Shah
                      </Typography>
                      <Typography
                        variant="p"
                        component="p"
                        style={{
                          fontFamily: "Rubik",
                          color: "#2ecc71",
                          fontStyle: "italic"
                        }}
                        className={classes.cardTitleAssociate}
                      >
                        Founder, DevX Innovations
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardAssociate}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="340"
                      image={persona}
                      title="Contemplative Reptile"
                      className={classes.mediaAssociate}
                    />
                    <CardContent style={{ paddingLeft: 0 }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ fontFamily: "Rubik", marginBottom: 0 }}
                        className={classes.cardTitleAssociate}
                      >
                        Parth Shah
                      </Typography>
                      <Typography
                        variant="p"
                        component="p"
                        style={{
                          fontFamily: "Rubik",
                          color: "#2ecc71",
                          fontStyle: "italic"
                        }}
                        className={classes.cardTitleAssociate}
                      >
                        Web Developer
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardAssociate}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="340"
                      image={persona}
                      title="Contemplative Reptile"
                      className={classes.mediaAssociate}
                    />
                    <CardContent style={{ paddingLeft: 0 }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ fontFamily: "Rubik", marginBottom: 0 }}
                        className={classes.cardTitleAssociate}
                      >
                        Parth Shah
                      </Typography>
                      <Typography
                        variant="p"
                        component="p"
                        style={{
                          fontFamily: "Rubik",
                          color: "#2ecc71",
                          fontStyle: "italic"
                        }}
                        className={classes.cardTitleAssociate}
                      >
                        Web Developer
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardAssociate}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="340"
                      image={persona}
                      title="Contemplative Reptile"
                      className={classes.mediaAssociate}
                    />
                    <CardContent style={{ paddingLeft: 0 }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ fontFamily: "Rubik", marginBottom: 0 }}
                        className={classes.cardTitleAssociate}
                      >
                        Parth Shah
                      </Typography>
                      <Typography
                        variant="p"
                        component="p"
                        style={{
                          fontFamily: "Rubik",
                          color: "#2ecc71",
                          fontStyle: "italic"
                        }}
                        className={classes.cardTitleAssociate}
                      >
                        Web Developer
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardAssociate}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="340"
                      image={persona}
                      title="Contemplative Reptile"
                      className={classes.mediaAssociate}
                    />
                    <CardContent style={{ paddingLeft: 0 }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ fontFamily: "Rubik", marginBottom: 0 }}
                        className={classes.cardTitleAssociate}
                      >
                        Parth Shah
                      </Typography>
                      <Typography
                        variant="p"
                        component="p"
                        style={{
                          fontFamily: "Rubik",
                          color: "#2ecc71",
                          fontStyle: "italic"
                        }}
                        className={classes.cardTitleAssociate}
                      >
                        Web Developer
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardAssociate}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="340"
                      image={persona}
                      title="Contemplative Reptile"
                      className={classes.mediaAssociate}
                    />
                    <CardContent style={{ paddingLeft: 0 }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ fontFamily: "Rubik", marginBottom: 0 }}
                        className={classes.cardTitleAssociate}
                      >
                        Parth Shah
                      </Typography>
                      <Typography
                        variant="p"
                        component="p"
                        style={{
                          fontFamily: "Rubik",
                          color: "#2ecc71",
                          fontStyle: "italic"
                        }}
                        className={classes.cardTitleAssociate}
                      >
                        Web Developer
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={classes.partnersInfo}>
            <div className={classNames(classes.layout)}>
              <Typography
                component="h3"
                variant="h3"
                className={classes.sectionTitle}
                style={{ marginBottom: "50px" }}
              >
                Partners &amp; Sponsors
              </Typography>
              <Grid container lg={12} xs={12}>
                <Grid item lg={2} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardSponsors}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      image={companyLogo}
                      title="Contemplative Reptile"
                    />
                  </Card>
                </Grid>

                <Grid item lg={2} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardSponsors}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      image={companyLogo}
                      title="Contemplative Reptile"
                    />
                  </Card>
                </Grid>
                <Grid item lg={2} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardSponsors}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      image={companyLogo}
                      title="Contemplative Reptile"
                    />
                  </Card>
                </Grid>
                <Grid item lg={2} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardSponsors}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      image={companyLogo}
                      title="Contemplative Reptile"
                    />
                  </Card>
                </Grid>
                <Grid item lg={2} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardSponsors}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      image={companyLogo}
                      title="Contemplative Reptile"
                    />
                  </Card>
                </Grid>
                <Grid item lg={2} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardSponsors}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      image={companyLogo}
                      title="Contemplative Reptile"
                    />
                  </Card>
                </Grid>
                <Grid item lg={2} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardSponsors}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      image={companyLogo}
                      title="Contemplative Reptile"
                    />
                  </Card>
                </Grid>
                <Grid item lg={2} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardSponsors}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      image={companyLogo}
                      title="Contemplative Reptile"
                    />
                  </Card>
                </Grid>
                <Grid item lg={2} xs={12} className={classes.infoBlock}>
                  <Card className={classes.cardSponsors}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      image={companyLogo}
                      title="Contemplative Reptile"
                    />
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>
        </main>
        {/* Footer */}
        <footer className={classes.footer}>
          <Typography variant="h6" align="center" gutterBottom>
            Footer
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          >
            Something here to give the footer a purpose!
          </Typography>
        </footer>
        {/* End footer */}
      </React.Fragment>
    );
  }
}

EventPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    event: state.events.event,
    auth: state.auth
  };
};

export default connect(
  mapStateToProps,
  { getEvent }
)(withStyles(styles)(EventPage));
