import React from "react";
import PropTypes from "prop-types";
import { FormikTextField } from "formik-material-fields";
import withStyles from "@material-ui/core/styles/withStyles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import classNames from "classnames";

const styles = {
  label: {
    fontSize: "15px",
    fontWeight: "400",
    color: "#252631",
    marginBottom: "1rem"
  },
  leftpadding: {
    "& input": {
      paddingLeft: "40px !important"
    }
  },
  leftBorder: {
    "& input": {
      borderLeft: "2px solid #F3A7A7"
    }
  }
};
const theme = createMuiTheme({
  overrides: {
    MuiFormHelperText: {
      root: {
        padding: "0px 0px 0px 5px"
      }
    },
    MuiInputLabel: {
      root: {
        "&$error": {
          color: "rgba(0,0,0,0.54)"
        }
      }
    },
    MuiInputBase: {
      input: {
        paddingLeft: "10px !important",
        fontSize: "14px",
        borderRadius: "5px",
        border: "1px solid #EDE8EF",
        borderLeft: props => {
          return props.mandatoryField
            ? "2px solid #F3A7A7"
            : "1px solid #EDE8EF";
        },
        marginTop: "5px",
        height: "26px"
      }
    }
  }
});
const InputField = props => {
  const {
    name,
    label,
    max,
    type,
    classes,
    mandatoryField,
    paddingfield,

    ...rest
  } = props;

  return (
    <ThemeProvider theme={theme}>
      <FormikTextField
        name={name}
        label={label}
        margin="normal"
        fullWidth
        inputProps={type !== "number" ? { maxLength: max || 100 } : { max }}
        InputProps={{
          // max: type !== "number" ? { maxLength: max || 100 } : { max },
          disableUnderline: true,
          mandatoryField
        }}
        className={
          (classNames(mandatoryField ? classes.leftBorder : ""),
          classNames(paddingfield ? classes.leftpadding : ""))
        }
        InputLabelProps={{ shrink: true }}
        type={type}
        {...rest}
      />
    </ThemeProvider>
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default withStyles(styles)(InputField);
