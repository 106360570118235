import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import SelectField from "../Common/Form/SelectField";
import { connect } from "react-redux";
import { getAllPartner } from "../../Actions/partnerActions";
import { capitalize } from "../../Utils/StringFunctions";

const styles = {};

const PartnerDropDown = props => {
  const {
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    partnerdata
  } = props;
  const [all_partners, setPartnerlist] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const partnerArr = [];
  const getPartnerOptions = partners => {
    // return partners which is not in partnerdata array
    partners.map(partner => {
      if (partnerdata && !partnerdata.includes(partner._id)) {
        return partnerArr.push({
          value: partner._id,
          label: capitalize(partner.partner_name)
        });
      }
    });
    return partnerArr ? partnerArr : [];
  };

  useEffect(() => {
    props.getAllPartner();
  }, []);

  useEffect(() => {
    setPartnerlist(props.all_partners);
  }, [props.all_partners]);

  useEffect(() => {
    setSelectedValue(props.values);
  }, [props.values]);

  const changeDropDown = (name, values) => {
    setSelectedValue(values);
    setFieldValue(name, values);
  };
  useEffect(() => {
    setSelectedValue(props.values);
  });
  const OpenPartnerModal = () => {
    props.openModaldata(true);
  };

  return (
    <SelectField
      value={selectedValue}
      name="partner_id"
      id="partner_id"
      onChange={changeDropDown}
      onBlur={setFieldTouched}
      error={errors ? props.errors.partner_id : ""}
      touched={touched}
      onCreateOption={() => OpenPartnerModal(true)}
      options={
        partnerdata
          ? getPartnerOptions(all_partners)
          : getAllPartner(all_partners)
      }
      multi={false}
      placeholder="Add Partner"
      creatable
    />
  );
};

const mapStateToProps = state => {
  return {
    all_partners: state.partners.all_partners
  };
};

export default connect(
  mapStateToProps,
  { getAllPartner }
)(withStyles(styles)(PartnerDropDown));
