import axios from "axios";
import {
  GET_PARTNERS,
  PARTNERS_LOADING,
  ADD_PARTNER,
  PARTNER_ERROR,
  ADD_PARTNER_ERROR,
  ADD_PARTNER_LOADING,
  GET_PARTNER,
  GET_ALL_PARTNER,
  EDIT_PARTNER
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";

export const getPartners = (id, search, event_id) => dispatch => {
  dispatch(setPartnersLoading());

  let url = "";
  if (id) {
    url = `/api/partner?companyid=${id}`;
  } else if (search) {
    url = `/api/partner?q=${search}`;
  } else if (event_id) {
    url = `/api/partner?eventid=${event_id}`;
  } else {
    url = `/api/partner`;
  }
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_PARTNERS,
        payload: res.data
      });
    })
    .catch(() =>
      dispatch({
        type: GET_PARTNERS,
        payload: {}
      })
    );
};
export const addPartner = (userData, history) => dispatch => {
  dispatch(addPartnerLoading());
  axios
    .post("/api/partner", userData)
    .then(result => {
      dispatch({
        type: ADD_PARTNER,
        payload: result.data
      });
      dispatch(showSuccess("Partner"));
      if (history) {
        history.push({
          pathname: "/partners"
        });
        history.push("/partners");
      }
    })
    .catch(err => {
      dispatch(showError("There was some error while creating a partner"));
      dispatch({ type: ADD_PARTNER_ERROR, payload: err.response.data });
    });
};

export const getPartner = id => dispatch => {
  axios
    .get(`/api/partner/${id}`)
    .then(res => {
      dispatch({
        type: GET_PARTNER,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: PARTNER_ERROR,
        payload: err.response && err.response.data
      })
    );
};

export const getAllPartner = () => dispatch => {
  axios
    .get(`/api/partner`)
    .then(res => {
      dispatch({
        type: GET_ALL_PARTNER,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: PARTNER_ERROR,
        payload: err.response && err.response.data
      })
    );
};
//EDIT PARTNER
export const editPartner = (id, userData, history) => dispatch => {
  axios
    .put(`/api/partner/update/${id}`, userData)
    .then(result => {
      dispatch({
        type: EDIT_PARTNER,
        payload: result.data
      });
      dispatch(showSuccess("", "Partner updated successfully"));
      history.push("/partners");
    })
    .catch(err => {
      dispatch({ type: PARTNER_ERROR, payload: err.response.data });
      dispatch(showError("There was some error while updating a partner"));
    });
};
// Profile Loading
export const setPartnersLoading = () => {
  return {
    type: PARTNERS_LOADING
  };
};
export const addPartnerLoading = () => {
  return {
    type: ADD_PARTNER_LOADING
  };
};
