import React from "react";
import { Typography, Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import logo from "../../../Assets/companylogo.png";
import { X } from "react-feather";
const ASSET_URL = process.env.REACT_APP_ASSET_URL;

const styles = () => {
  return {
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      boxShadow:
        "0 0 32px 0 rgba(12,12,13,.1), 0 2px 16px 0 rgba(12,12,13,.05)",
      backgroundColor: "#FCFDFE",
      position: "relative"
    },
    cardMedia: {
      paddingTop: "56.25%" // 16:9
    },
    cardContent: {
      border: "1px solid #efefef",
      backgroundColor: "#fff"
    },
    containerPaper: {
      position: "relative"
    },
    companyName: {
      fontWeight: 600,
      fontSize: "14px",
      textAlign: "center",
      color: "#222",
      textTransform: "capitalize",
      marginTop: "-18px",
      marginBottom: "18px"
    },
    profileImage: {
      display: "flex",
      justifyContent: "center"
    },
    deleteIcon: {
      position: "absolute",
      top: "3px",
      right: "4px",
      color: "#CDCDCD"
    },
    companyLogo: {
      width: "100%",
      padding: "5px",
      height: "60px",
      borderRadius: "100%"
    },
    imageSection: {
      position: "relative",
      top: "-37px",
      border: "1px solid #efefef",
      borderRadius: "100px",
      padding: "5px",
      width: "35%",
      boxShadow:
        "0 0 32px 0 rgba(12,12,13,.1), 0 2px 16px 0 rgba(12,12,13,.05)",
      background: "white"
    }
  };
};

function CompanyCard(props) {
  const { classes, data } = props;
  return (
    <div className={classes.card}>
      <div className={classes.cardContent}>
        <div className={classes.containerPaper}>
          <div className={classes.profileImage}>
            <div className={classes.imageSection}>
              <Avatar
                className={classes.companyLogo}
                src={data && data.logo ? `${ASSET_URL}${data.logo}` : logo}
              />
            </div>
          </div>
          <X className={classes.deleteIcon} />
          <Typography
            component="h2"
            variant="h5"
            className={classes.companyName}
          >
            {data && data.company_name ? data.company_name : ""}
          </Typography>
        </div>
      </div>
    </div>
  );
}
export default withStyles(styles)(CompanyCard);
