import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import SelectField from "../Common/Form/SelectField";
import { connect } from "react-redux";
import { getAllAssociate } from "../../Actions/associateActions";
import { capitalize } from "../../Utils/StringFunctions";

const styles = {};

const AssociateDropDown = props => {
  const {
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    aasociatedata
  } = props;
  const [all_associates, setAssociatelist] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const associateArr = [];
  const getAllAssociate = all_associates => {
    all_associates.map(all_associates => {
      if (aasociatedata && !aasociatedata.includes(all_associates._id)) {
        return associateArr.push({
          value: all_associates._id,
          label:
            all_associates.user &&
            capitalize(all_associates.user.first_name).concat(
              " ",
              all_associates.user && capitalize(all_associates.user.last_name)
            )
        });
      }
    });
    return associateArr ? associateArr : [];
  };

  useEffect(() => {
    props.getAllAssociate();
  }, []);

  useEffect(() => {
    setAssociatelist(props.all_associates);
  }, [props.all_associates]);

  const changeDropDown = (name, values) => {
    setSelectedValue(values);
    setFieldValue(name, values);
  };

  useEffect(() => {
    setSelectedValue(props.values);
  });

  const OpenAssociateModal = () => {
    props.openModaldata(true);
  };
  return (
    <SelectField
      value={selectedValue}
      name="associate_id"
      id="associate_id"
      onChange={changeDropDown}
      onBlur={setFieldTouched}
      error={errors ? errors.citylist : ""}
      touched={touched}
      onCreateOption={() => OpenAssociateModal(true)}
      options={
        aasociatedata
          ? getAllAssociate(all_associates)
          : getAllAssociate(all_associates)
      }
      multi={false}
      placeholder="Add Associate"
      creatable
    />
  );
};

const mapStateToProps = state => {
  return {
    all_associates: state.associates.all_associates
  };
};

export default connect(
  mapStateToProps,
  { getAllAssociate }
)(withStyles(styles)(AssociateDropDown));
