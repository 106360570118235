import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import SelectField from "../Common/Form/SelectField";
import { connect } from "react-redux";
import { getAllEvents } from "../../Actions/eventActions";
import { capitalize } from "../../Utils/StringFunctions";

const styles = {};

const EventDropDown = props => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    eventdata
  } = props;
  const [events, setEventlist] = useState([]);
  const eventArr = [];
  const getSelectedEvent = events => {
    // return events which is not in eventdata array
    events.map(event => {
      if (eventdata && !eventdata.includes(event._id)) {
        return eventArr.push({
          value: event._id,
          label: capitalize(event.event_name)
        });
      }
    });
    return eventArr ? eventArr : [];
  };
  const getAllEvent = events => {
    //Will return all events
    return events
      ? events.map(event => {
          return {
            value: event._id,
            label: capitalize(event.event_name)
          };
        })
      : [];
  };

  useEffect(() => {
    props.getAllEvents();
  }, []);

  useEffect(() => {
    setEventlist(props.events);
  }, [props.events]);

  return (
    <SelectField
      value={values}
      name="event_id"
      id="event_id"
      onChange={setFieldValue}
      onBlur={setFieldTouched}
      error={errors.citylist}
      touched={touched}
      options={eventdata ? getSelectedEvent(events) : getAllEvent(events)}
      multi={false}
      placeholder="Add Event"
    />
  );
};

const mapStateToProps = state => {
  return {
    events: state.events.allEvents
  };
};

export default connect(
  mapStateToProps,
  { getAllEvents }
)(withStyles(styles)(EventDropDown));
