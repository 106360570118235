import React from "react";
import { Paper, Tabs, Tab, Typography, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import AboutCompany from "./AboutCompany";
import ContactTabs from "./ContactTabs";
import EventsCompany from "./EventsCompany";
import ApplicationCompany from "./ApplicationCompany";
import ApplicantsCompany from "./ApplicantsCompany";
import AssociateCompany from "./AssociateCompany";
import PartnersCompany from "./PartnersCompany";
// import AssociateEvent from "./AssociateEvent";
// import ApplicationEvent from "./ApplicationEvent";
const styles = () => ({
  root: {
    paddingTop: "18px",
    paddingBottom: "15px"
  },
  noCoworker: {
    padding: "24px 24px 11px",
    color: "lightgrey"
  },
  layout: {
    marginTop: "19px !important",
    padding: "20px 25px"
  },
  paper: {
    backgroundColor: "#fcfdfe"
  }
});
const AntTabs = withStyles({
  indicator: {
    color: "#435bf2",
    backgroundColor: " #4682B4",
    top: "38px",
    display: "none"
  }
})(Tabs);

const AntTab = withStyles(() => ({
  selected: {
    color: " #4D7CFE !important",
    backgroundColor: "#E2EAFF",
    borderRadius: "5px"
  },
  root: {
    minWidth: "60px",
    maxWidth: "100%",
    textTransform: "capitalize",
    minHeight: "0px !important"
  }
}))(props => <Tab disableRipple {...props} />);

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        // padding: "0px 14px",
        // marginTop: " 14px",
        backgroundColor: "#fff"
      }}
    >
      {props.children}
    </Typography>
  );
}

function CompanyTabs(props) {
  const { classes, company } = props;
  const [value, setValue] = React.useState(0);
  function handleChange(event, newValue) {
    setValue(newValue);
  }
  return (
    <Paper className={classes.root} style={{ backgroundColor: "#FCFDFE" }}>
      <AntTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <AntTab label="About Company" />
        <AntTab label="Contacts" />
        <AntTab label="Events" />
        <AntTab label="Applications" />
        <AntTab label="Applicants" />
        <AntTab label="Partners" />
        <AntTab label="Associates" />
      </AntTabs>
      <TabContainer>
        {value === 0 && <AboutCompany company={company} id={props.id} />}
        {value === 1 && <ContactTabs company={company} id={props.id} />}
        {value === 2 && <EventsCompany company={company} id={props.id} />}
        {value === 3 && <ApplicationCompany company={company} id={props.id} />}
        {value === 4 && (
          <ApplicantsCompany
            company={company}
            id={props.id}
            history={props.history}
          />
        )}
        {value === 5 && <PartnersCompany company={company} id={props.id} />}
        {value === 6 && <AssociateCompany company={company} id={props.id} />}
      </TabContainer>
    </Paper>
  );
}

const mapStateToProps = state => {
  return {
    errors: state.errors
    // companyCoworkers: state.coworkers.companyCoworkers
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(CompanyTabs));
