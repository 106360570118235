import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { Paper, Grid, Button, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import FormLabel from "@material-ui/core/FormLabel";
import {
  SelectField,
  InputField,
  DatePickerField,
  RadioField,
  UploadField
} from "../../Components/Common/Form";
import {
  MemberSelection,
  EventMode,
  eventType
} from "../../Components/Common/DropDownData";
import { PlusCircle, MinusCircle } from "react-feather";
import CityDropdown from "../../Components/Common/CityDropdown";
import { registerEvent } from "../../Actions/eventActions";
import CustomButton from "../../Components/Common/Button";
import ClientList from "../../Components/Common/ClientList";
import Editor from "../../Components/Common/Form/Editor";
import { getEvent, editEvent } from "../../Actions/eventActions";
import SocialHandle from "../../Components/Common/SocialHandle";
import checkPermission from "../../Utils/CheckPermissions";

const styles = theme => ({
  layout: {
    width: "auto",
    backgroundColor: "#fff",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  applicationHeader: {
    borderLeft: "2px solid #F3A7A7",
    fontSize: "16px",
    fontWeight: 600,
    paddingLeft: "0.5%"
  },
  descriptionLabel: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)"
  },
  backgroundPaper: {
    marginBottom: "3%"
  },
  addDeleteIcon: {
    display: "flex",
    alignItems: "center"
  },
  draftButton: {
    width: "146.93px",
    height: "46px",
    backgroundColor: "#F1F1F1",
    color: "#4D7CFE",
    "&:hover": {
      backgroundColor: "#E2E2E2"
    }
  },
  icon: {
    color: "grey"
  },
  radioClass: {
    display: "flex",
    alignItems: "flex-end",
    paddingLeft: "3%"
  },
  RadioFieldPadding: {
    paddingLeft: "3%"
  }
});

const EventFormSchema = Yup.object().shape({
  event_name: Yup.string().required("Field Required"),
  banner: Yup.string().required("Field Required"),
  // company: Yup.string().test(function() {
  //   if (checkPermission("COMPANY_VIEW")) {
  //     return Yup.string().required("Field Required");
  //   }
  //   return true;
  // }),
  event_type: Yup.string().required("Field Required"),
  mode: Yup.string().required("Field Required"),
  team_max: Yup.string().required("Field Required"),
  team_min: Yup.string().required("Field Required"),
  // theme_name: Yup.string().required("Field Required"),
  location: Yup.string().required("Field Required"),
  venue: Yup.string().required("Field Required"),
  last_day_of_registration: Yup.date().required("Field Required"),
  details: Yup.string().required("Field Required")
});
class NewEventForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      draft: false,
      desc: ""
    };
    this.state.id = this.props.match.params.id;
  }
  componentDidMount() {
    if (this.state.id) {
      this.props.getEvent(this.state.id);
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.addEventData !== prevState.addEventData) {
      return { addEventData: nextProps.addEventData };
    }
    if (nextProps.event !== prevState.event) {
      return { event: nextProps.event };
    } else return [];
  }

  getInitialValues = id => {
    const event = this.props.event ? this.props.event : {};
    const eventCity = event.location ? event.location.cityName : "";
    const companyName = event.company ? event.company.company_name : "";
    if (id) {
      return {
        company: companyName,
        event_name: event.event_name,
        banner: event.banner,
        event_type: eventType.filter(
          option => option.value === event.event_type
        )[0],
        mode: event.mode,
        details: event.details,
        team_min: MemberSelection.filter(
          option => option.value == event.team_min
        )[0],
        team_max: MemberSelection.filter(
          option => option.value == event.team_max
        )[0],
        facebook: event.facebook,
        twitter: "",
        theme: event.theme,
        location: eventCity,
        start_date: event.start_date,
        end_date: event.end_date,
        venue: event.venue,
        last_day_of_registration: event.last_day_of_registration
      };
    } else {
      return {
        company: "",
        event_name: "",
        banner: "",
        event_type: "",
        mode: "online",
        details: "",
        team_min: "",
        team_max: "",
        facebook: "",
        twitter: "",
        theme: [
          {
            theme_name: ""
          }
        ],
        location: "",
        start_date: new Date(),
        end_date: new Date(),
        venue: "",
        last_day_of_registration: new Date(),
        event_status: ""
      };
    }
  };
  handleCancel = () => {
    this.props.history.push("/events");
  };
  formatData = values => {
    const formated = { ...values };
    const val = formated.selectedLocationObj
      ? formated.selectedLocationObj.value
      : "";
    formated.location = val;
    const clientName = formated.company ? formated.company.value : "";
    formated.company = clientName;
    const max = formated.team_max ? formated.team_max.value : "";
    formated.team_max = max;
    const min = formated.team_min ? formated.team_min.value : "";
    formated.team_min = min;
    const eventType = formated.event_type ? formated.event_type.value : "";
    formated.event_type = eventType;
    if (formated.draft === true) formated.event_status = "draft";
    else formated.event_status = "published";

    return formated;
  };

  render() {
    const { classes } = this.props;
    const { id, addEventData, event } = this.state;
    const { loading: addLoading } = addEventData;

    const maxOptions = values => {
      return values &&
        values.team_min &&
        values.team_min.value &&
        MemberSelection
        ? MemberSelection.filter(obj => obj.value > values.team_min.value)
        : MemberSelection;
    };

    return (
      <React.Fragment>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <div>
              <Formik
                initialValues={this.getInitialValues(id)}
                enableReinitialize={true}
                validationSchema={EventFormSchema}
                onSubmit={values => {
                  const data = this.formatData(values);
                  delete data.draft;
                  if (!this.state.id) {
                    this.props.registerEvent(data, this.props.history);
                  }
                  if (this.state.id) {
                    this.props.editEvent(id, data, this.props.history);
                  }
                }}
              >
                {({
                  values,
                  setFieldValue,
                  setFieldTouched,
                  errors,
                  touched,
                  submitForm
                }) => (
                  <Form>
                    <div className={classes.backgroundPaper}>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputField
                            name="event_name"
                            label=" Event Name"
                            placeholder="Enter event name"
                            mandatoryField
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <UploadField
                            label="Upload Banner"
                            name="banner"
                            id="banner"
                            onChange={setFieldValue}
                            value={values.banner}
                            folder="event/banner"
                            required
                          />
                        </Grid>
                        {checkPermission("COMPANY_VIEW") && (
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <ClientList
                              values={values}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              errors={errors}
                              touched={touched}
                              name="company"
                              mandatoryField
                            />
                          </Grid>
                        )}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <SelectField
                            value={values.event_type}
                            name="event_type"
                            id="event_type"
                            label="Type of event"
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            multi={false}
                            error={errors.industry}
                            touched={touched.industry}
                            options={eventType}
                            mandatoryField
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className={
                            checkPermission("ADMIN_VIEW")
                              ? ""
                              : classes.radioClass
                          }
                        >
                          <RadioField
                            name="mode"
                            label="Mode"
                            id="mode"
                            options={EventMode}
                            className={
                              checkPermission("ADMIN_VIEW")
                                ? ""
                                : classes.RadioFieldPadding
                            }
                          />
                        </Grid>
                        <Grid item lg={12} sm={12} xs={12}>
                          <FormLabel
                            className={classes.descriptionLabel}
                            component="legend"
                          >
                            About Event
                          </FormLabel>
                          <Editor
                            getval={data => setFieldValue("details", data)}
                            data={event ? event.details : ""}
                            error={errors.details}
                            name="details"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <span className={classes.applicationHeader}>
                            Team Members
                          </span>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <SelectField
                            value={values.team_min}
                            name="team_min"
                            id="team_min"
                            label="Minimum"
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            multi={false}
                            error={errors.team_min}
                            touched={touched.team_min}
                            options={MemberSelection}
                            mandatoryField
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <SelectField
                            value={values.team_max}
                            name="team_max"
                            id="team_max"
                            label="Maximun"
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            multi={false}
                            error={errors.team_max}
                            touched={touched.team_max}
                            options={maxOptions(values)}
                            mandatoryField
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputField
                            paddingfield
                            InputProps={{
                              disableUnderline: true,
                              startAdornment: (
                                <InputAdornment
                                  className={classes.inputSocialIcon}
                                >
                                  <SocialHandle
                                    type="facebook"
                                    height="25px"
                                    width="25px"
                                    inputFeildSocial
                                  />
                                </InputAdornment>
                              )
                            }}
                            name="facebook"
                            label="Facebook Link"
                            placeholder="Enter your facebook profile"
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.backgroundPaper}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <span className={classes.applicationHeader}>
                            Location & Date
                          </span>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <CityDropdown
                            values={values.location}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            errors={errors}
                            touched={touched}
                            name="location"
                            mandatoryField
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputField
                            name="venue"
                            label="Venue"
                            placeholder="Enter Venue"
                            mandatoryField
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <DatePickerField
                            name="start_date"
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            value={values.start_date}
                            disableFuture={false}
                            label="Start Date"
                            disablePast
                            errors={errors.start_date}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <DatePickerField
                            name="end_date"
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            value={values.end_date}
                            disableFuture={false}
                            label="End Date"
                            minDate={values.start_date}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <DatePickerField
                            name="last_day_of_registration"
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            value={values.last_day_of_registration}
                            disableFuture={false}
                            label="Last date of registeration"
                            maxDate={values.start_date}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.backgroundPaper}>
                      <FieldArray
                        name="theme"
                        render={({ remove, push }) => (
                          <div>
                            <Grid item xs={12} sm={12}>
                              <span className={classes.applicationHeader}>
                                Themes
                              </span>
                            </Grid>
                            {values.theme &&
                              values.theme.length > 0 &&
                              values.theme.map((theme, idx) => (
                                <Grid container spacing={2} key={idx}>
                                  <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <InputField
                                      name={`theme.${idx}.theme_name`}
                                      placeholder="Enter theme name"
                                      mandatoryField
                                    />
                                  </Grid>
                                  <Grid item className={classes.addDeleteIcon}>
                                    {values.theme.length > 1 && (
                                      <MinusCircle
                                        className={classes.icon}
                                        onClick={() => remove(idx)}
                                      />
                                    )}
                                  </Grid>
                                  <Grid item className={classes.addDeleteIcon}>
                                    <PlusCircle
                                      className={classes.icon}
                                      onClick={() =>
                                        push({
                                          theme_name: ""
                                        })
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                          </div>
                        )}
                      />
                    </div>

                    <Grid container xs={12} sm={12}>
                      <Grid container xs={6}>
                        <Button
                          onClick={async () => {
                            setFieldValue("draft", true);
                            await Promise.resolve();
                            submitForm();
                          }}
                          className={classes.draftButton}
                        >
                          Save Draft
                        </Button>
                      </Grid>
                      <Grid container xs={6} spacing={2} justify="flex-end">
                        <Grid item>
                          <CustomButton
                            type="cancel"
                            label="Cancel"
                            onClick={this.handleCancel}
                          />
                        </Grid>
                        <Grid item>
                          <CustomButton
                            type="submit"
                            label={id ? "Update" : "Publish"}
                            loader={addLoading}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    addEventData: state.events.addEvent,
    event: state.events.event
  };
};

export default connect(
  mapStateToProps,
  { registerEvent, getEvent, editEvent }
)(withStyles(styles)(withRouter(NewEventForm)));
