import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid, Button } from "@material-ui/core";
import { connect } from "react-redux";
import {
  getCompanies,
  AddAssociatetoCompany
} from "../../Actions/companiesAction";
import CompanyCard from "../../Components/Common/Cards/CompanyCard";
import CompanyDropdown from "../../Components/Common/CompanyDropdown";
import { Formik, Form } from "formik";
import SkeletonCard from "../../Components/Common/Cards/SkeletonCard";
import NoRecordInViewPage from "../../Components/Common/NoRecordInViewPage";

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: "#ca8301"
  },
  mainOverview: {
    padding: "20px 40px"
  },
  offerImage: {
    border: "1px solid #ececec",
    borderRadius: "5px",
    marginBottom: "3%"
  },
  addCompanySection: {
    display: "flex",
    justifyContent: "flex-end"
  },
  addButton: {
    marginTop: "26px"
  },
  companyContainer: {
    marginTop: "4%"
  }
});

class CompanyAssociate extends Component {
  state = {
    dense: false,
    secondary: false
  };

  componentDidMount() {
    let id = this.props.data._id;
    this.props.getCompanies("", id);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.associatedCompanies &&
      nextProps.associatedCompanies !== prevState.associatedCompanies
    ) {
      const CompanyIds = nextProps.associatedCompanies.map(el => {
        return el._id;
      });
      return { associatedCompanies: nextProps.associatedCompanies, CompanyIds };
    }
    return null;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
    if (prevProps.addAssociatetoCompany !== this.props.addAssociatetoCompany) {
      this.setState({
        addAssociatetoCompany: this.props.addAssociatetoCompany
      });
      if (this.props.addAssociatetoCompany.success)
        this.props.getCompanies("", this.props.data._id);
    }
  }
  render() {
    const { classes, data } = this.props;
    const { loading, associatedCompanies, CompanyIds } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.mainOverview}>
          <Formik
            initialValues={{
              company_id: ""
            }}
            onSubmit={values => {
              const val = values.company_id ? values.company_id.value : "";
              values.company_id = val;
              values.associate_id = data._id;
              this.props.AddAssociatetoCompany(values);
            }}
          >
            {({ setFieldValue, setFieldTouched, values, errors, touched }) => (
              <Form className={classes.container} noValidate autoComplete="off">
                <Grid
                  container
                  xs={12}
                  lg={12}
                  spacing={2}
                  className={classes.addCompanySection}
                >
                  <Grid item xs={3} lg={3}>
                    <CompanyDropdown
                      values={values.company_id}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      touched={touched}
                      name="company_id"
                      companyData={CompanyIds}
                    />
                  </Grid>
                  <Grid item xs={1} lg={1}>
                    <Button
                      variant="contained"
                      size="small"
                      type="submit"
                      className={classes.addButton}
                      disabled={!values.company_id}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
                {!loading &&
                  associatedCompanies &&
                  associatedCompanies.length <= 0 && (
                    <Grid container xs={12} justify="center">
                      <NoRecordInViewPage
                        text={`No Companies found for this associate`}
                      />
                    </Grid>
                  )}
                <Grid
                  container
                  spacing={2}
                  className={classes.companyContainer}
                >
                  {loading
                    ? Array.from(new Array(6)).map(id => {
                        return (
                          <Grid item key={id} xs={12} lg={4} sm={6}>
                            <SkeletonCard />
                          </Grid>
                        );
                      })
                    : associatedCompanies &&
                      associatedCompanies.map((element, i) => {
                        return (
                          <Grid item xs={3} sm={3} key={i}>
                            <CompanyCard data={element} />
                          </Grid>
                        );
                      })}
                </Grid>
              </Form>
            )}
          </Formik>
        </main>
      </React.Fragment>
    );
  }
}

CompanyAssociate.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    associatedCompanies: state.company.associatedCompanies,
    loading: state.company.loading,
    addAssociatetoCompany: state.company.addAssociatetoCompany
  };
};

export default connect(
  mapStateToProps,
  { getCompanies, AddAssociatetoCompany }
)(withStyles(styles)(CompanyAssociate));
