import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Grid, Button } from "@material-ui/core";
import classNames from "classnames";
import RenderHtml from "../../Components/Common/RenderHtml";

const $quoteSym = " ";

const styles = theme => ({
  eventtitle: {
    marginBottom: 14,
    fontSize: 18
  },
  title: {
    marginBottom: 5,
    fontSize: "16px"
  },
  prizetitle: {
    fontSize: 15,
    margin: "2px 9px"
  },
  prize: {
    fontSize: 15,
    margin: "2px 9px"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: "#ca8301"
  },
  mainOverview: {
    padding: "20px 40px"
  },
  subHeader: {
    color: "#000",
    // fontWeight: 600,
    fontSize: "16px",
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "24px",
      left: "0px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute"
    }
  },
  aboutEventCont: {
    fontSize: "14px",
    color: "#626262",
    padding: "20px 0px"
  },
  memberInfo: {
    fontWeight: "normal",
    fontSize: 14,
    color: "#626262",
    padding: "20px 0px"
  },
  themeButton: {
    margin: "20px 10px 0px 0px",
    borderRadius: "20px",
    backgroundColor: "#efefef",
    color: "#626262",
    cursor: "default",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#efefef",
      color: "#626262"
    }
  },
  eventHeader: {
    textTransform: "capitalize"
  }
});

function OverviewEvent(props) {
  const { classes, eventData } = props;
  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.mainOverview}>
        <div className={classes.aboutEvent}>
          <span className={classNames(classes.subHeader, classes.eventHeader)}>
            About {eventData.event_name}
          </span>
          {/* <p className={classes.aboutEventCont}>{eventData.details}</p> */}
          <RenderHtml data={eventData ? eventData.details : ""} />
        </div>
        <div className={classes.teamMember}>
          <span type="About TalentRupt" className={classes.subHeader}>
            Team Members
          </span>
          <Typography
            className={classes.memberInfo}
            variant="subtitle1"
            component="p"
          >
            Minimum : {eventData.team_min} | Maximum: {eventData.team_max}
          </Typography>
        </div>
        <div className={classes.themeSection}>
          <span type="About TalentRupt" className={classes.subHeader}>
            Themes
          </span>
          <Grid item>
            {eventData.theme
              ? eventData.theme.map(el => {
                  return (
                    <Button
                      key={el}
                      variant="contained"
                      color="primary"
                      className={classes.themeButton}
                    >
                      {el.theme_name}
                    </Button>
                  );
                })
              : ""}
          </Grid>
        </div>
      </main>
    </React.Fragment>
  );
}

export default withStyles(styles)(OverviewEvent);
