import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import ApplicantCard from "../../Components/Common/Cards/ApplicantCard";
import { getApplicants } from "../../Actions/applicantActions";
import { connect } from "react-redux";
// import StackCard from "../../Components/Common/Stack";
import SearchBar from "../../Components/Common/SearchBar";
// import ScreenHeader from "../../Components/Common/ScreenHeader";
import SkeletonCard from "../../Components/Common/Cards/SkeletonCard";
import NoRecordsFound from "../../Components/Common/NoRecordsFound";
import NoDataFound from "../../Components/Common/NoDataFound";

const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginBottom: theme.spacing.unit * 3,
    flexGrow: 1,
    marginTop: "16px",
    backgroundColor: "transparent",
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginBottom: theme.spacing.unit * 6
      // padding: theme.spacing.unit * 3
    }
  },
  applicationHeader: {
    margin: "20px",
    borderBottom: "3px solid orange",
    paddingBottom: "5px"
  },
  heroUnit: {
    margin: "20px 0"
  },
  title: {
    fontSize: "26px",
    margin: "20px 11px 0px 11px",
    borderBottom: "3px solid #edc40c",
    display: "inline-block",
    paddingBottom: "7px",
    width: "22px"
  },
  subHeader: {
    display: "flex",
    alignItems: "center",
    margin: "30px 0px"
  },
  main: {
    background: "#fff",
    padding: "24px"
  }
});
class AdminApplicants extends React.Component {
  constructor(props) {
    super(props);
    this.state = { applicants: [], search: "", isSearch: false };
  }
  componentDidMount() {
    this.props.getApplicants();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicants !== this.props.applicants) {
      this.setState({
        applicants: this.props.applicants
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
  }
  searchApplications = (search, isSearch) => {
    this.setState({ isSearch: true, search });
    this.props.getApplicants(search, isSearch);
  };
  render() {
    const { classes } = this.props;
    const { loading, applicants, isSearch, search } = this.state;
    if (
      !loading &&
      this.props.applicants &&
      this.props.applicants.length <= 0 &&
      !isSearch
    ) {
      return (
        <div style={{ textAlign: "center" }}>
          <NoDataFound />
        </div>
      );
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.main}>
          <div style={{ marginBottom: "10px" }}>
            {/* <ScreenHeader type="applicants" /> */}

            <div className={classes.subHeader}>
              <SearchBar
                type="Search applicants"
                onSearch={this.searchApplications}
              />
              {/* TODO IN VERSION-2 */}

              {/* <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.exportButton}
                onClick={this.handleChange}
                style={{ marginRight: "10px" }}
              >
                Export
              </Button> */}
            </div>
          </div>
          {this.props.applicants &&
            this.props.applicants.length <= 0 &&
            isSearch && (
              <NoRecordsFound
                text={`No records found for your text "${search}"`}
              />
            )}
          <main className={classes.layout}>
            <Grid container spacing={2}>
              {loading
                ? Array.from(new Array(6)).map(id => {
                    return (
                      <Grid item key={id} xs={12} lg={4} sm={6}>
                        <SkeletonCard />
                      </Grid>
                    );
                  })
                : applicants &&
                  applicants.map((element, id) => {
                    return (
                      <Grid item xs={12} lg={4} md={6} sm={12} key={id}>
                        <ApplicantCard
                          data={element}
                          history={this.props.history}
                        />
                      </Grid>
                    );
                  })}
            </Grid>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

AdminApplicants.propTypes = {};

const mapStateToProps = state => {
  return {
    applicants: state.applicants.applicants,
    loading: state.applicants.loading
  };
};

export default connect(
  mapStateToProps,
  { getApplicants }
)(withStyles(styles)(AdminApplicants));
