import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import AssociateCard from "../../Components/Common/Cards/AssociateCard";
import { getAssociates } from "../../Actions/associateActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SearchBar from "../../Components/Common/SearchBar";
import AddFormButton from "../../Components/Common/AddFormButton";
import SkeletonCard from "../../Components/Common/Cards/SkeletonCard";
import NoRecordsFound from "../../Components/Common/NoRecordsFound";
import NoDataFound from "../../Components/Common/NoDataFound";

const styles = () => ({
  search: {
    backgroundColor: "whitesmoke",
    border: "1px solid #e1dbdb"
  },

  layout: {
    marginTop: "20px"
  },
  subHeader: {
    display: "flex",
    alignItems: "center",
    margin: "30px 0px"
  },
  main: {
    background: "#fff",
    padding: "24px"
  }
});

class AdminAssociates extends React.Component {
  state = {
    age: "",
    open: false
  };

  constructor(props) {
    super(props);
    this.state = { associates: [] };
  }

  componentDidMount() {
    this.props.getAssociates();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.associates !== prevState.associates) {
      return { associates: nextProps.associates };
    } else return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.associates !== this.props.associates) {
      this.setState({
        associates: this.props.associates
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  searchAssociates = (search, isSearch) => {
    this.setState({ isSearch: true, search });
    this.props.getAssociates("", search, isSearch);
  };
  render() {
    const { classes } = this.props;
    const { associates, loading, isSearch, search } = this.state;
    if (!loading && associates && associates.length <= 0 && !isSearch) {
      return (
        <div style={{ textAlign: "center" }}>
          <NoDataFound />
          <AddFormButton type="Associates" url="/associates/add" />
        </div>
      );
    }
    return (
      <div className={classes.main}>
        <div style={{ marginBottom: "10px" }}>
          {/* <ScreenHeader type="Associates" /> */}

          <div className={classes.subHeader}>
            <SearchBar
              type="Search Associates"
              onSearch={this.searchAssociates}
            />
            <AddFormButton type="Associate" url="/associates/add" />
          </div>
        </div>
        {this.state.associates &&
          this.state.associates.length <= 0 &&
          isSearch && (
            <NoRecordsFound
              text={`No records found for your text "${search}"`}
            />
          )}
        <main className={classes.layout}>
          <Grid container spacing={2}>
            {loading
              ? Array.from(new Array(6)).map(id => {
                  return (
                    <Grid item key={id} xs={12} lg={4} sm={6}>
                      <SkeletonCard />
                    </Grid>
                  );
                })
              : associates &&
                associates.map((element, id) => {
                  return (
                    <Grid item xs={12} lg={4} md={6} sm={12} key={id}>
                      <AssociateCard
                        data={element}
                        history={this.props.history}
                        collapseIcon
                        viewMore
                      />
                    </Grid>
                  );
                })}
          </Grid>
        </main>
      </div>
    );
  }
}

AdminAssociates.propTypes = {};

const mapStateToProps = state => {
  return {
    associates: state.associates.associates,
    auth: state.auth,
    loading: state.associates.loading
  };
};

AdminAssociates.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  { getAssociates }
)(withStyles(styles)(AdminAssociates));
