import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Table, TableHead, TableRow, TableBody } from "@material-ui/core";
import { connect } from "react-redux";
import { getEvents } from "../../Actions/eventActions";
import SkeletonTable from "../../Components/Common/SkeletonTable";
import Cell from "../../Components/Common/Table/TableCell";
import Header from "../../Components/Common/Table/TableHeader";
import Pagination from "../../Components/Common/Table/TablePagination";
import NoRecordInViewPage from "../../Components/Common/NoRecordInViewPage";
import StatusColor from "../../Components/Common/EventStatusColor";

const moment = require("moment");

const styles = () => ({
  mainOverview: {
    padding: "20px 40px"
  },
  viewLink: {
    textDecoration: "none",
    color: "#626262"
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE"
    }
  }
});

class EventsCommpany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10
    };
  }

  componentDidMount() {
    let id = this.props.id;
    this.props.getEvents(id);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.events !== prevState.events) {
      return { events: nextProps.events };
    } else return null;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };
  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <Header>Sr</Header>
          <Header>Date</Header>
          <Header>Event</Header>
          <Header>Location</Header>
          <Header>Status</Header>
        </TableRow>
      </TableHead>
    );
  };
  renderBody = () => {
    const { classes } = this.props;
    const { page, rowsPerPage } = this.state;

    return (
      <TableBody>
        {this.state.events && this.state.events.length <= 0 && (
          <NoRecordInViewPage
            colSpan="5"
            text={`No Events found for this company`}
          />
        )}
        {this.state.events &&
          this.state.events
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, id) => {
              const color = StatusColor(item.event_status);
              return (
                <React.Fragment key>
                  <TableRow className={classes.root1}>
                    <Cell>{page * rowsPerPage + id + 1}</Cell>
                    <Cell>{moment(item.start_date).format("DD/MM/YYYY")}</Cell>
                    <Cell>{item.event_name}</Cell>
                    <Cell>{item.location && item.location.cityName}</Cell>
                    <Cell>
                      <span style={{ color: color }}>{item.event_status}</span>
                    </Cell>
                  </TableRow>
                </React.Fragment>
              );
            })}
      </TableBody>
    );
  };

  render() {
    const { classes } = this.props;
    const itemLength = this.state.events ? this.state.events.length : 0;
    const { loading } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.mainOverview}>
          {loading ? (
            Array.from(new Array(10)).map(id => {
              return <SkeletonTable key={id} />;
            })
          ) : (
            <div className={classes.root}>
              <div className={classes.header}>
                <Table className={classes.table}>
                  {this.renderHead()}
                  {this.renderBody()}
                </Table>
              </div>
              <Pagination
                count={itemLength}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          )}
        </main>
      </React.Fragment>
    );
  }
}

EventsCommpany.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    events: state.events.events,
    loading: state.events.loading
  };
};

export default connect(
  mapStateToProps,
  { getEvents }
)(withStyles(styles)(EventsCommpany));
