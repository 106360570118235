import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import {
  addCompany,
  getCompany,
  updateCompany
} from "../../Actions/companiesAction";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormLabel from "@material-ui/core/FormLabel";
import SelectField from "../../Components/Common/Form/SelectField";
import InputField from "../../Components/Common/Form/InputField";
import { Grid } from "@material-ui/core";
import UploadField from "../../Components/Common/Form/UploadField";
import Button from "../../Components/Common/Button";
import CityDropdown from "../../Components/Common/CityDropdown";
import SocialHandle from "../../Components/Common/SocialHandle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Editor from "../../Components/Common/Form/Editor";
import { StrengthOptions } from "../../Components/Common/DropDownData";
const styles = theme => ({
  layout: {
    width: "auto",
    backgroundColor: "#fff",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      // width: 700,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },

  formFooter: {
    display: "flex"
  },
  descriptionLabel: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)"
  },
  cancelButton: {
    marginRight: "20px"
  }
});

const CompanySchema = Yup.object().shape({
  company_name: Yup.string().required("Field Required"),
  location: Yup.string().required("Field Required"),
  employee_strength: Yup.string().required("Field Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Field Required"),
  website: Yup.string()
    .required("Field Required")
    .url(),
  logo: Yup.string().required("Field Required"),
  banner_image: Yup.string().required("Field Required"),
  description: Yup.string().required("Field Requierd")
});

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      desc: ""
    };
    this.state.id = this.props.match.params.id;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.addCompanyData !== prevState.addCompanyData) {
      return { addCompanyData: nextProps.addCompanyData };
    }
    if (nextProps.company !== prevState.company) {
      return { company: nextProps.company };
    } else return [];
  }

  componentDidMount() {
    if (this.state.id) {
      this.props.getCompany(this.state.id);
    }
  }

  getInitialValues = id => {
    const company = this.props.company ? this.props.company : "";
    const city = company.location ? company.location.cityName : "";
    if (id) {
      return {
        company_name: company.company_name,
        logo: company.logo,
        banner_image: company.banner_image,
        website: company.website,
        email: company.email,
        employee_strength: StrengthOptions.filter(
          option => option.value === company.employee_strength
        )[0],
        location: city,
        linkedin: company.linkedin,
        facebook: company.facebook,
        twitter: company.twitter,
        instagram: company.instagram,
        createdBy: this.props.auth.user.id,
        description: company.description
      };
    } else {
      return {
        company_name: "",
        logo: "",
        banner_image: "",
        employee_strength: "",
        email: "",
        website: "",
        location: "",
        linkedin: "",
        facebook: "",
        twitter: "",
        instagram: "",
        description: "",
        createdBy: this.props.auth.user.id
      };
    }
  };
  handleClose = () => {
    this.props.history.push("/company");
  };
  formatData = values => {
    const formated = { ...values };
    const strengthValue = formated.employee_strength
      ? formated.employee_strength.value
      : "";
    formated.employee_strength = strengthValue;
    const locationValue = formated.selectedLocationObj
      ? formated.selectedLocationObj.value
      : "";
    formated.location = locationValue;
    return formated;
  };

  render() {
    const { id, addCompanyData } = this.state;
    const { loading: addLoading } = addCompanyData;
    const { classes, company } = this.props;

    return (
      <React.Fragment>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <div>
              <Formik
                initialValues={this.getInitialValues(id)}
                enableReinitialize={true}
                validationSchema={CompanySchema}
                onSubmit={values => {
                  const data = this.formatData(values);
                  if (!this.state.id) {
                    this.props.addCompany(data, this.props.history);
                  } else {
                    this.props.updateCompany(
                      this.state.id,
                      data,
                      this.props.history
                    );
                  }
                }}
              >
                {({
                  values,
                  setFieldValue,
                  setFieldTouched,
                  errors,
                  touched
                }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item lg={7} md={7} sm={12} xs={12}>
                        <InputField
                          name="company_name"
                          label="Name"
                          placeholder="Enter Name"
                          mandatoryField
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <UploadField
                          label="Upload Logo"
                          name="logo"
                          id="logo"
                          onChange={setFieldValue}
                          value={values.logo}
                          folder="company/logo"
                          required
                        />
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <UploadField
                          label="Upload Banner"
                          name="banner_image"
                          id="banner_image"
                          onChange={setFieldValue}
                          value={values.banner_image}
                          folder="company/banner"
                          required
                        />
                      </Grid>
                      <Grid item lg={12} xs={12} sm={12}>
                        <FormLabel
                          className={classes.descriptionLabel}
                          component="legend"
                        >
                          About
                        </FormLabel>
                        <Editor
                          getval={data => setFieldValue("description", data)}
                          data={this.state.id ? company.description : ""}
                          error={errors.description}
                          name="description"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          name="website"
                          label="Website"
                          placeholder="Enter Website"
                          mandatoryField
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          name="email"
                          label="Email ID"
                          placeholder="johndoe@mail.com"
                          mandatoryField
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <SelectField
                          value={values.employee_strength}
                          name="employee_strength"
                          id="employee_strength"
                          label="Employee Strength"
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          multi={false}
                          error={errors.employee_strength}
                          touched={touched.employee_strength}
                          options={StrengthOptions}
                          mandatoryField
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CityDropdown
                          values={values.location}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          touched={touched}
                          name="location"
                          label="Location"
                          placeholder="Select location"
                          mandatoryField
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          paddingfield
                          name="facebook"
                          label="Facebook "
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment
                                className={classes.inputSocialIcon}
                              >
                                <SocialHandle
                                  type="facebook"
                                  height="25px"
                                  width="25px"
                                  inputFeildSocial
                                />
                              </InputAdornment>
                            )
                          }}
                          placeholder="Enter your facebook profile link"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          paddingfield
                          name="linkedin"
                          label="Linkedin "
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment
                                className={classes.inputSocialIcon}
                              >
                                <SocialHandle
                                  type="linkedin"
                                  height="25px"
                                  width="25px"
                                  inputFeildSocial
                                />
                              </InputAdornment>
                            )
                          }}
                          placeholder="Enter your Linkedin profile link"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputField
                          paddingfield
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment
                                className={classes.inputSocialIcon}
                              >
                                <SocialHandle
                                  type="twitter"
                                  height="25px"
                                  width="25px"
                                  inputFeildSocial
                                />
                              </InputAdornment>
                            )
                          }}
                          name="twitter"
                          label="Twitter "
                          placeholder="Enter your Twitter profile link"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        className={classes.formFooter}
                      >
                        <Button
                          type="cancel"
                          label="cancel"
                          onClick={this.handleClose}
                          className={classes.cancelButton}
                        ></Button>
                        <Button
                          type="submit"
                          label={this.state.id ? "update" : "add"}
                          loader={addLoading}
                        ></Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    company: state.company.company,
    auth: state.auth,
    errors: state.errors,
    addCompanyData: state.company.addCompany
  };
};

export default connect(
  mapStateToProps,
  { addCompany, getCompany, updateCompany }
)(withStyles(styles)(withRouter(CompanyForm)));
