import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import companyReducer from "./companyReducer";
import profileReducer from "./profileReducer";
import eventsReducer from "./eventsReducer";
import applicationReducer from "./applicationReducer";
import applicantReducer from "./applicantReducer";
import partnerReducer from "./partnerReducer";
import associateReducer from "./associateReducer";
import notesReducer from "./noteReducer";
import notificationreducer from "./notificationReducer";
import citylistReducer from "./citylistReducer";
export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  company: companyReducer,
  profile: profileReducer,
  events: eventsReducer,
  applications: applicationReducer,
  applicants: applicantReducer,
  associates: associateReducer,
  partners: partnerReducer,
  notes: notesReducer,
  notifications: notificationreducer,
  citylist: citylistReducer
});
